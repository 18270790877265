import { Component, OnInit, ViewChild } from "@angular/core";
import { Missao } from 'src/app/model/missao.model';
import { EmpresaService } from './service/empresa.service';
import { MissaoService } from '../missao/service/missao.service';
import { Empresa } from 'src/app/model/empresa.model';
import { Alerta } from 'src/app/model/alerta.model';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EmpresaBuilder } from 'src/app/builder/empresa.builder';
import { Regional } from 'src/app/model/regional.model';
import { RegionalService } from '../regional/service/regional.service';
import { Difusao } from 'src/app/model/difusao.model';
import { DifusaoService } from '../difusao/service/difusao.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { environment } from 'src/environments/environment';


 @Component({
    selector: 'empresa',
    templateUrl: './empresa.component.html'
 })

 export class EmpresaComponent implements OnInit {

   
    public empresa: Empresa;
    public regionais: Array<Regional>;
    public missoes: Array<Missao>;
    public difusoes: Array<Difusao>;
    public grupoPertencente: Array<ElementoGrupoDominio>;
    public segmento: Array<ElementoGrupoDominio>;
    public regionalSelecionadoId : number;
    public empresaBuilder: EmpresaBuilder;
    public url: string;

    constructor(
        public empresaService: EmpresaService,
        public regionalService: RegionalService,
        public missaoService: MissaoService,
        public difusaoService: DifusaoService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public swtAlert2Service: SwtAlert2Service,
        public router: Router
    ){
        this.missoes = new Array<Missao>();
        this.difusoes = new Array<Difusao>();
        this.grupoPertencente = new Array<ElementoGrupoDominio>(); 
        this.segmento = new Array<ElementoGrupoDominio>();
        this.empresaBuilder = new EmpresaBuilder();
        this.empresa = this.empresaBuilder.getInstance();
    }

    ngOnInit() {
        this.carregarRegionais();
        this.carregarGrupoPertencente();
        this.carregarSegmento();
        this.regionalSelecionadoId = null; 
        this.url = environment.pessoa.pessoaPorNomeUrl;
    }

    carregarRegionais() : void {
        this.regionalService.findAll().then( (lista: any) => {
            this.regionais = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    
    carregarMissoes(): void {
        this.missaoService.porRegional(this.regionalSelecionadoId).then((response: any) => {
            this.missoes = response.entity;          
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);            
        });
    }

    carregarDifusoes(): void {
        this.difusaoService.porRegional(this.regionalSelecionadoId).then((response: any) => {
            this.difusoes = response.entity;          
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);            
        });
    }

    carregarGrupoPertencente() : void {
        let busca = {
            grupoNome : 'EMPRESA_GRUPO_PERTENCENTE'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca).then( (lista: any) => {
            this.grupoPertencente = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarSegmento() : void {
        let busca = {
            grupoNome : 'EMPRESA_SEGMENTO'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca).then( (lista: any) => {
            this.segmento = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    salvar(empresa: Empresa) : void {

        this.empresaService.create(empresa).then( (response:any) => {
            this.empresa = this.empresaBuilder.getInstance();
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/empresas']);
            },2000); 
        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    setarElementoSelecionado(valor) {
        this.empresa.pessoasVinculadasIds = valor;       
    }

 }