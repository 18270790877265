export class Usuario {

    constructor(
        public id : number,
        public email : string,
        public username : string,
        public password : string,
        public isAccountNonExpired: boolean,
        public isAccountNonLocked: boolean,
        public isCredentialsNonExpired: boolean,
        public isEnabled: boolean,
        public autorizacoes: number[],
        public autorizacoesNome: string[]
    ) {}

}