export class RegraPO {

    constructor(
        public id: number,
        public nome: string,
        public planejamentosOrcamentariosIds: number[],
        public variaveisConstanteIds: number[],
        public variaveisDetalhesContaIds: number[],
        public variaveisResultadoIds: number[],
        public passosIds: number[],
        public contaResultadoId: number,
        public contaResultadoNome: string,
        public detalheResultadoId: number,
        public detalheResultadoNome: string,
        public resultadoFinalId: number,
        public resultadoFinalNome: string
    ){}

}