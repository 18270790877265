import { Component, OnInit } from '@angular/core';
import { Login } from '../model/login.model';
import { LoginService } from './service/login.service';

@Component({
    selector: 'login',
    templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
   
    
    public login: Login;

    constructor(
        public loginService: LoginService
    ){}

    ngOnInit(): void {
        this.login = new Login('','');
    }

    public async fazerLogin() : Promise<any> {
        this.loginService.fazerLogin(this.login);        
    }

}