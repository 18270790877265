import { Empresa } from '../model/empresa.model';
import { AbstractBuilder } from './abstract.builder';


export class EmpresaBuilder extends AbstractBuilder<Empresa> {

    reset() : void {
        this.entity = new Empresa(null,null,null,null,null,null,null,null,null,null,null,null,null,new Array<number>(),new Array<string>());
    }

}