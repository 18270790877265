import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../layout/secured.module';
import { RouterModule } from '@angular/router';
import { DifusaoComponent } from './difusao.component';
import { DifusoesComponent } from './difusoes.component';
import { DifusaoEditComponent } from './difusao.edit.component';
import { DifusaoRoutingModule } from './difusao-routing.module';
import { DifusaoService } from './service/difusao.service';

@NgModule({
    declarations: [ 
        DifusaoComponent,
        DifusoesComponent,
        DifusaoEditComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        DifusaoRoutingModule,
        FormsModule,
        SecuredModule
    ],
    providers: [
        DifusaoService
    ]
})
export class DifusaoModule {

}