import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Resumo } from 'src/app/model/resumo.model';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";


@Injectable()
export class ResumoMissaoDifusaoService extends GenericService<Resumo> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('resumomissaodifusao');
    }

    buscarConselhoMissaoBrasil(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/buscarconselhomissaobrasil', JSON.stringify(busca), { 'headers' : this.headers }).toPromise();
    }

    buscarConselhoMissaoExterior(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/buscarconselhomissaoexterior', JSON.stringify(busca), { 'headers' : this.headers }).toPromise();
    }

    buscarComissaoMissaoBrasil(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/buscarcomissaomissaobrasil', JSON.stringify(busca), { 'headers' : this.headers }).toPromise();
    }

    buscarComissaoMissaoExterior(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/buscarcomissaomissaoexterior', JSON.stringify(busca), { 'headers' : this.headers }).toPromise();
    }

}