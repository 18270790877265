import { Injectable } from '@angular/core';



@Injectable()
export class ResumoUtilsService {

    private COLOR_RED:string = '#d9534f';
    private COLOR_YELLOW:string = '#efe13d';
    private COLOR_GREEN:string = '#5cb85c';
    private COLOR_GRAY:string = '#c0c0c0';
    
corCrescimentoReceita(valor: number) : string {

    let retorno = '';

    if(valor < 0)
    {
        retorno = this.COLOR_RED; // red
    }
    else if(valor == 0)
    {
        retorno = this.COLOR_GRAY; // gray
    }
    else if(valor >= 0.01 && valor <= 20.99)
    {
        retorno = this.COLOR_RED; // red
    }
    else if(valor >= 21 && valor <= 24.99)
    {
        retorno = this.COLOR_YELLOW; // amarelo
    }
    else if(valor >= 25 && valor <= 39.99)
    {
        retorno = this.COLOR_GREEN; // verde
    }
    else if(valor >= 40 && valor <= 49.99)
    {
        retorno = this.COLOR_YELLOW; // amarelo
    }
    else if(valor >= 50)
    {
        retorno = this.COLOR_RED; // red
    } 

    return retorno;

}

corRealizacaoReceita(valor: number) : string {

    let retorno = '';

    if(valor < 0)
    {
        retorno = this.COLOR_RED; // red
    }
    else if(valor == 0)
    {
        retorno = this.COLOR_GRAY; // gray
    }
    else if(valor >= 0.01 && valor <= 74.99)
    {
        retorno = this.COLOR_RED; // red
    }
    else if(valor >= 75 && valor <= 84.99)
    {
        retorno = this.COLOR_YELLOW; // amarelo
    }
    else if(valor >= 85 )
    {
        retorno = this.COLOR_GREEN; // verde
    }

    return retorno;

}
    
corMargemSeguranca(valor: number) : string {

    let retorno = '';

    if(valor < 0)
    {
        retorno = this.COLOR_RED; // red
    }
    else if(valor == 0)
    {
        retorno = this.COLOR_GRAY; // gray
    }
    else if(valor >= 0.01 && valor <= 10.99)
    {
        retorno = this.COLOR_RED; // red
    }
    else if(valor >= 11 && valor <= 19.99)
    {
        retorno = this.COLOR_YELLOW; // amarelo
    }
    else if(valor >= 20)
    {
        retorno = this.COLOR_GREEN; // verde
    }

    return retorno;

}

corCrescimentoCusto(valor: number) : string {

    let retorno = '';

    if(valor <= -21)
    {
        retorno = this.COLOR_RED; // red
    }
    else if(valor >= -20.99 && valor <= -11)
    {
        retorno = this.COLOR_YELLOW; // amarelo
    }
    else if(valor >= -10.99 && valor <= -0.01)
    {
        retorno = this.COLOR_GREEN; // verde
    }
    else if(valor == 0)
    {
        retorno = this.COLOR_GRAY; // gray
    }
    else if(valor >= 0.01 && valor <= 10.99)
    {
        retorno = this.COLOR_GREEN; // verde
    }
    else if(valor >= 11 && valor <= 20.99)
    {
        retorno = this.COLOR_YELLOW; // amarelo
    }
    else if(valor >= 21)
    {
        retorno = this.COLOR_RED; // red
    }

    return retorno;

}

corCrescimentoDespesa(valor: number) : string {

    let retorno = '';

    if(valor <= -21)
    {
        retorno = this.COLOR_RED; // red
    }
    else if(valor >= -20.99 && valor <= -11)
    {
        retorno = this.COLOR_YELLOW; // amarelo
    }
    else if(valor >= -10.99 && valor <= -0.01)
    {
        retorno = this.COLOR_GREEN; // verde
    }
    else if(valor == 0)
    {
        retorno = this.COLOR_GRAY; // gray
    }
    else if(valor >= 0.01 && valor <= 5.99)
    {
        retorno = this.COLOR_GREEN; // verde
    }
    else if(valor >= 6 && valor <= 20.99)
    {
        retorno = this.COLOR_YELLOW; // amarelo
    }
    else if(valor >= 21)
    {
        retorno = this.COLOR_RED; // red
    }

    return retorno;

}

corEstruturaEvangelizacao(valor: string) : string {

    let retorno = '';

    if(valor)
    {
        if(valor.localeCompare('ATENDE') == 0)
        {
            retorno = this.COLOR_GREEN;
        }
        else if(valor.localeCompare('ATENDE PARCIAL') == 0)
        {
            retorno = this.COLOR_YELLOW;
        }
        else if(valor.localeCompare('NÃO ATENDE') == 0)
        {
            retorno = this.COLOR_RED;
        }
        else if(valor.localeCompare('NÃO TEM CEV') == 0)
        {
            retorno = this.COLOR_GRAY;
        }
    }    

    return retorno;

}

corEstruturaMissionaria(valor: string) : string {

    let retorno = '';

    if(valor)
    {
        if(valor.localeCompare('ATENDE') == 0)
        {
            retorno = this.COLOR_GREEN;
        }
        else if(valor.localeCompare('ATENDE PARCIAL') == 0)
        {
            retorno = this.COLOR_YELLOW;
        }
        else if(valor.localeCompare('NÃO ATENDE') == 0)
        {
            retorno = this.COLOR_RED;
        }
        else if(valor.localeCompare('NÃO TEM CV') == 0)
        {
            retorno = this.COLOR_GRAY;
        }
    }    

    return retorno;
    
}

corRepasseCV(valor: number) : string {

    let retorno = '';

    if(valor <= 69.99)
    {
        retorno = this.COLOR_RED; // red
    }
    else if(valor >= 70 && valor <= 79.99)
    {
        retorno = this.COLOR_YELLOW; // yellow
    }
    else if(valor >= 80)
    {
        retorno = this.COLOR_GREEN; // green
    }

    return retorno;

}

}