import { PrevisaoOrcamentariaService } from '../secured/previsaoorcamentaria/service/previsaoorcamentaria.service';
import { PrevisaoOrcamentaria } from './previsaoorcamentaria.model';

export class PlanilhaPlanejamentoOrcamentario {

    constructor(
        public margemSeguranca: PrevisaoOrcamentaria,
        public resultadoEconomico: PrevisaoOrcamentaria,
        public resultado: PrevisaoOrcamentaria,
        public previsoesAvulsas: Array<PrevisaoOrcamentaria>,
        public previsoes: Array<PrevisaoOrcamentaria>
    ){}

}