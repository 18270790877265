import { NgModule } from '@angular/core';
import { SecuredComponent } from './secured.component';
import { SecuredRoutingModule } from './secured-routing.module';
import { HeaderModule } from './header/header.module';
import { LeftSidebarModule } from './left-sidebar/left-sidebar.module';
import { FooterModule } from './footer/footer.module';
import { CommonModule } from '@angular/common';
import { TableComponent } from 'src/app/core/table/table.component';
import { Select2Component } from 'src/app/core/select2/select2.component';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { RouterManagerService } from './routermanager.service';

@NgModule({
    declarations: [ 
        SecuredComponent,
        TableComponent,
        Select2Component
    ],
    imports: [
        CommonModule,
        SecuredRoutingModule,
        HeaderModule,
        LeftSidebarModule,
        FooterModule
    ],
    exports: [
        SecuredComponent,
        HeaderModule,
        LeftSidebarModule,
        FooterModule,
        TableComponent,
        Select2Component
    ],
    providers: [
        SwtAlert2Service,
        RouterManagerService
    ]
})
export class SecuredModule {

}