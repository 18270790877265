import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { RegraPO } from 'src/app/model/regrapo.model';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";


@Injectable()
export class RegraPOService extends GenericService<RegraPO> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('regrapo');
    }

    buscar(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/buscar', JSON.stringify(busca), { 'headers' : this.headers }).toPromise();
    }

    vincularListaPO(id: number, req: any) : Promise<any> {
        return this.http.post<any>(`${this.apiURL}/vincularlistapo/${id}`, JSON.stringify(req), { 'headers' : this.headers }).toPromise();
    } 

    desvincularListaPO(id: number, req: any) : Promise<any> {
        return this.http.post<any>(`${this.apiURL}/desvincularlistapo/${id}`, JSON.stringify(req), { 'headers' : this.headers }).toPromise();
    } 

    vincularPO(req: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/vincularpo', JSON.stringify(req), { 'headers' : this.headers }).toPromise();
    } 

    desvincularPO(req: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/desvincularpo', JSON.stringify(req), { 'headers' : this.headers }).toPromise();
    } 

}