import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { VariavelResultado } from 'src/app/model/variavelresultado.model';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";


@Injectable()
export class VariavelResultadoService extends GenericService<VariavelResultado> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('variavelresultado');
    }

    buscaPorRegraId(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/porregra/${id}`, { 'headers' : this.headers }).toPromise();
    }

}