import { OnInit, Component } from '@angular/core';
import { Pessoa } from 'src/app/model/pessoa.model';
import { PessoaService } from './service/pessoa.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ImagemService } from '../imagem/service/imagem.service';
import 'select2';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import * as bootastrap from 'bootstrap';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { environment } from 'src/environments/environment';
import { env } from 'process';
import { Usuario } from 'src/app/model/usuario.model';
import { UsuarioService } from '../usuario/service/usuario.service';

@Component({
    selector: 'pessoaedit',
    templateUrl: './pessoa.component.html',
    styleUrls: ['./style/style.css', './style/normalize.css']
})

export class PessoaEditComponent implements OnInit {

    public pessoa: Pessoa;
    public pessoaId: number;
    public tabNumber: number;
    public imageChangedEvent: any;
    public croppedImage: any;
    public nextClicked: boolean;
    public url: string;
    public pessoaBuilder: PessoaBuilder;    
    public urlBaseImagem: string;   
    public usuarioAtual: Usuario;
    public usuariosPorUsuarioCriador: Array<Usuario>;

    constructor(
        public pessoaService: PessoaService,
        public imagemService: ImagemService,
        public swtAlert2Service: SwtAlert2Service,
        public route: ActivatedRoute,
        public router: Router,
        public usuarioService: UsuarioService
    ) {       
        this.imageChangedEvent = '';
        this.croppedImage = '';
        this.nextClicked = false;        
        this.pessoaBuilder = new PessoaBuilder();
        this.pessoa = this.pessoaBuilder.getInstance();   
        this.urlBaseImagem = environment.imagem.urlBaseImagem;     
    }

    ngOnInit() {
        this.getUsuarioAtual();
        this.pessoaId = Number(this.route.snapshot.paramMap.get('id'));        
        this.carregarPessoa(this.pessoaId);   
        this.url = environment.usuario.selectPorUsername;
    }

    async getUsuarioAtual() : Promise<any> {

        try 
        {
            let response = await this.usuarioService.getCurrentUser();   
            this.usuarioAtual = response.entity;      
            this.getUsuariosPorUsuarioCriador();  
        }
        catch(e)
        {
            console.log(e);
        }

    }

    async getUsuariosPorUsuarioCriador() : Promise<any> {

        try 
        {
            let response = await this.usuarioService.getUsuariosPorUsuarioCriador();   
            this.usuariosPorUsuarioCriador = response.entity;        
        }
        catch(e)
        {
            console.log(e);
        }

    }

    carregarPessoa(Id: number) {
        this.pessoaService.find(Id).then((response: any) => {
            this.pessoa = response.entity;
            if(this.pessoa.usuarioId != null)
            {
                $("select[name='usuario']").append(new Option(this.pessoa.usuarioUsername, this.pessoa.usuarioId.toString(), false, true));
            }
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    logOnConsole(dadosPessoaisForm:any):void {
        console.log(dadosPessoaisForm);       
    }

    setarElementoSelecionado(valor) {
        this.pessoa.usuarioId = valor;
    }

    salvar(pessoa: Pessoa): void {

        this.pessoaService.update(pessoa.id, pessoa).then((response: any) => {
            this.pessoa = response.entity;            
            this.swtAlert2Service.successAlert(response.message);          
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    salvarImagem(): void {

        this.imagemService.salvarCropped({ 'imageData': this.croppedImage }).then((response: any) => {
            this.swtAlert2Service.successAlert(response.message);
            this.pessoa.imagemId = response.entity.id;
            this.salvar(this.pessoa);
            $('#modal-pessoa-imagem').modal('hide');
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
        console.log(event);
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    imageLoaded() {
        console.log('Imagem carregada');        
    }

    cropperReady() {
        console.log('Imagem cortada');
        
    }

    loadImageFailed() {
        console.log('Carregamento da imagem falhou!');        
    }

}