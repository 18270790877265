import { GenericService } from 'src/app/core/services/generic.service';
import { Usuario } from 'src/app/model/usuario.model';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";


@Injectable()
export class UsuarioService extends GenericService<Usuario> {
    
    constructor(protected http: HttpClient, protected router: Router) {
        super(http,router);
        this.setEntityType('usuario');
    }

    getCurrentUser(): Promise<any> {
        return this.http.get<Usuario>(this.apiURL+'/currentuser', { 'headers' : this.headers }).toPromise();
    }

    buscarUsuarios(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/buscar', JSON.stringify(busca), { 'headers' : this.headers }).toPromise();
    }


    getUsuariosPorUsuarioCriador(): Promise<any> {
        return this.http.get<Array<Usuario>>(this.apiURL+'/porusuariocriador', { 'headers' : this.headers }).toPromise();
    }

}