import { Component, OnInit } from '@angular/core';
import { RegraPOService } from './service/regrapo.service';
import { RegraPO } from 'src/app/model/regrapo.model';
import { RegraPOBuilder } from 'src/app/builder/regrapo.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'regrapo',
    templateUrl: './regrapo.component.html'
})
export class RegraPOComponent implements OnInit {

    public regrapoBuilder: RegraPOBuilder;
    public regrapo: RegraPO;

    constructor(
        public regrapoService: RegraPOService,
        public swtAlert2Service: SwtAlert2Service,
        public route: ActivatedRoute,
        public router: Router
    ) 
    {
        this.regrapoBuilder = new RegraPOBuilder();
        this.regrapo = this.regrapoBuilder.getInstance();
    }

    ngOnInit() {        
    }

    salvar(regrapo: RegraPO) : void {

        this.regrapoService.create(regrapo).then( (response:any) => {
            this.regrapo = this.regrapoBuilder.getInstance();
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/regrapo/'+response.entity.id]);
            }, 2000);
        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

}