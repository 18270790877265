import { NgModule } from '@angular/core';
import { PessoaComponent } from './pessoa.component';
import { PessoaEditComponent } from './pessoa.edit.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PessoaRoutingModule } from './pessoa-routing.module';
import { FormsModule } from '@angular/forms';
import { SecuredModule } from '../layout/secured.module';
import { PessoaService } from './service/pessoa.service';
import { PessoasComponent } from './pessoas.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImagemService } from '../imagem/service/imagem.service';
import { SwtAlert2Service } from '../../core/swtalert2/swtalert2.service'

 @NgModule({
     declarations:[
         PessoaComponent,
         PessoasComponent,
         PessoaEditComponent
     ],
     imports:[
         CommonModule,
         RouterModule,
         PessoaRoutingModule,
         FormsModule,
         SecuredModule,
         ImageCropperModule
     ],
     providers:[
         PessoaService,         
         ImagemService
     ]
 })
 export class PessoaModule{
     
 }