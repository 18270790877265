import { Permissao } from '../model/permissao.model';
import { AbstractBuilder } from './abstract.builder';


export class PermissaoBuilder extends AbstractBuilder<Permissao> {

    reset() : void {
        this.entity = new Permissao(null,null,null,[]);
    }

}