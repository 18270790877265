import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { Regional } from 'src/app/model/regional.model';
import { Resumo } from 'src/app/model/resumo.model';
import { ElementoGrupoDominioService } from 'src/app/secured/grupodominio/service/elementogrupodominio.service';
import { RegionalService } from 'src/app/secured/regional/service/regional.service';
import { environment } from 'src/environments/environment';
import { ResumoMissaoDifusaoService } from '../service/resumomissaodifusao.service';
import { ResumoSetorService } from '../service/resumosetor.service';
import { ResumoUtilsService } from '../service/resumoutils.service';

@Component({
    selector: '[relatorio-resumo-missaoexterior]',
    templateUrl: './missaoexterior.component.html',
    styleUrls: ['../style/resumo.css']
})
export class RelatorioResumoMissaoExteriorComponent implements OnInit, OnChanges {

    @Input() 
    public resumos: Array<Resumo>;
    @Output() 
    public eventoBotaoBuscar = new EventEmitter();
    @Input()
    public request : any;
    @Input()
    public totalDePaginas: number;
    public statusComissao: Array<ElementoGrupoDominio>;
    public statusConselho: Array<ElementoGrupoDominio>;
    public estruturaEvangelizacao: Array<ElementoGrupoDominio>;
    public estruturaMissionaria: Array<ElementoGrupoDominio>;
    public urlBuscaEmpresaPorNome: string;
    public regionais: Array<Regional>;

    constructor(
        public resumoMissaoDifusaoService : ResumoMissaoDifusaoService,
        public swtAlert2Service: SwtAlert2Service,
        public regionalService: RegionalService,
        public elementoGrupoDominioService : ElementoGrupoDominioService,
        public resumoUtilsService: ResumoUtilsService
    ) {
        this.resumos = new Array<Resumo>();
        this.urlBuscaEmpresaPorNome = environment.empresa.empresaPorNomeUrl;
        this.regionais = new Array<Regional>();
        this.statusComissao = new Array<ElementoGrupoDominio>();
        this.statusConselho = new Array<ElementoGrupoDominio>();
        this.estruturaEvangelizacao = new Array<ElementoGrupoDominio>();
        this.estruturaMissionaria = new Array<ElementoGrupoDominio>();
    }

    ngOnInit() {  
        this.carregarRegionais();
        this.carregarStatusComissao();
        this.carregarStatusConselho();
        this.carregarEstruturaEvangelizacao();
        this.carregarEstruturaMissionaria();
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    carregarRegionais() : void {
        this.regionalService.findAll().then( (lista: any) => {
            this.regionais = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    async carregarStatusComissao() : Promise<any> {

        let busca = {
            grupoNome : 'STATUS_COMISSAO'
        }

        try
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);
            this.statusComissao = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarStatusConselho() : Promise<any> {

        let busca = {
            grupoNome : 'STATUS_CONSELHO'
        }

        try
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);
            this.statusConselho = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarEstruturaEvangelizacao() : Promise<any> {

        let busca = {
            grupoNome : 'ESTRUTURA_EVANGELIZACAO'
        }

        try
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);
            this.estruturaEvangelizacao = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarEstruturaMissionaria() : Promise<any> {

        let busca = {
            grupoNome : 'ESTRUTURA_MISSIONARIA'
        }

        try
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);
            this.estruturaMissionaria = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async atualizarResumo(resumo: Resumo) : Promise<any> {

        try
        {
            let response = await this.resumoMissaoDifusaoService.update(resumo.id,resumo);
            this.swtAlert2Service.successAlert(response.message);
            this.cliqueNoBotaoBuscar();
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async deletar(id: number) : Promise<any> {

        if(confirm('Deseja deletar este resumo do planejamento orçamentário?'))
        {

            try
            {
                let response =  await this.resumoMissaoDifusaoService.delete(id);
                this.swtAlert2Service.successAlert(response.message);
                this.cliqueNoBotaoBuscar();
            }
            catch(e)
            {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
                        
        }
    }

    cliqueNoBotaoBuscar() {
        this.eventoBotaoBuscar.emit(this.request);
    }

    cliqueNoBotaoListener(botao) {
        this.request.pageNumber = botao.numero-1;
        this.cliqueNoBotaoBuscar();
    }

    limparSelect2(nome:string) : void {
        $("select[name='"+nome+"']").empty();
        this.request = {
            ano : new Date().getFullYear(),
            empresaId : null,
            regionalId : null,
            pageNumber : 0, 
            quantityOfElements : 10
        };
    }

}