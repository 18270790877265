import { VariavelConstante } from '../model/variavelconstante.model';
import { AbstractBuilder } from './abstract.builder';


export class VariavelConstanteBuilder extends AbstractBuilder<VariavelConstante> {

    reset() : void {
        this.entity = new VariavelConstante(null,null,0,null);
    }

}