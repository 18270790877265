export class Empresa {

    constructor(
        public id : number,
        public w4Id : number,
        public nome : string,
        public regionalNome : string,
        public regionalId : number,
        public missaoNome : string,
        public missaoId : number,
        public difusaoNome : string,
        public difusaoId : number,
        public grupoPertencenteNome : string,
        public grupoPertencenteId : number,
        public segmentoNome : string,
        public segmentoId : number,
        public pessoasVinculadasIds: Array<number>,
        public pessoasVinculadasNomes: Array<string>
    ) {
        this.pessoasVinculadasIds = new Array<number>();
        this.pessoasVinculadasNomes = new Array<string>();
    }

}