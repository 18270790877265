import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PlanejamentoOrcamentarioService } from './service/planejamentoorcamentario.service';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../layout/secured.module';
import { RouterModule } from '@angular/router';
import { PlanejamentoOrcamentarioComponent } from './planejamentoorcamentario.component';
import { PlanejamentoOrcamentarioRoutingModule } from './planejamentoorcamentario-routing.module';
import { PlanejamentosOrcamentariosComponent } from './planejamentosorcamentarios.component';
import { PlanejamentoOrcamentarioEditComponent } from './planejamentoorcamentario.edit.component';
import { CategoriaPlanejamentoOrcamentarioService } from './service/categoriaplanejamentoorcamentario.service';
import { PlanejamentoOrcamentarioPlanilhaComponent } from './planejamentoorcamentarioplanilha.component';
import { PrevisaoOrcamentariaService } from '../previsaoorcamentaria/service/previsaoorcamentaria.service';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgpSortModule } from "ngp-sort-pipe";
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { PlanilhaPrevisaoOrcamentariaAvulsaComponent } from './planilhaprevisaoavulsa/planilhaprevisaoorcamentariaavulsa.component';
import { PlanilhaUtilsService } from './service/planilhautils.service';
import { PlanilhaPlanejamentoOrcamentarioComponent } from './tabelaplanejamentoorcamentario/planilhaplanejamentoorcamentario.component';
import { RelatorioComparativoComponent } from './relatoriocomparativo/relatoriocomparativo.component';
import { PlanejamentosOrcamentariosParaUsuariosComponent } from './usuario/planejamentosorcamentarios.component';
import { PlanejamentoOrcamentarioPlanilhaParaUsuarioComponent } from './usuario/planejamentoorcamentarioplanilha.component';
import { PlanejamentosOrcamentariosParaGestorComponent } from './gestor/planejamentosorcamentarios.component';
import { PlanejamentoOrcamentarioEditParaGestorComponent } from './gestor/planejamentoorcamentario.edit.component';
import { PlanejamentoOrcamentarioPlanilhaParaGestorComponent } from './gestor/planejamentoorcamentarioplanilha.component';
import { HistoricoPlanejamentoOrcamentarioService } from './service/historicoplanejamentoorcamentario.service';
import { HistoricoPlanejamentoOrcamentarioComponent } from './historicoplanejamentoorcamentario/historicoplanejamentoorcamentario.component';

@NgModule({
    declarations: [ 
        PlanejamentoOrcamentarioComponent,
        PlanejamentosOrcamentariosComponent,
        PlanejamentoOrcamentarioEditComponent,
        PlanejamentoOrcamentarioPlanilhaComponent,
        PlanilhaPrevisaoOrcamentariaAvulsaComponent,
        PlanilhaPlanejamentoOrcamentarioComponent,
        RelatorioComparativoComponent,
        PlanejamentosOrcamentariosParaGestorComponent,
        PlanejamentoOrcamentarioPlanilhaParaGestorComponent,
        PlanejamentoOrcamentarioEditParaGestorComponent,
        PlanejamentosOrcamentariosParaUsuariosComponent,
        PlanejamentoOrcamentarioPlanilhaParaUsuarioComponent,
        HistoricoPlanejamentoOrcamentarioComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        PlanejamentoOrcamentarioRoutingModule,
        FormsModule,
        SecuredModule,
        SweetAlert2Module,
        NgpSortModule,
        CurrencyMaskModule
    ],
    providers: [
        PlanejamentoOrcamentarioService,
        CategoriaPlanejamentoOrcamentarioService,
        PrevisaoOrcamentariaService,
        PlanilhaUtilsService,
        HistoricoPlanejamentoOrcamentarioService
    ]
})
export class PlanejamentoOrcamentarioModule {

}