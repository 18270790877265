export class Missao {

    constructor(
        public id: number,
        public nome: string,
        public regionalId: number,
        public faseMissaoId: number        
    ){
    }

}