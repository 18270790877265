import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Resumo } from 'src/app/model/resumo.model';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";


@Injectable()
export class ResumoSetorService extends GenericService<Resumo> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('resumosetor');
    }

    buscarConselhoDiaconia(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/buscarconselhodiaconia', JSON.stringify(busca), { 'headers' : this.headers }).toPromise();
    }

    buscarConselhoFortaleza(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/buscarconselhofortaleza', JSON.stringify(busca), { 'headers' : this.headers }).toPromise();
    }

    buscarComissaoDiaconia(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/buscarcomissaodiaconia', JSON.stringify(busca), { 'headers' : this.headers }).toPromise();
    }

    buscarComissaoFortaleza(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/buscarcomissaofortaleza', JSON.stringify(busca), { 'headers' : this.headers }).toPromise();
    }

}