export class ElementoGrupoDominio {

    constructor(
        public id: number,
        public nome: string,
        public valor: string,
        public valorAlternativo: string,
        public sequencia: number,
        public valorFixo: boolean,
        public grupoId: number,
        public editar: boolean  
    ){}

}