import { NgModule } from '@angular/core';
import { LoginComponent } from './login.component';
import { LoginRoutingModule } from './login-routing.module';
import { FormsModule } from '@angular/forms';
import { LoginService } from './service/login.service';
import { SwtAlert2Service } from '../core/swtalert2/swtalert2.service';

@NgModule({
    declarations :[
        LoginComponent
    ],
    imports: [
        LoginRoutingModule,
        FormsModule
    ],
    providers: [
        LoginService,
        SwtAlert2Service
    ]
})
export class LoginModule {

}