import { MesPrevisaoOrcamentaria } from './mesprevisaoorcamentaria.model';

export class PrevisaoOrcamentaria { 
    constructor(
        public id : number,
        public nome : string,
        public planejamentoOrcamentarioId : number,
        public planejamentoOrcamentarioNome : string,
        public categoriaPlanejamentoOrcamentarioId : number,
        public categoriaPlanejamentoOrcamentarioNome : string,
        public contaPlanoId : number,
        public contaPlanoW4Id : number,
        public contaPlanoNome : string,
        public contaPlanoCodigo: string,
        public contaPlanoTipoHierarquico: string,
        public contaPlanoPertencenteId: number,
        public detalheContaId : number,
        public detalheContaW4Id : number,
        public detalheContaNome : string,
        public detalheContaTipoHierarquico : string,
        public detalheContaPertencenteId: number,
        public centroCustoId : number,
        public centroCustoNome : string,
        public mediaTotal : number,
        public valorTotal : number,
        public analiseVertical : number,
        public observacaoW4 : string,
        public comentario : string,
        public meses : Array<MesPrevisaoOrcamentaria>, 
        public previsaoOrcamentariaPertencenteId : number,
        public previsaoOrcamentariaPertencenteNome : string,
        public previsoesOrcamentariasVinculadas : Array<PrevisaoOrcamentaria>,
        public nivelArvore : number,
        public ordemExibicao : number,
        public exibirCamposEdicao: boolean
    ) {}
}