import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Injectable()
export class SwtAlert2Service {
    
    private toast: any;
    private apiURL: string = environment.configServidor.apiUrlNotSecured;

    constructor(){
        this.toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000
        });
        this.pushNotificationListener();
    }

    private pushNotificationListener() : void {

        var self = this;

        var connect = function () {
            var source = new EventSource(self.apiURL+'pushnotification/stream');    
                source.addEventListener('open', function (e) {
            });
            
            source.addEventListener('message', function (e) {
                self.infoAlert(e.data);
            }, false);
            
            source.addEventListener('error', function (e:any) {
                if (e.currentTarget.readyState == EventSource.CLOSED) {
                    connect();
                }
            }, false);
        }
        
        $(function() {
          connect();
        });
    }

    public successAlert(message:string): void {
        this.toast.fire({
            title: 'Sucesso!',
            text: message,
            icon: 'success'
        });
    }
       
    public errorAlert(message:string): void {
        this.toast.fire({
            title: 'Erro!',
            text: message,
            icon: 'error'
        });
    }

    public warningAlert(message:string): void {
        this.toast.fire({
            title: 'Alerta!',
            text: message,
            icon: 'warning'
        });
    }

    public infoAlert(message:string): void {
        this.toast.fire({
            title: 'Informativo!',
            text: message,
            icon: 'info'
        });
    }

    public questionAlert(message:string): void {
        this.toast.fire({
            title: 'Questão!',
            text: message,
            icon: 'question'
        });
    }
}