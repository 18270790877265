import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { PrevisaoOrcamentaria } from 'src/app/model/previsaoorcamentaria.model';

@Injectable()
export class PrevisaoOrcamentariaService extends GenericService<PrevisaoOrcamentaria> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('previsaoorcamentaria');
    }

    buscarPrevisaoOrcamentariaInicialPorCategoria(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/porcategoria/${id}`, { 'headers' : this.headers }).toPromise();
    }

    obterPrevisoesOrcamentariasVinculadasW4(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/obterprevisoesw4/${id}`, { 'headers' : this.headers }).toPromise();
    }

    downloadExportacaoW4(request: any) : void {
        this.http.post<any>(`${this.apiURL}/exportarw4`,JSON.stringify(request),{responseType: 'blob' as 'json',headers: this.headers}).subscribe(
            (response:any) => {
                let dataType = response.type;
                let binaryData = [];
                binaryData.push(response);
                let downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
                if(request.filename)
                    downloadLink.setAttribute('download', request.filename+'_w4_'+request.ano);
                document.body.appendChild(downloadLink);
                downloadLink.click();
            });            
    }

} 