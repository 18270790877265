import { Component, OnInit } from '@angular/core';
import { CentroCustoService } from './service/centrocusto.service';
import { CentroCusto } from 'src/app/model/centrocusto.model';
import { Router, ActivatedRoute } from '@angular/router';
import { CentroCustoBuilder } from 'src/app/builder/centrocusto.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'centrocusto-edit',
    templateUrl: './centrocusto.component.html'
})
export class CentroCustoEditComponent implements OnInit {

    public centroCusto: CentroCusto;
    public centrosCustoVinculados: Array<CentroCusto>;
    public centroCustoId: number;
    public busca: any;
    public centroCustoBuilder: CentroCustoBuilder;
    public url : string;
    public urlVincular : string;
    public requestVinculacao: any;

    constructor(
        public centroCustoService: CentroCustoService,
        public swtAlert2Service: SwtAlert2Service,
        public route: ActivatedRoute,
        public router: Router
    ) {
        this.centroCustoBuilder = new CentroCustoBuilder();
        this.centroCusto = this.centroCustoBuilder.getInstance();    
        this.centrosCustoVinculados = new Array<CentroCusto>();    
        this.busca = {
            centroCustoId: this.centroCustoId,
            pageNumber: 0,
            quantityOfElements: 10
        };        
    }

    ngOnInit() {
        this.route.params.subscribe(
            params => {
                this.centroCustoId = +params['id'];
                this.carregarCentroCusto(this.centroCustoId);
                this.carregarCentrosCustoVinculados();
                this.url = environment.centroCusto.gruposPorNome;                
                this.urlVincular = environment.centroCusto.buscaParaVinculacao+this.centroCustoId;
            }
        );   
    }

    carregarCentroCusto(id: number): void {
        this.centroCustoService.find(id).then((response: any) => {
            this.centroCusto = response.entity;  
            if(this.centroCusto.centroCustoPertencenteId != null)
            {
                $("select[name='cp_pertencente']").append(new Option(this.centroCusto.centroCustoPertencenteNome, this.centroCusto.centroCustoPertencenteId.toString(), false, true));
            }         
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    async carregarCentrosCustoVinculados(): Promise<any> {
        
        try
        {
            let response = await this.centroCustoService.buscaPorCentroCustoPertencenteId(this.centroCustoId);
            this.centrosCustoVinculados = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    salvar(centroCusto: CentroCusto): void {

        this.centroCustoService.update(centroCusto.id, centroCusto).then((response: any) => {
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/centroscusto']);
            }, 2000);

        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    deletar(id: number) : void {

        if(confirm('Deseja deletar este centro de custo?'))
        {
            this.centroCustoService.delete(id)
            .then( (response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.carregarCentrosCustoVinculados();
            })
            .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }

    setarCentroCustoVinculavel(id:any): void {
        this.requestVinculacao = {
            "id" : this.centroCustoId,
            "vincularId" : Number(id)
        };
    }

    async vincularCentroCusto(id:any): Promise<any> {

        try 
        {
            let response = await this.centroCustoService.vicularCentroCusto(this.requestVinculacao);
            this.requestVinculacao = {};
            this.limparSelect2Vinculavel();
            this.swtAlert2Service.successAlert(response.message);
            this.carregarCentrosCustoVinculados();
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.message);
        }

    }

    async desvincularCentroCusto(id:any): Promise<any> {

        this.requestVinculacao = {
            "id" : this.centroCustoId,
            "vincularId" : Number(id)
        };

        try 
        {
            let response = await this.centroCustoService.desvicularCentroCusto(this.requestVinculacao);
            this.requestVinculacao = {};
            this.swtAlert2Service.successAlert(response.message);
            this.carregarCentrosCustoVinculados();
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    limparSelect2() : void {
        $("select[name='cp_pertencente']").empty();
        this.setarElementoSelecionado(null);
    }

    limparSelect2Vinculavel() : void {
        $("select[name='cp_vincular']").empty();
    }

    setarElementoSelecionado(id): void {
        this.centroCusto.centroCustoPertencenteId = id;
    }

}