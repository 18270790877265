import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario } from 'src/app/model/usuario.model';
import { UsuarioService } from '../usuario/service/usuario.service';

@Injectable()
export class RouterManagerService {

    private usuarioAtual: Usuario;
    private jaExecutado: boolean;

    constructor(
        private usuarioService: UsuarioService,
        private router: Router
    ){}

    async verificarUsuario() : Promise<any> {

        if(!this.jaExecutado && window.location.pathname == '/secured')
        {
            this.jaExecutado = true;
            let response2 = await this.usuarioService.getCurrentUser();
            this.usuarioAtual = response2.entity;

            if(this.usuarioAtual.autorizacoesNome.includes('ROLE_ADMIN'))
            {
                this.router.navigate(['/secured/planejamentosorcamentarios']);
            }
            else if(this.usuarioAtual.autorizacoesNome.includes('ROLE_GESTOR') || this.usuarioAtual.autorizacoesNome.includes('ROLE_AVANCADO_EXP'))
            {
                this.router.navigate(['/secured/g/planejamentosorcamentarios']);
            }
            else if(
                this.usuarioAtual.autorizacoesNome.includes('ROLE_USUARIO')
                ||
                this.usuarioAtual.autorizacoesNome.includes('ROLE_USUARIO_INTERMEDIARIO')
                ||
                this.usuarioAtual.autorizacoesNome.includes('ROLE_USUARIO_VISUALIZADOR')
                ||
                this.usuarioAtual.autorizacoesNome.includes('ROLE_CONSELHO')
            )
            {
                this.router.navigate(['/secured/u/planejamentosorcamentarios']);
            }
        }       

    }

}