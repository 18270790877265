import { DetalheConta } from '../model/detalheconta.model';
import { AbstractBuilder } from './abstract.builder';


export class DetalheContaBuilder extends AbstractBuilder<DetalheConta> {

    reset() : void {
        this.entity = new DetalheConta(null,null,null,null,null,null,null,null,new Array<number>(),new Array<number>());
    }

}