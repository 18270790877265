import { RelatorioComparativoW4 } from '../model/relatoriocomparativow4.model';
import { AbstractBuilder } from './abstract.builder';
import { RelatorioComparativoCategoriasW4 } from '../model/relatoriocomparativocategoriasw4.model';


export class RelatorioComparativoW4Builder extends AbstractBuilder<RelatorioComparativoW4> {

    reset() : void {
        this.entity = new RelatorioComparativoW4(new Array<RelatorioComparativoCategoriasW4>());
    }

}