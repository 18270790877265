import { VariavelDetalhesConta } from '../model/variaveldetalhesconta.model';
import { AbstractBuilder } from './abstract.builder';


export class VariavelDetalhesContaBuilder extends AbstractBuilder<VariavelDetalhesConta> {

    reset() : void {
        this.entity = new VariavelDetalhesConta(null,null,new Array<number>(),null);
    }

}