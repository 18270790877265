import { Component, OnInit } from '@angular/core';
import { RegraPOService } from './service/regrapo.service';
import { RegraPO } from 'src/app/model/regrapo.model';
import { Router, ActivatedRoute } from '@angular/router';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { RegraPOBuilder } from 'src/app/builder/regrapo.builder';
import { VariavelConstante } from 'src/app/model/variavelconstante.model';
import { VariavelConstanteBuilder } from 'src/app/builder/variavelconstante.builder';
import { VariavelConstanteService } from './service/variavelconstante.service';
import { VariavelResultadoService } from './service/variavelresultado.service';
import { VariavelDetalhesContaService } from './service/variaveldetalhesconta.service';
import { VariavelDetalhesConta } from 'src/app/model/variaveldetalhesconta.model';
import { VariavelResultado } from 'src/app/model/variavelresultado.model';
import { VariavelDetalhesContaBuilder } from 'src/app/builder/variaveldetalhesconta.builder';
import { VariavelResultadoBuilder } from 'src/app/builder/variavelresultado.builder';
import { DetalheContaService } from '../detalheconta/service/detalheconta.service';
import { environment } from 'src/environments/environment';
import { PassoRegraPO } from 'src/app/model/passoregraplanejamentoorcamentario.model';
import { PassoRegraPOService } from './service/passoregrapo.service';
import { PassoRegraPOBuilder } from 'src/app/builder/passoregrapo.builder';
import { PlanejamentoOrcamentarioService } from '../planejamentoorcamentario/service/planejamentoorcamentario.service';
import { PlanejamentoOrcamentario } from 'src/app/model/planejamentoorcamentario.model';
import { ContaDetalheChaveService } from './service/contadetalhechave.service';
import { ContaDetalheChave } from 'src/app/model/contadetalhechave.model';
import { ContaDetalheChaveBuilder } from 'src/app/builder/contadetalhechave.builder';

@Component({
    selector: 'regrapo-edit',
    templateUrl: './regrapo.component.html'
})
export class RegraPOEditComponent implements OnInit {

    public regrapoBuilder : RegraPOBuilder;
    public regrapo: RegraPO;
    public regrapoId: number;
    public totalDePaginas: number;
    public variavelConstante: VariavelConstante;
    public variavelConstanteBuilder: VariavelConstanteBuilder;
    public variaveisConstante: Array<VariavelConstante>;
    public variavelDetalhesConta: VariavelDetalhesConta;
    public variavelDetalhesContaBuilder: VariavelDetalhesContaBuilder;
    public variaveisDetalhesConta: Array<VariavelDetalhesConta>;
    public variavelDetalhesContaSelecionado: VariavelDetalhesConta;
    public variavelResultado: VariavelResultado;
    public variavelResultadoBuilder: VariavelResultadoBuilder;
    public variaveisResultado: Array<VariavelResultado>;
    public contaDetalheChave : ContaDetalheChave;
    public contaDetalheChaveBuilder : ContaDetalheChaveBuilder;
    public contasDetalhesChave: Array<ContaDetalheChave>;
    public urlBuscaContaPlanoPorNome : string;
    public urlBuscaDetalheContaPorNome : string;
    public urlPONome: string;    
    public passoRegraPO: PassoRegraPO;
    public passoRegraPOBuilder: PassoRegraPOBuilder;
    public passosRegraPO: Array<PassoRegraPO>;
    public buscaPORequest : any;
    public poSelecionadoId : number;
    public posVinculados: Array<PlanejamentoOrcamentario>;
    

    constructor(
        public regrapoService: RegraPOService,
        public variavelConstanteService: VariavelConstanteService,
        public variavelDetalhesContaService: VariavelDetalhesContaService,
        public variavelResultadoService: VariavelResultadoService,
        public detalheContaService: DetalheContaService,
        public passoRegraPOService: PassoRegraPOService,
        public poService: PlanejamentoOrcamentarioService,
        public contaDetalheChaveService: ContaDetalheChaveService,
        public swtAlert2Service: SwtAlert2Service,
        public route: ActivatedRoute,
        public router: Router
    ) {
        this.regrapoBuilder = new RegraPOBuilder();
        this.regrapo = this.regrapoBuilder.getInstance();  
        this.variavelConstanteBuilder = new VariavelConstanteBuilder();
        this.variavelConstante = this.variavelConstanteBuilder.getInstance();  
        this.variaveisConstante = new Array<VariavelConstante>();   
        this.variavelDetalhesContaBuilder = new VariavelDetalhesContaBuilder();
        this.variavelDetalhesConta = this.variavelDetalhesContaBuilder.getInstance();
        this.variaveisDetalhesConta = new Array<VariavelDetalhesConta>();  
        this.variavelResultadoBuilder = new VariavelResultadoBuilder();
        this.variavelResultado = this.variavelResultadoBuilder.getInstance(); 
        this.variaveisResultado = new Array<VariavelResultado>();          
        this.passoRegraPOBuilder = new PassoRegraPOBuilder();
        this.passoRegraPO = this.passoRegraPOBuilder.getInstance();
        this.passosRegraPO = new Array<PassoRegraPO>();
        this.contaDetalheChaveBuilder = new ContaDetalheChaveBuilder();
        this.contaDetalheChave = this.contaDetalheChaveBuilder.getInstance();
        this.contasDetalhesChave = new Array<ContaDetalheChave>();  
        this.posVinculados = new Array<PlanejamentoOrcamentario>();
        this.urlBuscaContaPlanoPorNome = environment.contaPlano.buscaPorNome;
        this.urlBuscaDetalheContaPorNome = environment.detalheConta.buscaPorNome;
        this.urlPONome = environment.po.poPorNomeUrl;            
    }

    ngOnInit() {
        this.regrapoId = Number(this.route.snapshot.paramMap.get('id'));
        this.buscaPORequest = {
            ano: null,
            nome: null,
            regraId: this.regrapoId,
            pageNumber: 0,
            quantityOfElements : 10        
        };  
        this.carregarRegraPO(this.regrapoId);
        this.carregarVariaveisConstantes(this.regrapoId);
        this.carregarVariaveisDetalhesConta(this.regrapoId);
        this.carregarVariaveisResultado(this.regrapoId);
        this.carregarPassosRegraPO(this.regrapoId);
        this.buscarPOs();
    }

    async buscarPOs() : Promise<any> {

        try
        {
            let response:any = await this.poService.buscarPorRegra(this.buscaPORequest);
            this.posVinculados = response.content;
            this.totalDePaginas = response.totalPages;
            this.buscaPORequest = {
                ano: null,
                nome: null,
                regraId: this.regrapoId,
                pageNumber: 0,
                quantityOfElements : 10        
            };
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarVariaveisConstantes(id:number) : Promise<any> {

        try
        {
            let response:any = await this.variavelConstanteService.buscaPorRegraId(id);
            this.variaveisConstante = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarVariaveisDetalhesConta(id:number) : Promise<any> {

        try
        {
            let response:any = await this.variavelDetalhesContaService.buscaPorRegraId(id);
            this.variaveisDetalhesConta = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarContaDetalheChave(id:number) : Promise<any> {

        try
        {
            let response:any = await this.contaDetalheChaveService.findByVariavelDetalhesContaId(id);
            this.contasDetalhesChave = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarVariaveisResultado(id:number) : Promise<any> {

        try
        {
            let response:any = await this.variavelResultadoService.buscaPorRegraId(id);
            this.variaveisResultado = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarPassosRegraPO(id:number) : Promise<any> {

        try
        {
            let response:any = await this.passoRegraPOService.buscaPorRegraId(id);
            this.passosRegraPO = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    carregarRegraPO(id: number): void {
        this.regrapoService.find(id).then((response: any) => {
            this.regrapo = response.entity;
            if(this.regrapo.contaResultadoId)
            {
                $("select[name='contaplano_final']").append(new Option(this.regrapo.contaResultadoNome, this.regrapo.contaResultadoId.toString(), false, true));
            }
            if(this.regrapo.detalheResultadoId)
            {
                $("select[name='detalheconta_final']").append(new Option(this.regrapo.detalheResultadoNome, this.regrapo.detalheResultadoId.toString(), false, true));
            }
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    salvar(regrapo: RegraPO): void {

        this.regrapoService.update(regrapo.id, regrapo).then((response: any) => {
            this.regrapo = response.entity;
            this.swtAlert2Service.successAlert(response.message);            
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    salvarVariavelConstante(variavelConstante: VariavelConstante): void {

        variavelConstante.regraId = this.regrapoId;

        this.variavelConstanteService.create(variavelConstante).then((response: any) => {
            this.variavelConstante = this.variavelConstanteBuilder.getInstance();
            this.carregarVariaveisConstantes(this.regrapoId);
            this.swtAlert2Service.successAlert(response.message); 
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });

    }

    async salvarVariavelDetalhesConta(variavelDetalhesConta: VariavelDetalhesConta) : Promise<any> {

        variavelDetalhesConta.regraId = this.regrapoId;

        try
        {
            let response:any = await this.variavelDetalhesContaService.create(variavelDetalhesConta);
            this.variavelDetalhesConta = this.variavelDetalhesContaBuilder.getInstance();
            this.carregarVariaveisDetalhesConta(this.regrapoId);
            this.swtAlert2Service.successAlert(response.message); 
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async salvarVariavelResultado(variavelResultado: VariavelResultado) : Promise<any> {

        variavelResultado.regraId = this.regrapoId;

        try
        {
            let response:any = await this.variavelResultadoService.create(variavelResultado);
            this.variavelResultado = this.variavelResultadoBuilder.getInstance();
            this.carregarVariaveisResultado(this.regrapoId);
            this.swtAlert2Service.successAlert(response.message); 
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async salvarPassoRegraPO(passo: PassoRegraPO) : Promise<any> {

        passo.regraId = this.regrapoId;

        try
        {
            let response:any = await this.passoRegraPOService.create(passo);
            this.passoRegraPO = this.passoRegraPOBuilder.getInstance();
            this.carregarPassosRegraPO(this.regrapoId);
            this.swtAlert2Service.successAlert(response.message); 
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async salvarContaDetalheChave() : Promise<any> {

        this.contaDetalheChave.variavelDetalhesContaId = this.variavelDetalhesContaSelecionado.id;

        try
        {
            let response:any = await this.contaDetalheChaveService.create(this.contaDetalheChave);
            this.carregarContaDetalheChave(this.variavelDetalhesContaSelecionado.id);
            this.swtAlert2Service.successAlert(response.message); 
            //this.limparSelect2("contaplano");
            this.limparSelect2("detalheconta");
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async deletarVariavelConstante(id:number) : Promise<any> {

        if(confirm("Deseja deletar esta variável?"))
        {

            try
            {
                let response:any = await this.variavelConstanteService.delete(id);
                this.carregarVariaveisConstantes(this.regrapoId);
                this.swtAlert2Service.successAlert(response.message); 
            }
            catch(e)
            {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }

        }

    }

    async deletarVariavelDetalhesConta(id:number) : Promise<any> {

        if(confirm("Deseja deletar esta variável?"))
        {

            try
            {
                let response:any = await this.variavelDetalhesContaService.delete(id);
                this.carregarVariaveisDetalhesConta(this.regrapoId);
                this.swtAlert2Service.successAlert(response.message); 
            }
            catch(e)
            {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }

        }

    }

    async deletarVariavelResultado(id:number) : Promise<any> {

        if(confirm("Deseja deletar esta variável?"))
        {

            try
            {
                let response:any = await this.variavelResultadoService.delete(id);
                this.carregarVariaveisResultado(this.regrapoId);
                this.swtAlert2Service.successAlert(response.message); 
            }
            catch(e)
            {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }

        }

    }

    async deletarPasso(id:number) : Promise<any> {

        if(confirm("Deseja deletar este passo?"))
        {

            try
            {
                let response:any = await this.passoRegraPOService.delete(id);
                this.carregarPassosRegraPO(this.regrapoId);
                this.swtAlert2Service.successAlert(response.message); 
            }
            catch(e)
            {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }

        }

    }

    async deletarContaDetalheChave(id:number) : Promise<any> {

        if(confirm("Deseja deletar esta vinculação?"))
        {

            try
            {
                let response:any = await this.contaDetalheChaveService.delete(id);
                this.carregarContaDetalheChave(this.variavelDetalhesContaSelecionado.id);
                this.swtAlert2Service.successAlert(response.message);  
            }
            catch(e)
            {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }

        }

    }

    async vincularPO() : Promise<any> {

        let request: any = {
            regraId : this.regrapoId,
            poId : this.poSelecionadoId
        }

        try
        {
            let response:any = await this.regrapoService.vincularPO(request);
            this.buscarPOs();
            this.swtAlert2Service.successAlert(response.message); 
            this.limparSelect2("po_nome");
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async desvincularPO(id: number) : Promise<any> {

        let request: any = {
            regraId : this.regrapoId,
            poId : id
        }

        try
        {
            let response:any = await this.regrapoService.desvincularPO(request);
            this.buscarPOs();
            this.swtAlert2Service.successAlert(response.message); 
            this.limparSelect2("po_nome");
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    setarVariavelDetalhesContaSelecionada(variavelDetalhesConta:VariavelDetalhesConta) : void {
        this.variavelDetalhesContaSelecionado = variavelDetalhesConta;
        this.carregarContaDetalheChave(variavelDetalhesConta.id);
    }

    setarContaPlanoSelecionado(id : number) {
        this.contaDetalheChave.contaPlanoId = id;
    }

    setarDetalheContaSelecionado(id : number) {
        this.contaDetalheChave.detalheContaId = id;
    }


    limparSelect2(nomeEl) : void {
        $("select[name='"+nomeEl+"']").empty();
    }

    cliqueNoBotaoListener(botao) {
        this.buscaPORequest.pageNumber = botao.numero-1;
        this.buscarPOs();
    }

}