import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
 providedIn: 'root'
})
export class AppRouteChangeService {

    constructor(private router: Router) {
        router.events.subscribe((event) => {
            // console.log(event);
            if($('body').width() <= 991)
            {
                $('body.igx-typography').removeClass("sidebar-open");
                $('body.igx-typography').addClass("sidebar-collapse");
            }
        });
    }
}