import { Component, OnInit } from '@angular/core';
import { PlanejamentoOrcamentario } from 'src/app/model/planejamentoorcamentario.model';
import { PlanejamentoOrcamentarioService } from './service/planejamentoorcamentario.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { environment } from 'src/environments/environment';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { RegraPOService } from '../regrapo/service/regrapo.service';
import { RegraPO } from 'src/app/model/regrapo.model';
import { DifusaoService } from '../difusao/service/difusao.service';
import { MissaoService } from '../missao/service/missao.service';
import { RegionalService } from '../regional/service/regional.service';
import { Regional } from 'src/app/model/regional.model';
import { Missao } from 'src/app/model/missao.model';
import { Difusao } from 'src/app/model/difusao.model';

@Component({
    selector: 'planejamentosorcamentarios',
    templateUrl: './planejamentosorcamentarios.component.html'
})
export class PlanejamentosOrcamentariosComponent implements OnInit {

    public entities: Array<PlanejamentoOrcamentario>;
    public busca: any;
    public totalDePaginas: number;
    public urlBuscaEmpresaPorNome: string;
    public urlBuscaPessoaPorNome: string;
    public listaGrupos: Array<ElementoGrupoDominio>;
    public listaStatus: Array<ElementoGrupoDominio>;
    public listaInstancias: Array<ElementoGrupoDominio>;
    public listaSegmentos: Array<ElementoGrupoDominio>;
    public regraId : number;
    public listaRegras : Array<RegraPO>;
    public regionais: Array<Regional>;
    public missoes: Array<Missao>;
    public difusoes: Array<Difusao>;
    public copiaRequest: any;

    constructor(
        public planejamentoOrcamentarioService: PlanejamentoOrcamentarioService,
        public regraService: RegraPOService,
        public regionalService: RegionalService,
        public missaoService: MissaoService,
        public difusaoService: DifusaoService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.entities = new Array<PlanejamentoOrcamentario>();        
        this.urlBuscaEmpresaPorNome = environment.empresa.empresaPorNomeUrl;
        this.urlBuscaPessoaPorNome = environment.pessoa.pessoaPorNomeUrl;
        this.listaGrupos = new Array<ElementoGrupoDominio>();
        this.listaStatus = new Array<ElementoGrupoDominio>();
        this.listaInstancias = new Array<ElementoGrupoDominio>();
        this.listaSegmentos = new Array<ElementoGrupoDominio>();
        this.listaRegras = new Array<RegraPO>();
        this.busca = {
            ano: null,
            empresaId: null,
            grupoId: null,
            statusId: null,
            instanciaId: null,
            segmentoId: null,
            regionalId: null,
            missaoId: null,
            difusaoId: null,
            fechado: null,
            pageNumber : 0, 
            quantityOfElements : 10
        };
        this.copiaRequest = {
            "periodoInicio" : null,
            "periodoFim" : null,
            "comValores" : false,
            "requestBusca" : this.busca
        }
    }

    ngOnInit() {
        this.carregarGrupos();
        this.carregarStatus();
        this.carregarRegionais();
        this.carregarInstancias();
        this.carregarSegmento();
        this.carregarRegras();
        this.buscar();
    }   

    buscar() : void {
        this.planejamentoOrcamentarioService.buscar(this.busca).then( (response: any) => {
            this.entities = response.content;
            this.totalDePaginas = response.totalPages;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero-1;
        this.buscar();
    }
    
    deletar(id: number) : void {

        if(confirm('Deseja deletar este planejamento orçamentário?'))
        {
            this.planejamentoOrcamentarioService.delete(id)
            .then( (response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscar();
            })
            .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }

    async carregarGrupos() : Promise<any> {

        let buscaFase = {
            grupoNome : 'EMPRESA_GRUPO_PERTENCENTE'
        }

        try
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase);
            this.listaGrupos = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarStatus() : Promise<any> {

        let buscaFase = {
            grupoNome : 'STATUS_PO'
        }

        try
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase);
            this.listaStatus = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarInstancias() : Promise<any> {

        let buscaFase = {
            grupoNome : 'INSTANCIA_PO'
        }

        try
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase);
            this.listaInstancias = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async copiarPosAnuais() : Promise<any> {

        try
        {

            if(this.copiaRequest.periodoInicio != null && this.copiaRequest.periodoFim != null)
            {
                let response = await this.planejamentoOrcamentarioService.gerarCopiasAnuaisPlanejamentoOrcamentario(this.copiaRequest);
                this.swtAlert2Service.successAlert(response.message);
            }
            else
            {
                this.swtAlert2Service.errorAlert("Preencha as datas iniciais e finais para realizar a cópia.");
            }
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }  

    }

    carregarRegionais() : void {
        this.regionalService.findAll().then( (lista: any) => {
            this.regionais = lista.entity;
        }).catch( (err: any) => {
            console.log(err);
        });
    }
    
    carregarMissoes(): void {
        this.missaoService.porRegional(this.busca.regionalId).then((response: any) => {
            this.missoes = response.entity;          
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    carregarDifusoes(): void {
        this.difusaoService.porRegional(this.busca.regionalId).then((response: any) => {
            this.difusoes = response.entity;          
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    async carregarSegmento() : Promise<any> {

        let buscaFase = {
            grupoNome : 'EMPRESA_SEGMENTO'
        }

        try
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase);
            this.listaSegmentos = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarRegras() : Promise<any> {

        try
        {
            let response = await this.regraService.findAll();
            this.listaRegras = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async vincularRegra() : Promise<any> {

        try
        {
            let response = await this.regraService.vincularListaPO(this.regraId,this.busca);
            this.regraId = undefined;
            this.swtAlert2Service.successAlert(response.message);
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }        
    }

    async desvincularRegra() : Promise<any> {

        try
        {
            let response = await this.regraService.desvincularListaPO(this.regraId,this.busca);
            this.regraId = undefined;
            this.swtAlert2Service.successAlert(response.message);
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }
    }

    setarElementoSelecionado(empresaId:number) : void {
        this.busca.empresaId = empresaId;
    }

    setarElementoSelecionadoPessoa(pessoaId:number) : void {
        this.busca.pessoaId = pessoaId;
    }

    limparSelect2(nome:string) : void {
        $("select[name='"+nome+"']").empty();
        this.setarElementoSelecionado(undefined);
        this.setarElementoSelecionadoPessoa(undefined);
        this.busca = {
            ano: null,
            empresaId: null,
            grupoId: null,
            statusId: null,
            instanciaId: null,
            segmentoId: null,
            regionalId: null,
            missaoId: null,
            difusaoId: null,
            fechado: null,
            pageNumber : 0, 
            quantityOfElements : 10
        };
        this.copiaRequest = {
            "periodoInicio" : null,
            "periodoFim" : null,
            "comValores" : false,
            "requestBusca" : this.busca
        }
    }
}