import { Component, OnInit } from '@angular/core';
import { Resumo } from 'src/app/model/resumo.model';
import { ResumoSetorService } from './service/resumosetor.service'
import { ResumoMissaoDifusaoService } from './service/resumomissaodifusao.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'resumo',
    templateUrl: './resumo.component.html'
})
export class ResumoComponent implements OnInit {

    public resumoConselhoDiaconiaEntities: Array<Resumo>;    
    public requestConselhoDiaconia: any;
    public totalDePaginasConselhoGeralDiaconia: number;

    public resumoComissaoDiaconiaEntities: Array<Resumo>;
    public requestComissaoDiaconia: any;
    public totalDePaginasComissaoGeralDiaconia: number;
    
    public resumoConselhoFortalezaEntities: Array<Resumo>;    
    public requestConselhoFortaleza: any;
    public totalDePaginasConselhoGeralFortaleza: number;

    public resumoComissaoFortalezaEntities: Array<Resumo>;
    public requestComissaoFortaleza: any;
    public totalDePaginasComissaoGeralFortaleza: number;

    public resumoConselhoMissaoBrasilEntities: Array<Resumo>;    
    public requestConselhoMissaoBrasil: any;
    public totalDePaginasConselhoGeralMissaoBrasil: number;

    public resumoComissaoMissaoBrasilEntities: Array<Resumo>;    
    public requestComissaoMissaoBrasil: any;
    public totalDePaginasComissaoGeralMissaoBrasil: number;

    public resumoConselhoMissaoExteriorEntities: Array<Resumo>;    
    public requestConselhoMissaoExterior: any;
    public totalDePaginasConselhoGeralMissaoExterior: number;

    public resumoComissaoMissaoExteriorEntities: Array<Resumo>;    
    public requestComissaoMissaoExterior: any;
    public totalDePaginasComissaoGeralMissaoExterior: number;

    constructor(
        public resumoSetorService: ResumoSetorService,
        public resumoMissaoDifusaoService: ResumoMissaoDifusaoService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.resumoConselhoDiaconiaEntities = new Array<Resumo>();           
        this.requestConselhoDiaconia = {
            ano : new Date().getFullYear(),
            empresaId : null,
            regionalId : null,
            pageNumber : 0, 
            quantityOfElements : 10
        };
        
        this.resumoComissaoDiaconiaEntities = new Array<Resumo>(); 
        this.requestComissaoDiaconia = {
            ano : new Date().getFullYear(),
            empresaId : null,
            regionalId : null,
            pageNumber : 0, 
            quantityOfElements : 10
        };   
        
        this.resumoConselhoFortalezaEntities = new Array<Resumo>();           
        this.requestConselhoFortaleza = {
            ano : new Date().getFullYear(),
            empresaId : null,
            regionalId : null,
            pageNumber : 0, 
            quantityOfElements : 10
        };

        this.resumoComissaoFortalezaEntities = new Array<Resumo>(); 
        this.requestComissaoFortaleza = {
            ano : new Date().getFullYear(),
            empresaId : null,
            regionalId : null,
            pageNumber : 0, 
            quantityOfElements : 10
        };

        this.resumoConselhoMissaoBrasilEntities = new Array<Resumo>();           
        this.requestConselhoMissaoBrasil = {
            ano : new Date().getFullYear(),
            empresaId : null,
            regionalId : null,
            pageNumber : 0, 
            quantityOfElements : 10
        };

        this.resumoComissaoMissaoBrasilEntities = new Array<Resumo>();           
        this.requestComissaoMissaoBrasil = {
            ano : new Date().getFullYear(),
            empresaId : null,
            regionalId : null,
            pageNumber : 0, 
            quantityOfElements : 10
        };

        this.resumoConselhoMissaoExteriorEntities = new Array<Resumo>();           
        this.requestConselhoMissaoExterior = {
            ano : new Date().getFullYear(),
            empresaId : null,
            regionalId : null,
            pageNumber : 0, 
            quantityOfElements : 10
        };

        this.resumoComissaoMissaoExteriorEntities = new Array<Resumo>();           
        this.requestComissaoMissaoExterior = {
            ano : new Date().getFullYear(),
            empresaId : null,
            regionalId : null,
            pageNumber : 0, 
            quantityOfElements : 10
        };
    }

    ngOnInit() {
        this.buscarConselhoDiaconia();
        this.buscarComissaoDiaconia();
        this.buscarConselhoFortaleza();
        this.buscarComissaoFortaleza();
        this.buscarConselhoMissaoBrasil();
        this.buscarComissaoMissaoBrasil();
        this.buscarConselhoMissaoExterior();
        this.buscarComissaoMissaoExterior();
    }   

    buscarConselhoDiaconia() : void {
        this.resumoSetorService.buscarConselhoDiaconia(this.requestConselhoDiaconia).then( (response: any) => {
            this.resumoConselhoDiaconiaEntities = response.content;
            this.totalDePaginasConselhoGeralDiaconia = response.totalPages;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    buscarComissaoDiaconia() : void {
        this.resumoSetorService.buscarComissaoDiaconia(this.requestComissaoDiaconia).then( (response: any) => {
            this.resumoComissaoDiaconiaEntities = response.content;
            this.totalDePaginasComissaoGeralDiaconia = response.totalPages;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    buscarConselhoFortaleza() : void {
        this.resumoSetorService.buscarConselhoFortaleza(this.requestConselhoFortaleza).then( (response: any) => {
            this.resumoConselhoFortalezaEntities = response.content;
            this.totalDePaginasConselhoGeralFortaleza = response.totalPages;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    buscarComissaoFortaleza() : void {
        this.resumoSetorService.buscarComissaoFortaleza(this.requestComissaoFortaleza).then( (response: any) => {
            this.resumoComissaoFortalezaEntities = response.content;
            this.totalDePaginasComissaoGeralFortaleza = response.totalPages;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    buscarConselhoMissaoBrasil() : void {
        this.resumoMissaoDifusaoService.buscarConselhoMissaoBrasil(this.requestConselhoMissaoBrasil).then( (response: any) => {
            this.resumoConselhoMissaoBrasilEntities = response.content;
            this.totalDePaginasConselhoGeralMissaoBrasil = response.totalPages;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    buscarComissaoMissaoBrasil() : void {
        this.resumoMissaoDifusaoService.buscarComissaoMissaoBrasil(this.requestComissaoMissaoBrasil).then( (response: any) => {
            this.resumoComissaoMissaoBrasilEntities = response.content;
            this.totalDePaginasComissaoGeralMissaoBrasil = response.totalPages;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    buscarConselhoMissaoExterior() : void {
        this.resumoMissaoDifusaoService.buscarConselhoMissaoExterior(this.requestConselhoMissaoExterior).then( (response: any) => {
            this.resumoConselhoMissaoExteriorEntities = response.content;
            this.totalDePaginasConselhoGeralMissaoExterior = response.totalPages;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    buscarComissaoMissaoExterior() : void {
        this.resumoMissaoDifusaoService.buscarComissaoMissaoExterior(this.requestComissaoMissaoExterior).then( (response: any) => {
            this.resumoComissaoMissaoExteriorEntities = response.content;
            this.totalDePaginasComissaoGeralMissaoExterior = response.totalPages;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    
    buscarConselhoGeralDiaconia(request:any) {
        this.requestConselhoDiaconia = request;
        this.buscarConselhoDiaconia();
    }

    buscarComissaoGeralDiaconia(request:any) {
        this.requestComissaoDiaconia = request;
        this.buscarComissaoDiaconia();
    }

    buscarConselhoGeralFortaleza(request:any) {
        this.requestConselhoFortaleza = request;
        this.buscarConselhoFortaleza();
    }

    buscarComissaoGeralFortaleza(request:any) {
        this.requestComissaoFortaleza = request;
        this.buscarComissaoFortaleza();
    }

    buscarConselhoGeralMissaoBrasil(request:any) {
        this.requestConselhoMissaoBrasil = request;
        this.buscarConselhoMissaoBrasil();
    }

    buscarComissaoGeralMissaoBrasil(request:any) {
        this.requestComissaoMissaoBrasil = request;
        this.buscarComissaoMissaoBrasil();
    }

    buscarConselhoGeralMissaoExterior(request:any) {
        this.requestConselhoMissaoExterior = request;
        this.buscarConselhoMissaoExterior();
    }

    buscarComissaoGeralMissaoExterior(request:any) {
        this.requestComissaoMissaoExterior = request;
        this.buscarComissaoMissaoExterior();
    }
    
}