import { Component, Input, OnInit } from '@angular/core';
import { CategoriaPlanejamentoOrcamentario } from 'src/app/model/categoriaplanejamentoorcamentario.model';
import { ContaPlanoService } from '../../contaplano/service/contaplano.service';
import { DetalheContaService } from '../../detalheconta/service/detalheconta.service';
import { PrevisaoOrcamentariaService } from '../../previsaoorcamentaria/service/previsaoorcamentaria.service';
import { PrevisaoOrcamentaria } from 'src/app/model/previsaoorcamentaria.model';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { PlanilhaUtilsService } from '../service/planilhautils.service';
import { PrevisaoOrcamentariaBuilder } from 'src/app/builder/previsaoorcamentaria.builder';
import { PlanejamentoOrcamentarioService } from '../service/planejamentoorcamentario.service';
import { PlanilhaPlanejamentoOrcamentario } from 'src/app/model/planilhaplanejamentoorcamentario.model';
import { PlanilhaPlanejamentoOrcamentarioBuilder } from 'src/app/builder/planilhaplanejamentoorcamentario.builder';
import { MesPrevisaoOrcamentaria } from 'src/app/model/mesprevisaoorcamentaria.model';

@Component({
    selector: '[historico-planejamento-orcamentario]',
    templateUrl: './historicoplanejamentoorcamentario.component.html',
    styleUrls: ['../style/table-sheet.css','../style/scrollbar.css']
})
export class HistoricoPlanejamentoOrcamentarioComponent implements OnInit {

    @Input() 
    public planilhaPlanejamentoOrcamentario: any;
    public planilhaPlanejamentoOrcamentarioBulder: PlanilhaPlanejamentoOrcamentarioBuilder;
    public mesesPrevisaoOrcamentaria: Array<MesPrevisaoOrcamentaria>;


    constructor(
        public planejamentoOrcamentarioService: PlanejamentoOrcamentarioService,
        public planilhaUtilService: PlanilhaUtilsService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.planilhaPlanejamentoOrcamentarioBulder = new PlanilhaPlanejamentoOrcamentarioBuilder();
        this.planilhaPlanejamentoOrcamentario = this.planilhaPlanejamentoOrcamentarioBulder.getInstance();
        this.mesesPrevisaoOrcamentaria = new Array<MesPrevisaoOrcamentaria>();
    }    

    ngOnInit() {
        if(this.planilhaPlanejamentoOrcamentario)
        {
            this.planilhaPlanejamentoOrcamentario.previsoes = this.planilhaPlanejamentoOrcamentario.previsoes.sort((a, b) => a.categoriaPlanejamentoOrcamentarioId - b.categoriaPlanejamentoOrcamentarioId);
            this.planilhaPlanejamentoOrcamentario.previsoesAvulsas = this.planilhaPlanejamentoOrcamentario.previsoesAvulsas.sort((a, b) => a.categoriaPlanejamentoOrcamentarioId - b.categoriaPlanejamentoOrcamentarioId);
            this.mesesPrevisaoOrcamentaria = this.planilhaPlanejamentoOrcamentario.margemSeguranca.meses;
        }        
    }

    // carregarPlanilhaPlanejamentoOrcamentario(id: number): void {
    //     this.planejamentoOrcamentarioService.gerarPlanilhaPO(id).then((response: any) => {
    //         this.planilhaPlanejamentoOrcamentario = response;
    //         this.planilhaPlanejamentoOrcamentario.previsoes = this.planilhaPlanejamentoOrcamentario.previsoes.sort((a, b) => a.categoriaPlanejamentoOrcamentarioId - b.categoriaPlanejamentoOrcamentarioId);
    //         this.planilhaPlanejamentoOrcamentario.previsoesAvulsas = this.planilhaPlanejamentoOrcamentario.previsoesAvulsas.sort((a, b) => a.categoriaPlanejamentoOrcamentarioId - b.categoriaPlanejamentoOrcamentarioId);
    //         this.mesesPrevisaoOrcamentaria = this.planilhaPlanejamentoOrcamentario.margemSeguranca.meses;
    //     }).catch((err: any) => {
    //         this.swtAlert2Service.errorAlert(err.error.errors);
    //     });
    // }

    public colorHexToRGB(hex:any,previsao:PrevisaoOrcamentaria) : any {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        let retorno = null;
        let fatorCorrecao = previsao.nivelArvore*0.20;
        
        if( 
            result 
            && 
            ( previsao.contaPlanoId != null && ( ( previsao.detalheContaId == null ) || ( previsao.detalheContaId != null && previsao.detalheContaTipoHierarquico != 'ITEM' ) ) ) 
        )            
        {
            retorno = {
                r: parseInt(result[1], 16) + (255 - parseInt(result[1], 16))*fatorCorrecao,
                g: parseInt(result[2], 16) + (255 - parseInt(result[2], 16))*fatorCorrecao,
                b: parseInt(result[3], 16) + (255 - parseInt(result[3], 16))*fatorCorrecao
            };
        } 
        else
        {
            retorno = {
                r: 255,
                g: 255,
                b: 255
            };
        }

        return result ? 'rgb('+retorno.r+','+retorno.g+','+retorno.b+')' : null;
    }

    public unilinhaColorHexToRGB(hex:any,previsao:PrevisaoOrcamentaria) : any {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        let retorno = null;
        let fatorCorrecao = previsao.nivelArvore*0.20;
        
        if( 
            result 
            && 
            (previsao.contaPlanoId == null && previsao.detalheContaId == null)
        )            
        {
            retorno = {
                r: parseInt(result[1], 16) + parseInt(result[1], 16)*fatorCorrecao,
                g: parseInt(result[2], 16) + parseInt(result[2], 16)*fatorCorrecao,
                b: parseInt(result[3], 16) + parseInt(result[3], 16)*fatorCorrecao
            };
        } 
        else
        {
            retorno = {
                r: 255,
                g: 255,
                b: 255
            };
        }

        return result ? 'rgb('+retorno.r+','+retorno.g+','+retorno.b+')' : null;
    }

    public compareDate(data1:any, data2:any) : any {
        return new Date(data1.data).getTime() - new Date(data2.data).getTime();
    }

}