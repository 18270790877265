import { Component, OnInit } from '@angular/core';
import { ContaPlano } from 'src/app/model/contaplano.model';
import { ContaPlanoService } from './service/contaplano.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'contasplano',
    templateUrl: './contasplano.component.html'
})
export class ContasPlanoComponent implements OnInit {

    public contasPlano: Array<ContaPlano>;
    public busca: any;
    public totalDePaginas: number;
    public url : string;

    constructor(
        public contasPlanoService: ContaPlanoService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.contasPlano = new Array<ContaPlano>();  
        this.url = environment.contaPlano.gruposPorNomeURL;              
        this.busca = {            
            nome : undefined,
            codigo : undefined,
            w4Id : undefined,
            tipoHierarquico : null,
            tipoOperacao : null,
            contaPlanoPertencenteId : undefined,
            pageNumber : 0, 
            quantityOfElements : 10
        };
    }

    ngOnInit() {
        this.buscarContasPlano();
    }   

    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero-1;
        this.buscarContasPlano();
    }

    buscarContasPlano() : void {

        this.contasPlanoService.buscarContasPlano(this.busca).then( (response: any) => {
            this.contasPlano = response.content;
            this.totalDePaginas = response.totalPages;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
        
    }
    
    deletar(id: number) : void {

        if(confirm('Deseja deletar este conta do plano?'))
        {
            this.contasPlanoService.delete(id)
            .then( (response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscarContasPlano();
            })
            .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }

    limparSelect2() : void {
        $("select[name='cp_pertencente']").empty();
        this.setarElementoSelecionado(null);
    }

    setarElementoSelecionado(id): void {
        this.busca.contaPlanoPertencenteId = id;
    }
}