import { Component, OnInit } from '@angular/core';
import { RegionalService } from './service/regional.service';
import { Regional } from 'src/app/model/regional.model';
import { Router, ActivatedRoute } from '@angular/router';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { RegionalBuilder } from 'src/app/builder/regional.builder';

@Component({
    selector: 'regional-edit',
    templateUrl: './regional.component.html'
})
export class RegionalEditComponent implements OnInit {

    public regionalBuilder : RegionalBuilder;
    public regional: Regional;
    public regionalId: number;
    public busca: any;
    public totalDePaginas: number;

    constructor(
        public regionalService: RegionalService,
        public swtAlert2Service: SwtAlert2Service,
        public route: ActivatedRoute,
        public router: Router
    ) {
        this.regionalBuilder = new RegionalBuilder();
        this.regional = this.regionalBuilder.getInstance();        
    }

    ngOnInit() {
        this.regionalId = Number(this.route.snapshot.paramMap.get('id'));
        this.carregarRegional(this.regionalId);
    }

    carregarRegional(id: number): void {
        this.regionalService.find(id).then((response: any) => {
            this.regional = response.entity;
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    salvar(regional: Regional): void {

        this.regionalService.update(regional.id, regional).then((response: any) => {
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/regionais']);
            }, 2000);
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }


}