import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResumoComponent } from './resumo.component';

const resumoRoutes: Routes = [
    {
        path: 'secured/resumo',
        component: ResumoComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(resumoRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class ResumoRoutingModule {
    
}