import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ResumoSetorService } from './service/resumosetor.service';
import { ResumoMissaoDifusaoService } from './service/resumomissaodifusao.service';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../../layout/secured.module';
import { RouterModule } from '@angular/router';
import { ResumoRoutingModule } from './resumo-routing.module';
import { ResumoComponent } from './resumo.component';
import { RelatorioResumoDiaconiaComponent } from './diaconia.component/diaconia.component';
import { ElementoGrupoDominioService } from '../../grupodominio/service/elementogrupodominio.service';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ResumoUtilsService } from './service/resumoutils.service';
import { RelatorioResumoFortalezaComponent } from './fortaleza.component/fortaleza.component';
import { RelatorioResumoMissaoBrasilComponent } from './missaobrasil.component/missaobrasil.component';
import { RelatorioResumoMissaoExteriorComponent } from './missaoexterior.component/missaoexterior.component';

@NgModule({
    declarations: [ 
        ResumoComponent,
        RelatorioResumoDiaconiaComponent,
        RelatorioResumoFortalezaComponent,
        RelatorioResumoMissaoBrasilComponent,
        RelatorioResumoMissaoExteriorComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        ResumoRoutingModule,
        FormsModule,
        SecuredModule,
        CurrencyMaskModule
    ],
    providers: [
        ResumoSetorService,
        ResumoMissaoDifusaoService,
        ElementoGrupoDominioService,
        ResumoUtilsService
    ]
})
export class ResumoModule {

}