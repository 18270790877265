import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { ContaDetalheChave } from 'src/app/model/contadetalhechave.model';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";


@Injectable()
export class ContaDetalheChaveService extends GenericService<ContaDetalheChave> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('contadetalhechave');
    }

    findByVariavelDetalhesContaId(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/porvariaveldetalhesconta/${id}`, { 'headers' : this.headers }).toPromise();
    }

}