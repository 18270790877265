import { NgModule } from '@angular/core';
import { ContaPlanoComponent } from './contaplano.component';
import { Routes, RouterModule } from '@angular/router';
import { ContasPlanoComponent } from './contasplano.component';
import { ContaPlanoEditComponent } from './contaplano.edit.component';

const contaPlanoRoutes: Routes = [
    {
        path: 'secured/contaplano',
        component: ContaPlanoComponent
    },
    {
        path: 'secured/contasplano',
        component: ContasPlanoComponent
    },
    {
        path: 'secured/contaplano/:id',
        component: ContaPlanoEditComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(contaPlanoRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class ContaPlanoRoutingModule {
    
}