import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { ContaPlano } from 'src/app/model/contaplano.model';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";


@Injectable()
export class ContaPlanoService extends GenericService<ContaPlano> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('contaplano');
    }

    buscarContaPlano(busca: any) {
        return this.http.post<any>(this.apiURL+'/buscar', JSON.stringify(busca), { 'headers' : this.headers }).toPromise();
    }

    buscarContasPlano(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/buscar', JSON.stringify(busca), { 'headers' : this.headers }).toPromise();
    }

    buscaPorContaPlanoPertencenteId(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/porcontaplanopertencente/${id}`, { 'headers' : this.headers }).toPromise();
    }

    vicularContaPlano(req: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/vincularcontaplano', JSON.stringify(req), { 'headers' : this.headers }).toPromise();
    } 

    vicularDetalheConta(req: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/vinculardetalheconta', JSON.stringify(req), { 'headers' : this.headers }).toPromise();
    } 

    desvicularContaPlano(req: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/desvincularcontaplano', JSON.stringify(req), { 'headers' : this.headers }).toPromise();
    } 

    desvicularDetalheConta(req: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/desvinculardetalheconta', JSON.stringify(req), { 'headers' : this.headers }).toPromise();
    } 

    buscaPorDetalheContaId(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/pordetalheconta/${id}`, { 'headers' : this.headers }).toPromise();
    }

}