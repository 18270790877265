import { Injectable } from '@angular/core';
import { PrevisaoOrcamentaria } from 'src/app/model/previsaoorcamentaria.model';


@Injectable()
export class PlanilhaUtilsService {

    onScroll(event,tabela): void {
        $("table[name='"+tabela+"']:not(thead) > *").width($("table[name='"+tabela+"']:not(thead)").width() + $("table[name='"+tabela+"']").scrollLeft());
        $("table[name='"+tabela+"']").find('tbody').scrollLeft($("table[name='"+tabela+"']").find('thead').scrollLeft());
    }

    onTab(event,tabela): void {
        var scrollLeft = $("table[name='"+tabela+"']").scrollLeft();
        scrollLeft += $(event.target).parent().width();
        $("table[name='"+tabela+"']").scrollLeft(scrollLeft);		
    }

    textBold(previsao: PrevisaoOrcamentaria) : boolean {
        
        let retorno:boolean = false;

        if(previsao != undefined)
        {
            if(previsao.id == null)
            {
                retorno = true;
            }
            else if(previsao.detalheContaId == null && previsao.contaPlanoId != null)
            {
                retorno = true;
            }
            else
            {
                retorno = previsao.detalheContaTipoHierarquico == 'GRUPO';
            }
        }

        return retorno;

    }

    espacamentoInicial(previsao: PrevisaoOrcamentaria) : string {

        let retorno = '';

        if(previsao != undefined)
        {
            if(previsao.id == null)
            {
                return '5px';
            }
            else if(previsao.nivelArvore==0)
            {
                retorno += '5px';
            }
            else
            {
                retorno += ((previsao.nivelArvore*15)+5)+'px';
            }
            }

        return retorno; 
    }

    espacamentoInicialRelatorio(linha: any) : string {

        let retorno = '';

        if(linha != undefined)
        {
            retorno += ((linha.nivelArvore*15)+5)+'px';   
        }

        return retorno; 
    }

    textoBotaoCopiar(prevOrc: PrevisaoOrcamentaria) : string {

        let retorno:string = "Deseja copiar este elemento? - ";

        if(prevOrc.detalheContaId != null) {
            retorno += prevOrc.detalheContaNome;
        }
        else
        {
            retorno += prevOrc.contaPlanoNome;
        }

        return retorno;

    }

    textoBotaoPlus(prevOrc: PrevisaoOrcamentaria) : string {

        let retorno:string = "Adicionar Sub-Item a ";

        if(prevOrc.detalheContaId != null) {
            retorno += prevOrc.detalheContaNome;
        }
        else
        {
            retorno += prevOrc.contaPlanoNome;
        }

        return retorno;

    }

    textoBotaoMinus(prevOrc: PrevisaoOrcamentaria) : string {

        let retorno:string = "Remover ";

        if(prevOrc.detalheContaId != null) {

            if(prevOrc.detalheContaTipoHierarquico == 'GRUPO')
            {
                retorno += "Grupo ";
            }
            else
            {
                retorno += "Item ";
            }

            retorno += prevOrc.detalheContaNome;
        }
        else if(prevOrc.contaPlanoId != null)
        {

            if(prevOrc.contaPlanoTipoHierarquico == 'GRUPO')
            {
                retorno += "Grupo ";
            }
            else
            {
                retorno += "Item ";
            }

            retorno += prevOrc.contaPlanoNome;
        }
        else
        {
            retorno += "Item " + prevOrc.nome;
        }

        return retorno;

    }

    filtragemEdicaoLinhas(regraUsuario:String, nome:String, codigoConta: string) : boolean {
        
        let retorno = true;

        if(!regraUsuario)
        {
            retorno = false;
        }
        else if(regraUsuario == 'ROLE_USUARIO' || "ROLE_USUARIO_INTERMEDIARIO" || "ROLE_GESTOR" || "ROLE_CONSELHO")
        {
            if(
                nome == 'Despesa de Comunhão de Bens'
                ||
                nome == 'Repasse Concedido para Fundo Desenv Governo Geral'
                ||
                nome == 'Despesa de Comunhão de Bens com a Igreja Local'
            )
            {
                retorno = false;
            }
        }        

        if(regraUsuario == 'ROLE_USUARIO' && codigoConta && codigoConta.includes('2.2.1.1.'))
        {
            retorno = false;
        }

        if(regraUsuario == 'ROLE_USUARIO_VISUALIZADOR')
        {
            retorno = false;
        }

        return retorno;

    }

}