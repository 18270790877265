import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CentroCustoService } from './service/centrocusto.service';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../layout/secured.module';
import { RouterModule } from '@angular/router';
import { CentroCustoComponent } from './centrocusto.component';
import { CentroCustoRoutingModule } from './centrocusto-routing.module';
import { CentroCustoEditComponent } from './centrocusto.edit.component';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { CentrosCustoComponent } from './centroscusto.component';

@NgModule({
    declarations: [ 
        CentroCustoComponent,
        CentrosCustoComponent,
        CentroCustoEditComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        CentroCustoRoutingModule,
        FormsModule,
        SecuredModule
    ],
    providers: [
        CentroCustoService,
        SwtAlert2Service
    ]
})
export class CentroCustoModule {

}