import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '../../usuario/service/usuario.service';
import { Usuario } from 'src/app/model/usuario.model';
import { Pessoa } from 'src/app/model/pessoa.model';
import { PessoaService } from '../../pessoa/service/pessoa.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'left-sidebar-comp',
    templateUrl: './left-sidebar.component.html',
    styleUrls: ['./style/scrollbar.css']
})
export class LeftSidebarComponent implements OnInit {

    public usuarioAtual: Usuario;
    public pessoaAtual: Pessoa;
    public urlBaseImagem: string;

    constructor(
        public usuarioService: UsuarioService,
        public pessoaService: PessoaService
    ) {
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
    }

    async getUsuarioAtual() : Promise<any> {

        try 
        {
            let response = await this.usuarioService.getCurrentUser();   
            this.usuarioAtual = response.entity;    
            await this.getPessoaAtual();     
        }
        catch(e)
        {
            console.log(e);
        }

    }

    async getPessoaAtual() : Promise<any> {

        try 
        {
            let response = await this.pessoaService.buscaPorUsuarioId(this.usuarioAtual.id);            
            this.pessoaAtual = response.entity;
        }
        catch(e)
        {
            console.log(e);
        }

    }

    exibirParaRole(regras: string[]) : boolean {

        let retorno = false;

        if(this.usuarioAtual && regras.includes(this.usuarioAtual.autorizacoesNome[0]))
        {
            retorno = true;
        }

        return retorno;

    }

    async initialize() : Promise<any> {
        await this.getUsuarioAtual();        
    }    

    ngOnInit(): void {
        this.initialize();
    }   

}