import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RegraPOService } from './service/regrapo.service';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../layout/secured.module';
import { RouterModule } from '@angular/router';
import { RegraPOComponent } from './regrapo.component';
import { RegraPORoutingModule } from './regrapo-routing.module';
import { RegrasPOComponent } from './regraspo.component';
import { RegraPOEditComponent } from './regrapo.edit.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { VariavelConstanteService } from './service/variavelconstante.service';
import { VariavelDetalhesContaService } from './service/variaveldetalhesconta.service';
import { VariavelResultadoService } from './service/variavelresultado.service';
import { PassoRegraPOService } from './service/passoregrapo.service';
import { ContaDetalheChaveService } from './service/contadetalhechave.service';
import { NgpSortModule } from "ngp-sort-pipe";

@NgModule({
    declarations: [ 
        RegraPOComponent,
        RegrasPOComponent,
        RegraPOEditComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        RegraPORoutingModule,
        FormsModule,
        SecuredModule,
        CurrencyMaskModule,
        NgpSortModule
    ],
    providers: [
        RegraPOService,
        VariavelConstanteService,
        VariavelDetalhesContaService,
        VariavelResultadoService,
        PassoRegraPOService,
        ContaDetalheChaveService
    ]
})
export class RegraPOModule {

}