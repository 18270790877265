export class PlanejamentoOrcamentario { 
    constructor(
        public id: number,
        public nome: string,
        public empresaId: number,
        public empresaNome: string,
        public periodoInicio: Date,
        public periodoFim: Date,
        public statusAndamentoId: number,
        public statusAndamentoNome: string,
        public instanciaId: number,
        public instanciaNome: string,
        public categoriasIds: Array<number>,
        public taxaConversao: number,
        public converterValoresUsandoTaxa: boolean,
        public fechado: boolean,
        public regrasIds: Array<number>,
        public historicos: Array<any>
    ) {}
}