import { CategoriaPlanejamentoOrcamentario } from '../model/categoriaplanejamentoorcamentario.model';
import { AbstractBuilder } from './abstract.builder';


export class CategoriaPlanejamentoOrcamentarioBuilder extends AbstractBuilder<CategoriaPlanejamentoOrcamentario> {

    reset() : void {
        this.entity = new CategoriaPlanejamentoOrcamentario(null,null,null,new Array<number>(),true,true,true,true,true,true,true,true,false,null,null,null);
    }

}