import { Component, OnInit } from '@angular/core';
import { MissaoService } from './service/missao.service';
import { Missao } from 'src/app/model/missao.model';
import { Alerta } from 'src/app/model/alerta.model';
import { Regional } from 'src/app/model/regional.model';
import { RegionalService } from '../regional/service/regional.service';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { MissaoBuilder } from 'src/app/builder/missao.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'missao',
    templateUrl: './missao.component.html'
})
export class MissaoComponent implements OnInit {

    public missao: Missao;
    public regionais: Array<Regional>;
    public paisSelecionadoId: number;
    public estadoSelecionadoId: number;
    public fasesMissao: Array<ElementoGrupoDominio>;
    public missaoBuilder: MissaoBuilder;

    constructor(
        public missaoService: MissaoService,
        public regionalService: RegionalService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.missaoBuilder = new MissaoBuilder();
        this.missao = this.missaoBuilder.getInstance();
        this.regionais = new Array<Regional>();
        this.paisSelecionadoId = null;
        this.estadoSelecionadoId = null;
        this.fasesMissao = new Array<ElementoGrupoDominio>();        
    }

    ngOnInit() {  
        this.carregarRegionais();   
        this.carregarFasesMissao();  
    }

    carregarRegionais() : void {
        this.regionalService.findAll().then( (lista: any) => {
            this.regionais = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarFasesMissao() : void {

        let buscaFase = {
            grupoNome : 'FASE_MISSAO'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase).then( (lista: any) => {
            this.fasesMissao = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    salvar(missao: Missao) : void {

        this.missaoService.create(missao).then( (response:any) => {
            this.missao = this.missaoBuilder.getInstance();
            this.swtAlert2Service.successAlert(response.message);
        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

}