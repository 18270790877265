import { PassoRegraPO } from '../model/passoregraplanejamentoorcamentario.model';
import { AbstractBuilder } from './abstract.builder';


export class PassoRegraPOBuilder extends AbstractBuilder<PassoRegraPO> {

    reset() : void {
        this.entity = new PassoRegraPO(null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null);
    }

}