import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NotificacaoService } from './service/notificacao.service';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../layout/secured.module';
import { RouterModule } from '@angular/router';
import { NotificacaoComponent } from './notificacao.component';
import { NotificacaoRoutingModule } from './notificacao-routing.module';

@NgModule({
    declarations: [ 
        NotificacaoComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        NotificacaoRoutingModule,
        FormsModule,
        SecuredModule
    ],
    providers: [
        NotificacaoService
    ]
})
export class NotificacaoModule {

}