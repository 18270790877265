import { Component, OnInit } from '@angular/core';
import { DetalheContaService } from './service/detalheconta.service';
import { DetalheConta } from 'src/app/model/detalheconta.model';
import { Router, ActivatedRoute } from '@angular/router';
import { DetalheContaBuilder } from 'src/app/builder/detalheconta.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { environment } from 'src/environments/environment';
import { ContaPlanoService } from '../contaplano/service/contaplano.service';
import { ContaPlano } from 'src/app/model/contaplano.model';

@Component({
    selector: 'detalheconta-edit',
    templateUrl: './detalheconta.component.html'
})
export class DetalheContaEditComponent implements OnInit {

    public detalheConta: DetalheConta;
    public detalhesContaVinculadas: Array<DetalheConta>;
    public contasPlanoPertencente: Array<ContaPlano>;
    public detalheContaId: number;
    public busca: any;
    public detalheContaBuilder: DetalheContaBuilder;
    public urlGruposPorNomeURL : string;
    public urlBuscaPorDetalheIdENomeParaVinculacao : string;
    public urlBuscaPorNomeParaVinculacaoDeContaPlano : string;
    public requestVinculacao: any;

    constructor(
        public detalheContaService: DetalheContaService,
        public contaPlanoService: ContaPlanoService,
        public swtAlert2Service: SwtAlert2Service,
        public route: ActivatedRoute,
        public router: Router
    ) {
        this.detalheContaBuilder = new DetalheContaBuilder();
        this.detalheConta = this.detalheContaBuilder.getInstance();    
        this.detalhesContaVinculadas = new Array<DetalheConta>();    
        this.contasPlanoPertencente = new Array<ContaPlano>();
        this.busca = {
            detalheContaId: this.detalheContaId,
            pageNumber: 0,
            quantityOfElements: 10
        };  
        this.urlGruposPorNomeURL = environment.detalheConta.gruposPorNomeURL;
        this.urlBuscaPorDetalheIdENomeParaVinculacao = environment.detalheConta.urlBuscaPorDetalheIdENomeParaVinculacao+this.detalheContaId;
        this.urlBuscaPorNomeParaVinculacaoDeContaPlano = environment.contaPlano.buscaPorNome;              
    }

    ngOnInit() {
        this.route.params.subscribe(
            params => {
                this.detalheContaId = +params['id'];
                this.carregarDetalheConta(this.detalheContaId);
                this.carregarDetalhesContaVinculadas();
                this.carregarContasPlanoPertencente(); 
                this.urlGruposPorNomeURL = environment.detalheConta.gruposPorNomeURL;
                this.urlBuscaPorDetalheIdENomeParaVinculacao = environment.detalheConta.urlBuscaPorDetalheIdENomeParaVinculacao+this.detalheContaId;
                this.urlBuscaPorNomeParaVinculacaoDeContaPlano = environment.contaPlano.buscaPorNome;               
            }
        );          
    }

    carregarDetalheConta(id: number): void {
        this.detalheContaService.find(id).then((response: any) => {
            this.detalheConta = response.entity;  
            if(this.detalheConta.detalheContaPertencenteId != null)
            {
                $("select[name='dc_pertencente']").append(new Option(this.detalheConta.detalheContaPertencenteNome, this.detalheConta.detalheContaPertencenteId.toString(), false, true));
            }         
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    async carregarDetalhesContaVinculadas(): Promise<any> {
        
        try
        {
            let response = await this.detalheContaService.buscaPorDetalheContaPertencenteId(this.detalheContaId);
            this.detalhesContaVinculadas = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarContasPlanoPertencente(): Promise<any> {
        
        try
        {
            let response = await this.contaPlanoService.buscaPorDetalheContaId(this.detalheContaId);
            this.contasPlanoPertencente = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    salvar(detalheConta: DetalheConta): void {

        this.detalheContaService.update(detalheConta.id, detalheConta).then((response: any) => {
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/detalhesconta']);
            }, 2000);

        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    deletar(id: number) : void {

        if(confirm('Deseja deletar este detalhe da conta?'))
        {
            this.detalheContaService.delete(id)
            .then( (response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.carregarDetalhesContaVinculadas();
            })
            .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }

    setarDetalheContaVinculavel(id:any): void {
        this.requestVinculacao = {
            "id" : this.detalheContaId,
            "vincularId" : Number(id)
        };
    }

    setarContaPlanoVinculavel(id:any): void {
        this.requestVinculacao = {
            "id" : this.detalheContaId,
            "vincularId" : Number(id)
        };
    }

    async vincularDetalheConta(id:any): Promise<any> {

        try 
        {
            let response = await this.detalheContaService.vicularDetalheConta(this.requestVinculacao);
            this.requestVinculacao = {};
            this.limparSelect2Vinculavel();
            this.swtAlert2Service.successAlert(response.message);
            this.carregarDetalhesContaVinculadas();
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.message);
        }

    }

    async vincularContaPlano(id:any): Promise<any> {

        try 
        {
            let response = await this.detalheContaService.vicularContaPlano(this.requestVinculacao);
            this.requestVinculacao = {};
            this.limparSelect2ContaPlanoVinculavel();
            this.swtAlert2Service.successAlert(response.message);
            this.carregarContasPlanoPertencente();
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.message);
        }

    }

    async desvincularDetalheConta(id:any): Promise<any> {

        this.requestVinculacao = {
            "id" : this.detalheContaId,
            "vincularId" : Number(id)
        };

        try 
        {
            let response = await this.detalheContaService.desvicularDetalheConta(this.requestVinculacao);
            this.requestVinculacao = {};
            this.swtAlert2Service.successAlert(response.message);
            this.carregarDetalhesContaVinculadas();
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.message);
        }

    }

    async desvincularContaPlano(id:any): Promise<any> {

        this.requestVinculacao = {
            "id" : this.detalheContaId,
            "vincularId" : Number(id)
        };

        try 
        {
            let response = await this.detalheContaService.desvicularContaPlano(this.requestVinculacao);
            this.requestVinculacao = {};
            this.swtAlert2Service.successAlert(response.message);
            this.carregarContasPlanoPertencente();
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.message);
        }

    }

    limparSelect2() : void {
        $("select[name='dc_pertencente']").empty();
        this.setarElementoSelecionado(null);
    }

    limparSelect2Vinculavel() : void {
        $("select[name='dc_vincular']").empty();
    }

    limparSelect2ContaPlanoVinculavel() : void {
        $("select[name='cp_vincular']").empty();
    }

    setarElementoSelecionado(id): void {
        this.detalheConta.detalheContaPertencenteId = id;
    }

}