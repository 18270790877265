export class ContaDetalheChave {

    constructor(
        public id: number,
        public variavelDetalhesContaId: number,
        public variavelDetalhesContaNome: string,
        public contaPlanoId: number,
        public contaPlanoNome: string,
        public detalheContaId: number,
        public detalheContaNome: string,
    ){}

}