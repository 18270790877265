import { Component, OnInit } from '@angular/core';
import { GrupoDominioService } from './service/grupodominio.service';
import { GrupoDominio } from 'src/app/model/grupodominio.model';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { GrupoDominioBuilder } from 'src/app/builder/grupodominio.builder';

@Component({
    selector: 'grupodominio',
    templateUrl: './grupodominio.component.html'
})
export class GrupoDominioComponent {

    public grupoDominio: GrupoDominio;
    public grupoDominioBuilder: GrupoDominioBuilder;

    constructor(
        public grupoDominioService: GrupoDominioService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.grupoDominioBuilder = new GrupoDominioBuilder();
        this.grupoDominio = this.grupoDominioBuilder.getInstance();
    }

    salvar(grupoDominio: GrupoDominio) : void {

        this.grupoDominioService.create(grupoDominio).then( (response:any) => {
            this.grupoDominio = this.grupoDominioBuilder.getInstance();
            this.swtAlert2Service.successAlert(response.message);
        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

}