import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { VariavelDetalhesConta } from 'src/app/model/variaveldetalhesconta.model';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";


@Injectable()
export class VariavelDetalhesContaService extends GenericService<VariavelDetalhesConta> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('variaveldetalhesconta');
    }

    buscaPorRegraId(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/porregra/${id}`, { 'headers' : this.headers }).toPromise();
    }

    vincularDetalheConta(req: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/vinculardetalheconta', JSON.stringify(req), { 'headers' : this.headers }).toPromise();
    } 

    desvincularDetalheConta(req: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/desvinculardetalheconta', JSON.stringify(req), { 'headers' : this.headers }).toPromise();
    } 

}