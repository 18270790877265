import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { Injectable } from "@angular/core";


@Injectable()
export class ElementoGrupoDominioService extends GenericService<ElementoGrupoDominio> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('elementogrupodominio');
    }

    async buscarElementosGrupoDominio(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/buscar', JSON.stringify(busca), { 'headers' : this.headers }).toPromise();
    }

    async buscarPorGrupoDominioNome(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/pornomegrupo', JSON.stringify(busca), { 'headers' : this.headers }).toPromise();
    }

}