import { iGenericService } from './generic.service.interface';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Token } from 'src/app/model/token.model';
import { environment } from 'src/environments/environment';
import { Login } from 'src/app/model/login.model';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";

@Injectable()
export class GenericService<T> implements iGenericService<T> {

    protected apiURL: string = environment.configServidor.apiUrl;
    private tokenURL: string = environment.configServidor.tokenUrl;
    private checktokenURL: string = environment.configServidor.checkTokenUrl;
    
    protected token : Token = JSON.parse(localStorage.getItem("posh-token"));
    protected headers: HttpHeaders = new HttpHeaders({
                                                'Content-Type' : 'application/json; charset=UTF-8',
                                                'Authorization' : 'Bearer '+ (this.token ? this.token.access_token : '')
                                            });

    
    
    protected tokenHeaders: HttpHeaders = new HttpHeaders({
                                                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
                                                'Authorization' : environment.authorization                                               
                                            });


    constructor(protected http: HttpClient, protected router: Router) {}

    setEntityType(entityType : string) : void {
        this.apiURL += entityType;
    }
    
    create(entity: T): Promise<any> {
        return this.http.post<T>(this.apiURL, entity, { 'headers' : this.headers }).toPromise();        
    }  
    
    async createAsync(entity: T): Promise<any> {
        return this.http.post<T>(this.apiURL, entity, { 'headers' : this.headers }).toPromise();        
    } 
    
    update(id: number, entity: T): Promise<any> {
        return this.http.put<T>(this.apiURL+'/'+id, entity, { 'headers' : this.headers }).toPromise(); 
    }

    async updateAsync(id: number, entity: T): Promise<any> {
        return this.http.put<T>(this.apiURL+'/'+id, entity, { 'headers' : this.headers }).toPromise(); 
    }

    delete(id: number): Promise<any> {
        return this.http.delete<T>(this.apiURL+'/'+id, { 'headers' : this.headers }).toPromise();
    }

    async deleteAsync(id: number): Promise<any> {
        return this.http.delete<T>(this.apiURL+'/'+id, { 'headers' : this.headers }).toPromise();
    }

    find(id: number): Promise<any> {
        return this.http.get<T>(this.apiURL+'/'+id, { 'headers' : this.headers }).toPromise();
    }

    async findAsync(id: number): Promise<any> {
        return this.http.get<T>(this.apiURL+'/'+id, { 'headers' : this.headers }).toPromise();
    }

    findAll(): Promise<any> {
        return this.http.get<T[]>(this.apiURL, { 'headers' : this.headers }).toPromise();
    }

    async findAllAsync(): Promise<any> {
        return this.http.get<T[]>(this.apiURL, { 'headers' : this.headers }).toPromise();
    }

    getToken(login: Login) : Promise<any> {
        return this.http.post(this.tokenURL, login.toString(), { 'headers' : this.tokenHeaders })
                .toPromise()
                .then(response => {
                   localStorage.setItem("posh-token",JSON.stringify(response));
                   return response;
                })
                .catch(err => this.handleError(err));
    }

    checkToken() : Promise<any> {

        let savedToken:Token = JSON.parse(localStorage.getItem("posh-token"));

        return this.http.get(this.checktokenURL+savedToken.access_token, { 'headers' : this.tokenHeaders })
                .toPromise()
                .then( (response:any) => {
                    if(new Date(response.exp*1000).getTime() <= new Date().getTime()){
                        return this.refreshToken();
                    }
                })
                .catch(response => {
                    if(response.error.error == 'invalid_token' && (response.error.error_description.includes('Token was not recognised') || response.error.error_description.includes('Invalid access token'))) {
                        this.router.navigate(['/login']);
                    }
                    else 
                    if(response.error.error == 'invalid_token' && response.error.error_description.includes('Token has expired')){
                        this.refreshToken().then( response => response).catch(response => {
                            this.router.navigate(['/login']);
                        });
                    }
                });
    }

    refreshToken() : Promise<any> {
        return this.http.post(this.tokenURL,{ "grant_type" : "refresh_token", "refresh_token" : this.token.refresh_token },{ 'headers' : this.tokenHeaders })
        .toPromise()
        .then(response => {
            localStorage.setItem("posh-token",JSON.stringify(response));
        })
        .catch(err => this.handleError(err));
    }

  

    private handleError(response: any): Promise<any> {
        return Promise.reject(response);
    }



}