import { Component, OnInit } from '@angular/core';
import { PlanejamentoOrcamentario } from 'src/app/model/planejamentoorcamentario.model';
import { PlanejamentoOrcamentarioService } from '../service/planejamentoorcamentario.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { environment } from 'src/environments/environment';
import { ElementoGrupoDominioService } from '../../grupodominio/service/elementogrupodominio.service';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { UsuarioService } from '../../usuario/service/usuario.service';
import { PessoaService } from '../../pessoa/service/pessoa.service';
import { EmpresaService } from '../../empresa/service/empresa.service';
import { Usuario } from 'src/app/model/usuario.model';
import { Pessoa } from 'src/app/model/pessoa.model';
import { Empresa } from 'src/app/model/empresa.model';

@Component({
    selector: 'planejamentosorcamentarios',
    templateUrl: './planejamentosorcamentarios.component.html'
})
export class PlanejamentosOrcamentariosParaGestorComponent implements OnInit {

    public entities: Array<PlanejamentoOrcamentario>;
    public busca: any;
    public totalDePaginas: number;
    public urlBuscaEmpresaPorNome: string;
    public urlBuscaPessoaPorNome: string;
    public listaGrupos: Array<ElementoGrupoDominio>;
    public listaStatus: Array<ElementoGrupoDominio>;
    public listaInstancias: Array<ElementoGrupoDominio>;
    public usuarioAtual : Usuario;
    public pessoaAtual : Pessoa;
    public empresas : Array<Empresa>;

    constructor(
        public planejamentoOrcamentarioService: PlanejamentoOrcamentarioService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public swtAlert2Service: SwtAlert2Service,
        public usuarioService: UsuarioService,
        public pessoaService: PessoaService,
        public empresaService: EmpresaService
    ) 
    {
        this.entities = new Array<PlanejamentoOrcamentario>();        
        this.urlBuscaEmpresaPorNome = environment.empresa.empresaPorNomeUrl;
        this.urlBuscaPessoaPorNome = environment.pessoa.pessoaPorNomeUrl;
        this.listaGrupos = new Array<ElementoGrupoDominio>();
        this.listaStatus = new Array<ElementoGrupoDominio>();
        this.empresas = new Array<Empresa>();
        this.busca = {
            ano: null,
            empresaId: null,
            grupoId: null,
            statusId: null,
            instanciaId: null,
            fechado: null,
            pageNumber : 0, 
            quantityOfElements : 10
        };
    }

    ngOnInit() {
        this.carregarUsuarioEPessoaAtual();
        this.carregarGrupos();
        this.carregarStatus();
        this.buscar(null);
    }   

    async carregarUsuarioEPessoaAtual() : Promise<any> {

        try
        {
            let response = await this.usuarioService.getCurrentUser();
            this.usuarioAtual = response.entity;
            let pessoaResponse = await this.pessoaService.buscaPorUsuarioId(this.usuarioAtual.id);
            this.pessoaAtual = pessoaResponse.entity;
            this.carregarEmpresasPorPessoaVinculadaId(this.pessoaAtual.id);
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarEmpresasPorPessoaVinculadaId(id: number) : Promise<any> {

        let self = this;

        try
        {
            let response = await this.empresaService.buscaPorPessoaVinculadaId(id);
            this.empresas = response.entity.sort((a, b) => a.text.localeCompare(b.text));
            $('select[name="lpo_empresa"]').select2({
                theme: "bootstrap4"
            })
            .trigger('change')
            .on('change',function() {
                self.busca.empresaId = $(this).val();
            });
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    buscar(callback:any) : void {
        this.planejamentoOrcamentarioService.buscar(this.busca).then( (response: any) => {
            this.entities = response.content;
            this.totalDePaginas = response.totalPages;
            if(callback)
            {
                callback();
            }
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero-1;
        this.buscar(null);
    }
    
    async carregarGrupos() : Promise<any> {

        let buscaFase = {
            grupoNome : 'EMPRESA_GRUPO_PERTENCENTE'
        }

        try
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase);
            this.listaGrupos = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarStatus() : Promise<any> {

        let buscaFase = {
            grupoNome : 'STATUS_PO'
        }

        try
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase);
            this.listaStatus = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    setarElementoSelecionado(empresaId:number) : void {
        this.busca.empresaId = empresaId;
    }

    setarElementoSelecionadoPessoa(pessoaId:number) : void {
        this.busca.pessoaId = pessoaId;
    }

    limparSelect2(nome:string) : void {
        $("select[name='"+nome+"']").empty();
        this.setarElementoSelecionado(undefined);
        this.setarElementoSelecionadoPessoa(undefined);
        this.busca = {
            ano: null,
            empresaId: null,
            grupoId: null,
            statusId: null,
            instanciaId: null,
            fechado: null,
            pageNumber : 0, 
            quantityOfElements : 10
        };
    }

    limparEmpresaSelect2() : void {

        this.busca.empresaId = null;
        
        this.buscar(function() {
            $('select[name="lpo_empresa"]').select2({
                theme: "bootstrap4"
            });
        });

    }
}