import { Component, OnInit } from '@angular/core';
import { CentroCustoService } from './service/centrocusto.service';
import { CentroCusto } from 'src/app/model/centrocusto.model';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { CentroCustoBuilder } from 'src/app/builder/centrocusto.builder';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'centrocusto',
    templateUrl: './centrocusto.component.html'
})
export class CentroCustoComponent {

    public centroCusto: CentroCusto;
    public centroCustoBuilder: CentroCustoBuilder;
    public url: string;

    constructor(
        public centroCustoService: CentroCustoService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {        
        this.centroCustoBuilder = new CentroCustoBuilder();
        this.centroCusto = this.centroCustoBuilder.getInstance();
        this.url = environment.centroCusto.gruposPorNome;         
    }

    salvar(centrocusto: CentroCusto) : void {

        this.centroCustoService.create(centrocusto).then( (response:any) => {
            this.centroCusto = this.centroCustoBuilder.getInstance();
            this.swtAlert2Service.successAlert(response.message);
        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    limparSelect2() : void {
        $("select[name='cp_pertencente']").empty();
    }

    setarElementoSelecionado(id): void {
        this.centroCusto.centroCustoPertencenteId = id;
    }

}