import { Autorizacao } from './autorizacao.model';
import { VariavelConstante } from './variavelconstante.model';
import { VariavelDetalhesConta } from './variaveldetalhesconta.model';
export class PassoRegraPO {

    constructor(
        public id: number,
        public regraId: number,
        public variavelConstante1Id: number,
        public variavelConstante1Nome: string,
        public variavelConstante2Id: number,
        public variavelConstante2Nome: string,
        public variavelDetalhe1Id: number,
        public variavelDetalhe1Nome: string,
        public variavelDetalhe2Id: number,
        public variavelDetalhe2Nome: string,
        public variavelResultado1Id: number,
        public variavelResultado1Nome: string,
        public variavelResultado2Id: number,
        public variavelResultado2Nome: string,
        public resultadoFinalId: number,
        public resultadoFinalNome: string,
        public tipoOperacaoMatematica: string,
        public zerarSeNegativo: boolean
    ){}

}