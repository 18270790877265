import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/model/usuario.model';
import { UsuarioService } from './service/usuario.service';
import { AutorizacaoService } from '../autorizacao/service/autorizacao.service';
import { Autorizacao } from 'src/app/model/autorizacao.model';
import { ActivatedRoute, Router } from '@angular/router';
import { timeout } from 'q';
import { UsuarioBuilder } from 'src/app/builder/usuario.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'usuario-edit',
    templateUrl: './usuario.component.html'
})
export class UsuarioEditComponent implements OnInit {

    public autorizacoes: Array<Autorizacao>;
    public usuario: Usuario;
    private usuarioBuilder: UsuarioBuilder;
    public autorizacaoSelecionada: number = null;
    public total: number;
    public usuarioId: number;
    public usuarioAtual: Usuario;

    constructor(
        private usuarioService: UsuarioService,
        private autorizacaoService: AutorizacaoService,
        private swtAlert2Service: SwtAlert2Service,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.autorizacoes = new Array<Autorizacao>();
        this.usuarioBuilder = new UsuarioBuilder();
        this.usuario = this.usuarioBuilder.getInstance();
    }

    ngOnInit() {
        this.getUsuarioAtual();
        this.carregarAutorizacoes();
        this.usuarioId = Number(this.route.snapshot.paramMap.get('id'));
    }

    async getUsuarioAtual() : Promise<any> {

        try 
        {
            let response = await this.usuarioService.getCurrentUser();   
            this.usuarioAtual = response.entity;        
        }
        catch(e)
        {
            console.log(e);
        }

    }

    carregarAutorizacoes(): void {
        this.autorizacaoService.findAll().then((lista: any) => {
            this.autorizacoes = lista.entity.filter( (regra) => { 
                
                if(
                    (regra.nome == 'ROLE_ADMIN' || regra.nome == 'ROLE_GESTOR') 
                    && 
                    this.usuarioAtual.autorizacoesNome[0] == 'ROLE_GESTOR'
                ) 
                {
                    return false;
                }
                
                return true;
            
            });
            
            this.carregarUsuario(this.usuarioId);            
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarUsuario(id: number): void {
        this.usuarioService.find(id).then((response: any) => {
            this.usuario = response.entity;
            this.autorizacaoSelecionada = this.usuario.autorizacoes[0];            
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    salvar(usuario: Usuario): void {

        usuario.autorizacoes = new Array<number>();
        usuario.autorizacoes.push(this.autorizacaoSelecionada);

        this.usuarioService.update(usuario.id, usuario).then((response: any) => {
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/usuarios']);
            },2000);            
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

}