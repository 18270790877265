import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { DetalheConta } from 'src/app/model/detalheconta.model';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";


@Injectable()
export class DetalheContaService extends GenericService<DetalheConta> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('detalheconta');
    }

    buscarDetalhesConta(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/buscar', JSON.stringify(busca), { 'headers' : this.headers }).toPromise();
    }

    buscaPorDetalheContaPertencenteId(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/pordetalhecontapertencente/${id}`, { 'headers' : this.headers }).toPromise();
    }

    buscaPorVariavelDetalhesContaId(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/porvariaveldetalhesconta/${id}`, { 'headers' : this.headers }).toPromise();
    }

    vicularDetalheConta(req: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/vinculardetalheconta', JSON.stringify(req), { 'headers' : this.headers }).toPromise();
    } 

    vicularContaPlano(req: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/vincularcontaplano', JSON.stringify(req), { 'headers' : this.headers }).toPromise();
    }
    
    desvicularDetalheConta(req: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/desvinculardetalheconta', JSON.stringify(req), { 'headers' : this.headers }).toPromise();
    } 

    desvicularContaPlano(req: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/desvincularcontaplano', JSON.stringify(req), { 'headers' : this.headers }).toPromise();
    } 

    buscaPorContaPlanoId(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/porcontaplano/${id}`, { 'headers' : this.headers }).toPromise();
    }

}