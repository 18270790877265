import { NgModule } from '@angular/core';
import { RegraPOComponent } from './regrapo.component';
import { Routes, RouterModule } from '@angular/router';
import { RegrasPOComponent } from './regraspo.component';
import { RegraPOEditComponent } from './regrapo.edit.component';

const regrapoRoutes: Routes = [
    {
        path: 'secured/regrapo',
        component: RegraPOComponent
    },
    {
        path: 'secured/regraspo',
        component: RegrasPOComponent
    },
    {
        path: 'secured/regrapo/:id',
        component: RegraPOEditComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(regrapoRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class RegraPORoutingModule {
    
}