import { RegraPO } from '../model/regrapo.model';
import { AbstractBuilder } from './abstract.builder';


export class RegraPOBuilder extends AbstractBuilder<RegraPO> {

    reset() : void {
        this.entity = new RegraPO(null,null,new Array<number>(),new Array<number>(),new Array<number>(),new Array<number>(),new Array<number>(),null,null,null,null,null,null);
    }

}