import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SecuredComponent } from './secured.component';

const securedRoutes: Routes = [
    {
        path: 'secured',
        component: SecuredComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(securedRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class SecuredRoutingModule {
    
}