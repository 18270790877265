import { NgModule } from '@angular/core';
import { CentroCustoComponent } from './centrocusto.component';
import { Routes, RouterModule } from '@angular/router';
import { CentrosCustoComponent } from './centroscusto.component';
import { CentroCustoEditComponent } from './centrocusto.edit.component';

const contaPlanoRoutes: Routes = [
    {
        path: 'secured/centrocusto',
        component: CentroCustoComponent
    },
    {
        path: 'secured/centroscusto',
        component: CentrosCustoComponent
    },
    {
        path: 'secured/centrocusto/:id',
        component: CentroCustoEditComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(contaPlanoRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class CentroCustoRoutingModule {
    
}