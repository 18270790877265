import { Component, OnInit } from "@angular/core";
import { Pessoa } from 'src/app/model/pessoa.model';
import { PessoaService } from './service/pessoa.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { environment } from 'src/environments/environment';

 @Component({
     selector: 'pessoa',
     templateUrl: './pessoas.component.html', 
     styleUrls:['./style/style.css']
 })
 export class PessoasComponent implements OnInit{
     
    public pessoas: Array<Pessoa>;
    public busca: any;
    public totalDePaginas: number; 
    public urlBaseImagem: string;

    constructor(
        public pessoaService: PessoaService,
        public swtAlert2Service: SwtAlert2Service
    ){
        this.pessoas = new Array<Pessoa>();
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.busca = {
            nome: null,
            sobrenome: null,            
            pageNumber: 0,
            quantityOfElements : 10
        };

    }

    ngOnInit(): void {
        this.buscarPessoas();
    }

    buscarPessoas() : void {
        this.pessoaService.buscarPessoas(this.busca).then( (response: any) => {
            this.pessoas = response.entity;
            this.totalDePaginas = response.quantity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    deletarPessoa(id: number) : void {

        if(confirm('Deseja deletar este centro de evangelização?'))
        {
            this.pessoaService.delete(id)
            .then( (response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscarPessoas();
            })
            .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }

    
    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero-1;
        this.buscarPessoas();
    }
 }