export class DetalheConta {

    constructor(
        public id : number,
        public w4Id : number,
        public nome : string,
        public codigo : string,
        public tipoHierarquico : string,
        public tipoOperacao : string,
        public detalheContaPertencenteId : number,
        public detalheContaPertencenteNome: string,
        public contasPlanoPertencenteIds : Array<number>,
        public detalhesContaVinculadosIds : Array<number>
    ){
        this.contasPlanoPertencenteIds = new Array<number>();
        this.detalhesContaVinculadosIds = new Array<number>();
    }

}