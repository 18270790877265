import { NgModule } from '@angular/core';
import { UsuarioComponent } from './usuario.component';
import { Routes, RouterModule } from '@angular/router';
import { UsuariosComponent } from './usuarios.component';
import { UsuarioEditComponent } from './usuario.edit.component';

const usuarioRoutes: Routes = [
    {
        path: 'secured/usuario',
        component: UsuarioComponent
    },
    {
        path: 'secured/usuario/:id',
        component: UsuarioEditComponent
    },
    {
        path: 'secured/usuarios',
        component: UsuariosComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(usuarioRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class UsuarioRoutingModule {
    
}