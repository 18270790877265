import { NgModule } from '@angular/core';
import { DetalheContaComponent } from './detalheconta.component';
import { Routes, RouterModule } from '@angular/router';
import { DetalhesContaComponent } from './detalhesconta.component';
import { DetalheContaEditComponent } from './detalheconta.edit.component';

const detalheContaRoutes: Routes = [
    {
        path: 'secured/detalheconta',
        component: DetalheContaComponent
    },
    {
        path: 'secured/detalhesconta',
        component: DetalhesContaComponent
    },
    {
        path: 'secured/detalheconta/:id',
        component: DetalheContaEditComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(detalheContaRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class DetalheContaRoutingModule {
    
}