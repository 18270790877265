import { Component, OnInit } from '@angular/core';
import { DetalheContaService } from './service/detalheconta.service';
import { DetalheConta } from 'src/app/model/detalheconta.model';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { DetalheContaBuilder } from 'src/app/builder/detalheconta.builder';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'detalheconta',
    templateUrl: './detalheconta.component.html'
})
export class DetalheContaComponent {

    public detalheConta: DetalheConta;
    public detalheContaBuilder: DetalheContaBuilder;
    public urlGruposPorNomeURL : string;
    public urlBuscaPorDetalheIdENomeParaVinculacao : string;
    public urlBuscaPorNomeParaVinculacaoDeContaPlano : string;

    constructor(
        public detalheContaService: DetalheContaService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {        
        this.detalheContaBuilder = new DetalheContaBuilder();
        this.detalheConta = this.detalheContaBuilder.getInstance();
        this.urlGruposPorNomeURL = environment.detalheConta.gruposPorNomeURL;
        this.urlBuscaPorDetalheIdENomeParaVinculacao = environment.detalheConta.urlBuscaPorDetalheIdENomeParaVinculacao;
        this.urlBuscaPorNomeParaVinculacaoDeContaPlano = environment.contaPlano.buscaPorNome;
    }

    salvar(contaplano: DetalheConta) : void {

        this.detalheContaService.create(contaplano).then( (response:any) => {
            this.detalheConta = this.detalheContaBuilder.getInstance();
            this.limparSelect2();
            this.swtAlert2Service.successAlert(response.message);
        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    limparSelect2() : void {
        $("select[name='dc_pertencente']").empty();
    }

    setarElementoSelecionado(id): void {
        this.detalheConta.detalheContaPertencenteId = id;
    }

}