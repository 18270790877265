import { Component, OnInit } from '@angular/core';
import { Alerta } from 'src/app/model/alerta.model';
import { Regional } from 'src/app/model/regional.model';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { RegionalService } from '../regional/service/regional.service';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';
import { Difusao } from 'src/app/model/difusao.model';
import { DifusaoService } from './service/difusao.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'difusoes',
    templateUrl: './difusoes.component.html'
})
export class DifusoesComponent implements OnInit {

    public entities: Array<Difusao>;
    public busca: any;
    public totalDePaginas: number;
    public regionais: Array<Regional>;
    public fasesDifusao: Array<ElementoGrupoDominio>;
    public etapasDifusao: Array<ElementoGrupoDominio>;

    constructor(
        public difusaoService: DifusaoService,
        public regionalService: RegionalService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.entities = new Array<Difusao>();        
        this.busca = {
            regionalId: null,            
            nomeDifusao: null,
            elementoGrupoDominioFaseDifusaoId: null,
            elementoGrupoDominioEtapaDifusaoId: null,                       
            pageNumber : 0, 
            quantityOfElements : 10
        };
        this.regionais = new Array<Regional>();        
        this.fasesDifusao = new Array<ElementoGrupoDominio>(); 
        this.etapasDifusao = new Array<ElementoGrupoDominio>(); 
    }

    ngOnInit() {
        this.buscar();
        this.carregarRegionais();   
        this.carregarFasesDifusao();  
        this.carregarEtapasDifusao();        
    }   

    carregarRegionais() : void {
        this.regionalService.findAll().then( (lista: any) => {
            this.regionais = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarFasesDifusao() : void {

        let buscaFase = {
            grupoNome : 'FASE_DIFUSAO'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase).then( (lista: any) => {
            this.fasesDifusao = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarEtapasDifusao() : void {

        let buscaFase = {
            grupoNome : 'ETAPA_DIFUSAO'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase).then( (lista: any) => {
            this.etapasDifusao = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
        
    }

    buscar() : void {

        if(this.busca.dataFundacaoField)
        {
            this.busca.dataFundacao = new Date(this.busca.dataFundacaoField);
        }
        else
        {
            this.busca.dataFundacao = null;
        }

        this.difusaoService.buscar(this.busca).then( (response: any) => {
            this.entities = response.entity;
            this.totalDePaginas = response.quantity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero-1;
        this.buscar();
    }
    
    deletar(id: number) : void {

        if(confirm('Deseja deletar esta difusao?'))
        {
            this.difusaoService.delete(id)
            .then( (response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscar();
            })
            .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }
}