import { Component, OnInit } from '@angular/core';
import { CentroCusto } from 'src/app/model/centrocusto.model';
import { CentroCustoService } from './service/centrocusto.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'centroscusto',
    templateUrl: './centroscusto.component.html'
})
export class CentrosCustoComponent implements OnInit {

    public centrosCusto: Array<CentroCusto>;
    public busca: any;
    public totalDePaginas: number;
    public url : string;

    constructor(
        public centrosCustoService: CentroCustoService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.centrosCusto = new Array<CentroCusto>();  
        this.url = environment.centroCusto.gruposPorNome;              
        this.busca = {            
            nome : undefined,
            w4Id : undefined,
            tipoHierarquico : null,
            centroCustoPertencenteId : undefined,
            pageNumber : 0, 
            quantityOfElements : 10
        };
    }

    ngOnInit() {
        this.buscarCentrosCusto();
    }   

    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero-1;
        this.buscarCentrosCusto();
    }

    buscarCentrosCusto() : void {

        this.centrosCustoService.buscarCentrosCusto(this.busca).then( (response: any) => {
            this.centrosCusto = response.content;
            this.totalDePaginas = response.totalPages;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
        
    }
    
    deletar(id: number) : void {

        if(confirm('Deseja deletar este conta do plano?'))
        {
            this.centrosCustoService.delete(id)
            .then( (response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscarCentrosCusto();
            })
            .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }

    limparSelect2() : void {
        $("select[name='cp_pertencente']").empty();
        this.setarElementoSelecionado(null);
    }

    setarElementoSelecionado(id): void {
        this.busca.centroCustoPertencenteId = id;
    }
}