import { CentroCusto } from '../model/centrocusto.model';
import { AbstractBuilder } from './abstract.builder';


export class CentroCustoBuilder extends AbstractBuilder<CentroCusto> {

    reset() : void {
        this.entity = new CentroCusto(null,null,null,null,null,null,new Array<number>());
    }

}