import { Missao } from '../model/missao.model';
import { AbstractBuilder } from './abstract.builder';


export class MissaoBuilder extends AbstractBuilder<Missao> {

    reset() : void {
        this.entity = new Missao(null,null,null,null);
    }

}