import { Component, OnInit } from '@angular/core';
import { PlanejamentoOrcamentarioService } from './service/planejamentoorcamentario.service';
import { PlanejamentoOrcamentario } from 'src/app/model/planejamentoorcamentario.model';
import { PlanejamentoOrcamentarioBuilder } from 'src/app/builder/planejamentoorcamentario.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { environment } from 'src/environments/environment';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { ActivatedRoute, Router } from '@angular/router';
import { EmpresaService } from '../empresa/service/empresa.service';

@Component({
    selector: 'planejamentoorcamentario',
    templateUrl: './planejamentoorcamentario.component.html'
})
export class PlanejamentoOrcamentarioComponent implements OnInit {

    public planejamentoOrcamentarioBuilder: PlanejamentoOrcamentarioBuilder;
    public planejamentoOrcamentario: PlanejamentoOrcamentario;
    public urlBuscaEmpresaPorNome: string;
    public listaStatus : Array<ElementoGrupoDominio>;
    public listaInstancias : Array<ElementoGrupoDominio>;

    constructor(
        public planejamentoOrcamentarioService: PlanejamentoOrcamentarioService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public empresaService: EmpresaService,
        public swtAlert2Service: SwtAlert2Service,
        public route: ActivatedRoute,
        public router: Router
    ) 
    {
        this.planejamentoOrcamentarioBuilder = new PlanejamentoOrcamentarioBuilder();
        this.planejamentoOrcamentario = this.planejamentoOrcamentarioBuilder.getInstance();
        this.urlBuscaEmpresaPorNome = environment.empresa.empresaPorNomeUrl;
        this.listaStatus = new Array<ElementoGrupoDominio>();
        this.listaInstancias = new Array<ElementoGrupoDominio>();
    }

    ngOnInit() {   
        this.carregarStatus();
        this.carregarInstancias();     
    }

    salvar(planejamentoOrcamentario: PlanejamentoOrcamentario) : void {

        this.planejamentoOrcamentarioService.create(planejamentoOrcamentario).then( (response:any) => {
            this.planejamentoOrcamentario = this.planejamentoOrcamentarioBuilder.getInstance();
            this.limparSelect2('empresas');
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/planejamentoorcamentario/'+response.entity.id]);
            }, 2000);
        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    async carregarStatus() : Promise<any> {

        let buscaFase = {
            grupoNome : 'STATUS_PO'
        }

        try
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase);
            this.listaStatus = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarInstancias() : Promise<any> {

        let buscaFase = {
            grupoNome : 'INSTANCIA_PO'
        }

        try
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase);
            this.listaInstancias = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async setarElementoSelecionado(empresaId) : Promise<any> {
        this.planejamentoOrcamentario.empresaId = empresaId;

        try
        {
            let response = await this.empresaService.find(empresaId);
            this.planejamentoOrcamentario.nome = response.entity.nome;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }
    }

    limparSelect2(nomeEl) : void {
        $("select[name='"+nomeEl+"']").empty();
    }

}