import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CategoriaPlanejamentoOrcamentario } from 'src/app/model/categoriaplanejamentoorcamentario.model';
import { ContaPlanoService } from '../../contaplano/service/contaplano.service';
import { DetalheContaService } from '../../detalheconta/service/detalheconta.service';
import { PrevisaoOrcamentariaService } from '../../previsaoorcamentaria/service/previsaoorcamentaria.service';
import { PrevisaoOrcamentaria } from 'src/app/model/previsaoorcamentaria.model';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { PlanilhaUtilsService } from '../service/planilhautils.service';
import { PrevisaoOrcamentariaBuilder } from 'src/app/builder/previsaoorcamentaria.builder';
import { PlanejamentoOrcamentario } from 'src/app/model/planejamentoorcamentario.model';
import { PlanejamentoOrcamentarioService } from '../service/planejamentoorcamentario.service';
import { RelatorioComparativoW4 } from 'src/app/model/relatoriocomparativow4.model';
import { environment } from 'src/environments/environment';
import { RelatorioComparativoW4Builder } from 'src/app/builder/relatoriocomparativow4.builder';
import { RelatorioComparativoCategoriasW4 } from 'src/app/model/relatoriocomparativocategoriasw4.model';

@Component({
    selector: '[relatorio-comparativo]',
    templateUrl: './relatoriocomparativo.component.html',
    styleUrls: ['../style/table-sheet.css','../style/scrollbar.css','../style/table-relatorio.css']
})
export class RelatorioComparativoComponent implements OnChanges {

    @Input() 
    public po: PlanejamentoOrcamentario;
    public relatorioComparativo: RelatorioComparativoW4;
    public relatorioComparativoBuilder: RelatorioComparativoW4Builder;
    public anoInicial: number;
    public anos: Array<number>;
    public meses: Array<any>;
    public urlBuscaCentroCustoPorNome: string;
    public request: any;
    public poId: number;

    constructor(
        public planejamentoOrcamentarioService: PlanejamentoOrcamentarioService,
        public swtAlert2Service: SwtAlert2Service,
        public planilhaUtilService: PlanilhaUtilsService
    ) { 
        this.urlBuscaCentroCustoPorNome = environment.centroCusto.buscaPorNome;
        this.anos = new Array<number>();
        this.meses = this.gerarMeses();
        this.relatorioComparativoBuilder = new RelatorioComparativoW4Builder();
        this.relatorioComparativo = this.relatorioComparativoBuilder.getInstance();
        this.request = { 
            planejamentoOrcamentarioId : null,
            previstoAnoDe : null,
            previstoMesDe : null,
            previstoAnoAte : null,
            previstoMesAte : null,
            realizadoAnoDe : null,
            realizadoMesDe : null,
            realizadoAnoAte : null,
            realizadoMesAte : null,
            centroDeCustoId : null,
            fonteW4 : false
        };

    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) 
        {
            if(propName == 'po' && this.po.id != null)
            {
                let change = changes[propName];
                let diferenca:number;
                let dataAnoinicial = new Date(this.po.periodoInicio);

                this.anoInicial = new Date(dataAnoinicial.setTime( dataAnoinicial.getTime() + dataAnoinicial.getTimezoneOffset()*60*1000 )).getFullYear();

                let dataAnoFinal = new Date(this.po.periodoFim);

                let anoFinal = new Date(dataAnoFinal.setTime( dataAnoFinal.getTime() + dataAnoinicial.getTimezoneOffset()*60*1000 )).getFullYear();

                diferenca = anoFinal - this.anoInicial;

                for(let i = 0; i <= diferenca; i++)
                {
                    this.anos.push(this.anoInicial+i);
                }

                this.poId = this.po.id;

                // this.request.realizadoAnoDe = new Date(dataAnoinicial.setTime( dataAnoinicial.getTime() + dataAnoinicial.getTimezoneOffset()*60*1000 )).getFullYear()-1;
                // this.request.realizadoAnoAte = new Date(dataAnoinicial.setTime( dataAnoinicial.getTime() + dataAnoinicial.getTimezoneOffset()*60*1000 )).getFullYear()-1;
            }
        }
    }

    async carregarRelatorioComparativo() : Promise<any> {

        try
        {
            this.request.planejamentoOrcamentarioId = this.po.id;

            let response = await this.planejamentoOrcamentarioService.relatorioComparativo(this.request);
            this.relatorioComparativo = response;
            this.relatorioComparativo.relatoriosPorCategoria = this.relatorioComparativo.relatoriosPorCategoria.sort((a,b) => a.categoriaId - b.categoriaId);

        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    limparFormularioPesquisa() {
        this.limparSelect2('centroCustoRelatorio');
        this.request = { 
            planejamentoOrcamentarioId : this.po.id,
	        previstoAnoDe : null,
	        previstoMesDe : null,
	        previstoAnoAte : null,
	        previstoMesAte : null,
	        realizadoAnoDe : null,
	        realizadoMesDe : null,
	        realizadoAnoAte : null,
	        realizadoMesAte : null,
	        centroDeCustoId : null,
            fonteW4 : false
        };
    }

    gerarMeses() : Array<any> {
        return [
            { 
                id : '1',
                nome : 'Janeiro'
            },
            { 
                id : '2',
                nome : 'Fevereiro'
            },
            { 
                id : '3',
                nome : 'Março'
            },
            { 
                id : '4',
                nome : 'Abril'
            },
            { 
                id : '5',
                nome : 'Maio'
            },
            { 
                id : '6',
                nome : 'Junho'
            },
            { 
                id : '7',
                nome : 'Julho'
            },
            { 
                id : '8',
                nome : 'Agosto'
            },
            { 
                id : '9',
                nome : 'Setembro'
            },
            { 
                id : '10',
                nome : 'Outubro'
            },
            { 
                id : '11',
                nome : 'Novembro'
            },
            { 
                id : '12',
                nome : 'Dezembro'
            }
        ];
    }

    limparSelect2(nomeEl) : void {
        $("select[name='"+nomeEl+"']").empty();
    }

    public colorHexToRGB(hex:any,relatorio:RelatorioComparativoCategoriasW4) : any {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        let retorno = null;
        let fatorCorrecao = relatorio.nivelArvore*0.15;
        
        if(result && relatorio.tipoHierarquico == 'G')
        {
            retorno = {
                r: parseInt(result[1], 16) + (255 - parseInt(result[1], 16))*fatorCorrecao,
                g: parseInt(result[2], 16) + (255 - parseInt(result[2], 16))*fatorCorrecao,
                b: parseInt(result[3], 16) + (255 - parseInt(result[3], 16))*fatorCorrecao
            };
        } 
        else
        {
            retorno = {
                r: 255,
                g: 255,
                b: 255
            };
        }

        return result ? 'rgb('+retorno.r+','+retorno.g+','+retorno.b+')' : null;
      }

      setarAnoRealizado() {
        if(!this.request.fonteW4)
        {
            let dataAnoinicial = new Date(this.po.periodoInicio);
            this.request.realizadoAnoDe = new Date(dataAnoinicial.setTime( dataAnoinicial.getTime() + dataAnoinicial.getTimezoneOffset()*60*1000 )).getFullYear()-1;
            this.request.realizadoAnoAte = new Date(dataAnoinicial.setTime( dataAnoinicial.getTime() + dataAnoinicial.getTimezoneOffset()*60*1000 )).getFullYear()-1;
        }
        else
        {
            this.request.realizadoAnoDe = null;
            this.request.realizadoAnoAte = null;
        }
      }

}