import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from './service/loader.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.css']
})
export class LoaderComponent {

    color = 'primary';
    mode = 'indeterminate';
    value = 50;
    isLoading: Subject<boolean> = this.loaderService.isLoading;

    constructor(public loaderService: LoaderService) { }
    
}