export class Pessoa {

    constructor(
        public id: number,
        public nome: string,
        public sobrenome: string,
        public imagemId: number,
        public usuarioId: number,
        public usuarioUsername: string
    ){}

}