import { Component, OnInit, ViewChild } from '@angular/core';
import { PlanejamentoOrcamentarioService } from '../service/planejamentoorcamentario.service';
import { PlanejamentoOrcamentario } from 'src/app/model/planejamentoorcamentario.model';
import { PlanejamentoOrcamentarioBuilder } from 'src/app/builder/planejamentoorcamentario.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { environment } from 'src/environments/environment';
import { ElementoGrupoDominioService } from '../../grupodominio/service/elementogrupodominio.service';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoriaPlanejamentoOrcamentarioService } from '../service/categoriaplanejamentoorcamentario.service';
import { CategoriaPlanejamentoOrcamentario } from 'src/app/model/categoriaplanejamentoorcamentario.model';
import { PrevisaoOrcamentaria } from 'src/app/model/previsaoorcamentaria.model';
import { PrevisaoOrcamentariaBuilder } from 'src/app/builder/previsaoorcamentaria.builder';
import { ContaPlanoService } from '../../contaplano/service/contaplano.service';
import { CategoriaPlanejamentoOrcamentarioBuilder } from 'src/app/builder/categoriaplanejamentoorcamentario.builder';
import { PrevisaoOrcamentariaService } from '../../previsaoorcamentaria/service/previsaoorcamentaria.service';
import { DetalheContaService } from '../../detalheconta/service/detalheconta.service';
import { ContaPlano } from 'src/app/model/contaplano.model';
import { DetalheConta } from 'src/app/model/detalheconta.model';
import { PlanilhaUtilsService } from '../service/planilhautils.service';
import { PlanilhaPlanejamentoOrcamentarioComponent } from '../tabelaplanejamentoorcamentario/planilhaplanejamentoorcamentario.component';
import { Usuario } from 'src/app/model/usuario.model';
import { UsuarioService } from '../../usuario/service/usuario.service';
import { CentroCustoService } from 'src/app/secured/centrocusto/service/centrocusto.service';
import { HistoricoPlanejamentoOrcamentarioService } from '../service/historicoplanejamentoorcamentario.service';

@Component({
    selector: 'planejamentoorcamentarioplanilha',
    templateUrl: './planejamentoorcamentarioplanilha.component.html',
    styleUrls: ['../style/table-sheet.css','../style/scrollbar.css']
})
export class PlanejamentoOrcamentarioPlanilhaParaGestorComponent implements OnInit {

    @ViewChild(PlanilhaPlanejamentoOrcamentarioComponent,{static: false})
    public planilhaPo: PlanilhaPlanejamentoOrcamentarioComponent;
    public planejamentoOrcamentarioBuilder: PlanejamentoOrcamentarioBuilder;
    public planejamentoOrcamentario: PlanejamentoOrcamentario;
    public planejamentoOrcamentarioId: number;
    public previsaoOrcamentariaBuilder : PrevisaoOrcamentariaBuilder;
    public previsaoOrcamentaria: PrevisaoOrcamentaria;
    public previsaoOrcamentariaSelecionada: PrevisaoOrcamentaria;
    public previsaoOrcamentariaReordenacao: PrevisaoOrcamentaria;
    public categoriaPlanejamentoOrcamentarioBuilder: CategoriaPlanejamentoOrcamentarioBuilder;
    public categoriaPlanejamentoOrcamentarioSelecionada: CategoriaPlanejamentoOrcamentario;
    public urlBuscaContaPlanoPorNome: string;
    public urlBuscaDetalheContaPorNome: string;
    public urlBuscaCentroCustoPorNome: string;
    public listaStatus : Array<ElementoGrupoDominio>;
    public listaInstancias : Array<ElementoGrupoDominio>;
    public listaCategorias : Array<CategoriaPlanejamentoOrcamentario>;
    public listaCategoriasNaoAvulsas : Array<CategoriaPlanejamentoOrcamentario>;
    public listaContaPlano : Array<ContaPlano>;
    public listaDetalheConta : Array<DetalheConta>;
    public exibirCentroCustoForm : boolean;
    public usuarioAtual: Usuario;
    public historicoPlanejamentoOrcamentario: any;

    constructor(
        public planejamentoOrcamentarioService: PlanejamentoOrcamentarioService,
        public categoriaPlanejamentoOrcamentarioService: CategoriaPlanejamentoOrcamentarioService,
        public previsaoOrcamentariaService: PrevisaoOrcamentariaService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public contaPlanoService: ContaPlanoService,
        public detalheContaService: DetalheContaService,
        public swtAlert2Service: SwtAlert2Service,
        public route: ActivatedRoute,
        private router: Router,
        public planilhaUtilsService: PlanilhaUtilsService,
        public usuarioService: UsuarioService,
        public centroCustoService: CentroCustoService,
        public historicoPlanejamentoOrcamentarioService: HistoricoPlanejamentoOrcamentarioService
    ) 
    {
        this.planejamentoOrcamentarioBuilder = new PlanejamentoOrcamentarioBuilder();
        this.planejamentoOrcamentario = this.planejamentoOrcamentarioBuilder.getInstance();
        this.previsaoOrcamentariaBuilder = new PrevisaoOrcamentariaBuilder();
        this.previsaoOrcamentaria = this.previsaoOrcamentariaBuilder.getInstance();
        this.previsaoOrcamentariaSelecionada = this.previsaoOrcamentariaBuilder.getInstance();
        this.previsaoOrcamentariaReordenacao = this.previsaoOrcamentariaBuilder.getInstance();
        this.categoriaPlanejamentoOrcamentarioBuilder = new CategoriaPlanejamentoOrcamentarioBuilder();
        this.categoriaPlanejamentoOrcamentarioSelecionada = this.categoriaPlanejamentoOrcamentarioBuilder.getInstance();
        this.urlBuscaContaPlanoPorNome = environment.contaPlano.buscaPorNome;
        this.urlBuscaDetalheContaPorNome = environment.detalheConta.buscaPorNome;
        this.urlBuscaCentroCustoPorNome = environment.centroCusto.buscaPorNome;
        this.listaStatus = new Array<ElementoGrupoDominio>();
        this.listaInstancias = new Array<ElementoGrupoDominio>();
        this.listaCategorias = new Array<CategoriaPlanejamentoOrcamentario>();
        this.listaCategoriasNaoAvulsas = new Array<CategoriaPlanejamentoOrcamentario>();
        this.listaContaPlano = new Array<ContaPlano>();
        this.listaDetalheConta = new Array<DetalheConta>();
    }

    ngOnInit() {   
        this.getUsuarioAtual();
        this.route.paramMap.subscribe(params => {
            this.planejamentoOrcamentarioId = Number(params.get("id"));
            this.carregarPlanejamentoOrcamentario(this.planejamentoOrcamentarioId);            
            this.carregarCategorias();
        });
        this.carregarStatus();
        this.carregarInstancias();  
        this.exibirCentroCustoForm = false;
    }

    async getUsuarioAtual() : Promise<any> {

        try 
        {
            let response = await this.usuarioService.getCurrentUser();   
            this.usuarioAtual = response.entity;        
        }
        catch(e)
        {
            console.log(e);
        }

    }

    async exibirCampoCentroDeCustoNoForm(id: number) : Promise<any> {
        
        this.exibirCentroCustoForm = false;

        try
        {
            let response = await this.detalheContaService.find(id);
            let detalhe = response.entity;

            if(detalhe.tipoHierarquico == 'I')
            {
                this.exibirCentroCustoForm = true;
                this.carregarCentroCustoPadrao();
            }

        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async importarLinhasDoW4(id: number) : Promise<any> {

        if(confirm("Deseja importar as linhas desta categoria do W4?"))
        {
            try
            {
                let response = await this.previsaoOrcamentariaService.obterPrevisoesOrcamentariasVinculadasW4(id);
                this.swtAlert2Service.successAlert(response.message); 
                this.carregarPrevisoesIniciais();   
            }
            catch(e)
            {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        }

    }

    carregarPlanejamentoOrcamentario(id: number): void {
        this.planejamentoOrcamentarioService.find(id).then((response: any) => {
            this.planejamentoOrcamentario = response.entity;
            $("select[name='empresas']").append(new Option(this.planejamentoOrcamentario.empresaNome, this.planejamentoOrcamentario.empresaId.toString(), false, true));
            if(this.planejamentoOrcamentario.converterValoresUsandoTaxa)
            {
                let self = this;
                setTimeout(function()
                {                    
                    self.planilhaPo.carregarPlanilhaPlanejamentoOrcamentario(self.planejamentoOrcamentarioId);
                },1000);
            }
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            this.router.navigate(['/secured/g/planejamentosorcamentarios']);
        });
    }

    async carregarStatus() : Promise<any> {

        let buscaFase = {
            grupoNome : 'STATUS_PO'
        }

        try
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase);
            this.listaStatus = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarInstancias() : Promise<any> {

        let buscaFase = {
            grupoNome : 'INSTANCIA_PO'
        }

        try
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase);
            this.listaInstancias = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarCategorias() : Promise<any> {

        try
        {
            let response = await this.categoriaPlanejamentoOrcamentarioService.buscaPorPlanejamentoOrcamentarioId(this.planejamentoOrcamentarioId);
            this.listaCategorias = response.entity;    

            this.listaCategorias.forEach(c => c.requestExportacao = {
                                                                        idProcessoW4:205,
                                                                        ano:null,
                                                                        centroCustoId: null,
                                                                        filename: c.nome.toLocaleLowerCase()
                                                                    });
            
            this.carregarPrevisoesIniciais();
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarPrevisoesIniciais() : Promise<any> {
        for(let categoria of this.listaCategorias){

            try
            {

            let response = await this.previsaoOrcamentariaService.buscarPrevisaoOrcamentariaInicialPorCategoria(categoria.id);
            categoria.previsaoOrcamentariaInicial = response.entity;

            let response2 = await this.getCentrosCustoExportacaoW4(categoria.previsaoOrcamentariaInicial.id);
            categoria.requestExportacao.listaCentroCusto = response2;

            }
            catch(e)
            {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }

        }

        this.listaCategoriasNaoAvulsas = this.listaCategorias.filter((value,index) => index <=2);
    }

    async carregarContasPlanoPorCPPertencenteId(id:number) : Promise<any> {

        try
        {
            let response = await this.contaPlanoService.buscaPorContaPlanoPertencenteId(id);
            this.listaContaPlano = response.entity.sort( (a,b) => { 
                if(a.nome > b.nome)
                    return 1;
                if(a.nome < b.nome)
                    return -1;
                return 0;
            });
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarDetalheContaPorCPPertencenteId(id:number) : Promise<any> {

        try
        {
            let response = await this.detalheContaService.buscaPorContaPlanoId(id);
            this.listaDetalheConta = response.entity.sort( (a,b) => { 
                if(a.nome > b.nome)
                    return 1;
                if(a.nome < b.nome)
                    return -1;
                return 0;
            });
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarDetalheContaPorDCPertencenteId(id:number) : Promise<any> {

        try
        {
            let response = await this.detalheContaService.buscaPorDetalheContaPertencenteId(id);
            this.listaDetalheConta = response.entity.sort( (a,b) => { 
                if(a.nome > b.nome)
                    return 1;
                if(a.nome < b.nome)
                    return -1;
                return 0;
            });
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarCentroCustoPadrao() : Promise<any> {

        try
        {
            let response = await this.centroCustoService.buscarItensPorNome("Adm Financeiro");
            if(response.entity.length > 0)
            {
                $("select[name='centroCusto']").append(new Option(response.entity[0].text, response.entity[0].id.toString(), false, true));
            }
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async salvarPrevisaoOrcamentariaInicial() : Promise<any> {

        this.previsaoOrcamentaria.planejamentoOrcamentarioId = this.planejamentoOrcamentarioId;
        this.previsaoOrcamentaria.categoriaPlanejamentoOrcamentarioId = this.categoriaPlanejamentoOrcamentarioSelecionada.id;
        this.previsaoOrcamentaria.nivelArvore = 0;
        this.previsaoOrcamentaria.ordemExibicao = 0;

        try
        {
            let responseCP = await this.contaPlanoService.find(this.previsaoOrcamentaria.contaPlanoId);
            this.previsaoOrcamentaria.nome = responseCP.entity.nome;
            let response = await this.previsaoOrcamentariaService.create(this.previsaoOrcamentaria);
            this.categoriaPlanejamentoOrcamentarioSelecionada.previsaoOrcamentariaInicial = response.entity;
            this.previsaoOrcamentaria = this.previsaoOrcamentariaBuilder.getInstance();
            this.limparSelect2Modal();
            $("#previsao-orcamentaria-inicial").modal('hide');
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async atualizarPrevisaoOrcamentariaReordenacao() : Promise<any> {

        try
        {
            let mensagemFinal = '';

            for(let previsao of this.previsaoOrcamentariaReordenacao.previsoesOrcamentariasVinculadas)
            {
                let response = await this.previsaoOrcamentariaService.update(previsao.id,previsao);
                if(!mensagemFinal)
                {
                    mensagemFinal = response.message;
                }
            }                  
            this.previsaoOrcamentariaReordenacao = this.previsaoOrcamentariaBuilder.getInstance();
            this.carregarPrevisoesIniciais();            
            $("#reordenar-vinculacoes").modal('hide');            
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    ordernarVinculacoesPorCodigoConta() : void {

        for(let previsao of this.previsaoOrcamentariaReordenacao.previsoesOrcamentariasVinculadas)
        {
            if(!previsao.detalheContaId)
            {
                previsao.ordemExibicao = Number(previsao.contaPlanoCodigo.split(".")[previsao.contaPlanoCodigo.split(".").length-1]);
            }
            else
            {
                previsao.ordemExibicao = previsao.detalheContaW4Id;
            }
        }
        
    }

    async atualizarPrevisaoOrcamentaria(previsao:PrevisaoOrcamentaria) : Promise<any> {

        try
        {
            if(previsao.detalheContaId == null)
            {
                let responseCP = await this.contaPlanoService.find(previsao.contaPlanoId);
                previsao.nome = responseCP.entity.nome;
            }
            else
            {
                let responseCP = await this.detalheContaService.find(previsao.detalheContaId);
                previsao.nome = responseCP.entity.nome;
            }
            let response = await this.previsaoOrcamentariaService.update(previsao.id,previsao);
            this.swtAlert2Service.successAlert(response.message);                       
            this.carregarPrevisoesIniciais();                        
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async salvarPrevisaoOrcamentariaSubItem() : Promise<any> {

        this.previsaoOrcamentaria.planejamentoOrcamentarioId = this.planejamentoOrcamentarioId;
        this.previsaoOrcamentaria.categoriaPlanejamentoOrcamentarioId = this.categoriaPlanejamentoOrcamentarioSelecionada.id;
        this.previsaoOrcamentaria.previsaoOrcamentariaPertencenteId = this.previsaoOrcamentariaSelecionada.id;
        this.previsaoOrcamentaria.nivelArvore = this.previsaoOrcamentariaSelecionada.nivelArvore + 1;
        this.previsaoOrcamentaria.ordemExibicao = this.previsaoOrcamentariaSelecionada.previsoesOrcamentariasVinculadas.length;

        try
        {

            if(this.previsaoOrcamentaria.contaPlanoId == null)
            {
                this.previsaoOrcamentaria.contaPlanoId = this.previsaoOrcamentariaSelecionada.contaPlanoId;
            }

            if(this.previsaoOrcamentaria.detalheContaId == null)
            {
                let responseCP = await this.contaPlanoService.find(this.previsaoOrcamentaria.contaPlanoId);
                this.previsaoOrcamentaria.nome = responseCP.entity.nome;
            }
            else
            {
                let responseCP = await this.detalheContaService.find(this.previsaoOrcamentaria.detalheContaId);
                this.previsaoOrcamentaria.nome = responseCP.entity.nome;
            }            
            let response = await this.previsaoOrcamentariaService.create(this.previsaoOrcamentaria);            
            this.previsaoOrcamentaria = this.previsaoOrcamentariaBuilder.getInstance();
            this.carregarPrevisoesIniciais();
            this.limparSelect2Modal();
            this.exibirCentroCustoForm = false;
            $("#previsao-orcamentaria-subitens").modal('hide');
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async copiarPrevisaoOrcamentaria(previsao : PrevisaoOrcamentaria) : Promise<any> {

        let copy = Object.assign({}, previsao);

        copy.id = 0;
        copy.meses = [];
        copy.valorTotal = 0;
        copy.mediaTotal = 0;
        copy.analiseVertical = 0;

        try
        {
            let response = await this.previsaoOrcamentariaService.create(copy);            
            this.carregarPrevisoesIniciais();
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async deletarPrevisaoOrcamentaria(id:number, categoria: CategoriaPlanejamentoOrcamentario) : Promise<any> {

        try
        {
            let response = await this.previsaoOrcamentariaService.delete(id);            
            await this.carregarPrevisoesIniciais();
            if(categoria.previsaoOrcamentariaInicial != null)
            {
                let response2 = await this.previsaoOrcamentariaService.update(categoria.previsaoOrcamentariaInicial.id,categoria.previsaoOrcamentariaInicial);
            }            
            this.swtAlert2Service.successAlert(response.message)
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async setarContaPlano(contaPlanoId) : Promise<any> {

        this.previsaoOrcamentaria.contaPlanoId = contaPlanoId;

        try
        {
            let response = await this.contaPlanoService.find(contaPlanoId);
            this.previsaoOrcamentaria.nome = response.entity.nome;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }
        
    }

    async setarDetalheConta(detalheContaId) : Promise<any> {

        this.previsaoOrcamentaria.detalheContaId = detalheContaId;

        try
        {
            let response = await this.detalheContaService.find(detalheContaId);
            this.previsaoOrcamentaria.nome = response.entity.nome;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async finalizarPO() : Promise<any> {

        if(confirm("Deseja finalizar este Planejamento Orçamentário?"))
        {
            try
            {
                let response = await this.planejamentoOrcamentarioService.finalizarPO(this.planejamentoOrcamentarioId);                
                this.swtAlert2Service.successAlert(response.message);
                let response2 = await this.historicoPlanejamentoOrcamentarioService.criarHistorico(this.planejamentoOrcamentarioId);
                this.swtAlert2Service.successAlert(response2.message);
                this.carregarPlanejamentoOrcamentario(this.planejamentoOrcamentarioId);
            }
            catch(e)
            {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        }
    }

    async reabrirPO() : Promise<any> 
    {
        if(confirm("Deseja reabrir este Planejamento Orçamentário?"))
        {
            try
            {
                let response = await this.planejamentoOrcamentarioService.reabrirPO(this.planejamentoOrcamentarioId);                
                this.swtAlert2Service.successAlert(response.message);
                let response2 = await this.historicoPlanejamentoOrcamentarioService.criarHistorico(this.planejamentoOrcamentarioId);
                this.swtAlert2Service.successAlert(response2.message);
                this.carregarPlanejamentoOrcamentario(this.planejamentoOrcamentarioId);
            }
            catch(e)
            {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        }
    }

    setarCentroCusto(centroCustoId) : void {
        this.previsaoOrcamentaria.centroCustoId = centroCustoId;
    }

    setarCategoriaSelecionada(categoria: CategoriaPlanejamentoOrcamentario) : void {
        this.categoriaPlanejamentoOrcamentarioSelecionada = categoria;
    }

    setarCategoriaEPrevisaoSelecionadas(categoria: CategoriaPlanejamentoOrcamentario, previsao: PrevisaoOrcamentaria) : void {
        this.categoriaPlanejamentoOrcamentarioSelecionada = categoria;
        this.previsaoOrcamentariaSelecionada = previsao;
        this.carregarContasPlanoPorCPPertencenteId(previsao.contaPlanoId);
        if(previsao.contaPlanoTipoHierarquico == 'ITEM' && previsao.detalheContaId == null)
        {
            this.carregarDetalheContaPorCPPertencenteId(previsao.contaPlanoId);
        }
        else if(previsao.detalheContaId != null && previsao.detalheContaTipoHierarquico == 'GRUPO') 
        {
            this.carregarDetalheContaPorDCPertencenteId(previsao.detalheContaId);
        }
    }

    limparSelect2Modal() : void {
        this.previsaoOrcamentaria.nome = '';
        $("select[name='contaPlano']").empty();
        $("select[name='detalheConta']").empty();
        $("select[name='centroCusto']").empty();
    }

    limparSelect2(nomeEl) : void {
        $("select[name='"+nomeEl+"']").empty();
    }

    executarRotinaEdicaoPrevisaoOrcamentaria(previsao:PrevisaoOrcamentaria) : void {
        if(!previsao.exibirCamposEdicao && this.permitirEdicaoCamposPrevisao(previsao))
        {
            previsao.exibirCamposEdicao = true;
            this.carregarDetalheContaPorDCPertencenteId(previsao.detalheContaPertencenteId);
        }
    }

    permitirEdicaoCamposPrevisao(previsao:PrevisaoOrcamentaria) : boolean {

        let permitir:boolean = false;

        if(previsao.detalheContaId != null && previsao.detalheContaTipoHierarquico == 'ITEM')
        {
            permitir = true;
            permitir = this.planilhaUtilsService.filtragemEdicaoLinhas(this.usuarioAtual.autorizacoesNome[0],previsao.nome, previsao.contaPlanoCodigo);
        }

        return permitir;

    }

    existePrevisaoOrcamentariaInicial(categorias:Array<CategoriaPlanejamentoOrcamentario>)
    {
        for(let categoria of categorias)
        {
            if(categoria.previsaoOrcamentariaInicial)
            {
                return true;
            }
        }

        return false;
    }

    public colorHexToRGB(hex:any,previsao:PrevisaoOrcamentaria) : any {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        let retorno = null;
        let fatorCorrecao = previsao.nivelArvore*0.15;
        
        if(result && previsao.detalheContaTipoHierarquico != 'ITEM')
        {
            retorno = {
                r: parseInt(result[1], 16) + (255 - parseInt(result[1], 16))*fatorCorrecao,
                g: parseInt(result[2], 16) + (255 - parseInt(result[2], 16))*fatorCorrecao,
                b: parseInt(result[3], 16) + (255 - parseInt(result[3], 16))*fatorCorrecao
            };
        } 
        else
        {
            retorno = {
                r: 255,
                g: 255,
                b: 255
            };
        }

        return result ? 'rgb('+retorno.r+','+retorno.g+','+retorno.b+')' : null;
    }

    public compareDate(data1:any, data2:any) : any {
        return new Date(data1.data).getTime() - new Date(data2.data).getTime();
    }

    public async carregarHistorico(id:number) : Promise<any> {

        $("#historicos").modal('hide');

        try
        {
            let response = await this.historicoPlanejamentoOrcamentarioService.find(id);            
            this.historicoPlanejamentoOrcamentario = response.entity;
            $("#historico").modal('show');
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }
        
    }

    public async downloadExcel(id:number) : Promise<any> {

        try
        {
            let response = await this.planejamentoOrcamentarioService.downloadExcel(id);            
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    public async downloadExportacaoW4(previsao : PrevisaoOrcamentaria, request : any) : Promise<any> {

        request.idPrevisao = previsao.id;

        try
        {
            let response = await this.previsaoOrcamentariaService.downloadExportacaoW4(request);
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    public anoExportacaoW4() : Array<number> {

        let anos = new Array<number>();
        
        let diferenca:number;

        let dataAnoinicial = new Date(this.planejamentoOrcamentario.periodoInicio);

        let anoInicial = new Date(dataAnoinicial.setTime( dataAnoinicial.getTime() + dataAnoinicial.getTimezoneOffset()*60*1000 )).getFullYear();

        let dataAnoFinal = new Date(this.planejamentoOrcamentario.periodoFim);

        let anoFinal = new Date(dataAnoFinal.setTime( dataAnoFinal.getTime() + dataAnoinicial.getTimezoneOffset()*60*1000 )).getFullYear();

        diferenca = anoFinal - anoInicial;

        for(let i = 0; i <= diferenca; i++)
        {
            anos.push(anoInicial+i);
        }

        return anos;
    }

    public async getCentrosCustoExportacaoW4(id:number) : Promise<any> {

        try
        {
            let response = await this.centroCustoService.buscaEmPrevisao(id);   
            return response.entity;         
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

}