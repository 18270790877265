import { ContaPlano } from '../model/contaplano.model';
import { AbstractBuilder } from './abstract.builder';


export class ContaPlanoBuilder extends AbstractBuilder<ContaPlano> {

    reset() : void {
        this.entity = new ContaPlano(null,null,null,null,null,null,null,null,new Array<number>(),new Array<number>());
    }

}