import { Component, OnInit } from '@angular/core';
import { Autorizacao } from 'src/app/model/autorizacao.model';
import { AutorizacaoService } from './service/autorizacao.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'autorizacoes',
    templateUrl: './autorizacoes.component.html'
})
export class AutorizacoesComponent implements OnInit {

    public autorizacoes: Array<Autorizacao>;
    public busca: any;
    public totalDePaginas: number;

    constructor(
        public autorizacaoService: AutorizacaoService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.autorizacoes = new Array<Autorizacao>();                
        this.busca = {
            nomeExibicao: undefined,
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 10
        };
    }

    ngOnInit() {
        this.buscarAutorizacoes();
    }   

    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero-1;
        this.buscarAutorizacoes();
    }

    buscarAutorizacoes() : void {
        this.autorizacaoService.buscarAutorizacoes(this.busca).then( (response: any) => {
            this.autorizacoes = response.content;
            this.totalDePaginas = response.totalPages;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    
    deletar(id: number) : void {

        if(confirm('Deseja deletar esta permissão?'))
        {
            this.autorizacaoService.delete(id)
            .then( (response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscarAutorizacoes();
            })
            .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }
}