// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authorization : 'Basic bmV0bzpzZWNyZXQ=',
  detalheConta : {
    gruposPorNomeURL: "https://www.shalom.tec.br/po/api/secured/detalheconta/grupospornome",
    urlBuscaPorDetalheIdENomeParaVinculacao: "https://www.shalom.tec.br/po/api/secured/detalheconta/buscapornomeparavinculacao/",
    buscaPorNome: "https://www.shalom.tec.br/po/api/secured/detalheconta/pornome"
  },
  contaPlano : {
    gruposPorNomeURL: "https://www.shalom.tec.br/po/api/secured/contaplano/grupospornome",
    urlBuscaPorContaIdENomeParaVinculacao: "https://www.shalom.tec.br/po/api/secured/contaplano/buscapornomeparavinculacao/",
    buscaPorNome: "https://www.shalom.tec.br/po/api/secured/contaplano/pornome"
  },
  centroCusto : {
    buscaPorNome: "https://www.shalom.tec.br/po/api/secured/centrocusto/pornome",
    gruposPorNome: "https://www.shalom.tec.br/po/api/secured/centrocusto/grupospornome",
    buscaParaVinculacao: "https://www.shalom.tec.br/po/api/secured/centrocusto/buscaparavinculacao/"
  },
  empresa : {
    empresaPorNomeUrl: "https://www.shalom.tec.br/po/api/secured/empresa/pornome",
  },
  pessoa : {
    pessoaPorNomeUrl: "https://www.shalom.tec.br/po/api/secured/pessoa/pornome"
  },
  po : {
    poPorNomeUrl: "https://www.shalom.tec.br/po/api/secured/planejamentoorcamentario/pornome"
  },
  usuario : {
    selectPorUsername: "https://www.shalom.tec.br/po/api/secured/usuario/selectporusername"
  },
  imagem : {
    urlBaseImagem: "https://www.shalom.tec.br/po/api/secured/imagem/arquivo/"
  },
  configServidor : {
    apiUrl : "https://www.shalom.tec.br/po/api/secured/",
    apiUrlNotSecured: "https://www.shalom.tec.br/po/api/",
    tokenUrl : "https://www.shalom.tec.br/po/oauth/token",
    checkTokenUrl : "https://www.shalom.tec.br/po/oauth/check_token?token="
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
