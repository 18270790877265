import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { PlanejamentoOrcamentario } from 'src/app/model/planejamentoorcamentario.model';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { timeout } from 'rxjs/operators';


@Injectable()
export class PlanejamentoOrcamentarioService extends GenericService<PlanejamentoOrcamentario> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('planejamentoorcamentario');
    }

    w4Nomes() : Promise<any> {
        return this.http.get<any>(this.apiURL+'/w4nomes/', { 'headers' : this.headers }).toPromise();
    }

    buscar(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/buscar', JSON.stringify(busca), { 'headers' : this.headers }).toPromise();
    }

    gerarCopiasAnuaisPlanejamentoOrcamentario(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/copiaanual', JSON.stringify(request), { 'headers' : this.headers }).toPromise();
    }

    buscarPorRegra(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/buscarporregra', JSON.stringify(busca), { 'headers' : this.headers }).toPromise();
    }

    gerarPlanilhaPO(id : number) : Promise<any> {
        return this.http.get<any>(this.apiURL+'/planilhapo/'+id, { 'headers' : this.headers }).toPromise();
    }

    finalizarPO(id : number) : Promise<any> {
        return this.http.get<any>(this.apiURL+'/finalizar/'+id, { 'headers' : this.headers }).toPromise();
    }

    reabrirPO(id : number) : Promise<any> {
        return this.http.get<any>(this.apiURL+'/reabrir/'+id, { 'headers' : this.headers }).toPromise();
    }

    relatorioComparativo(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/relatoriocomparativo', JSON.stringify(busca), { 'headers' : this.headers }).pipe(timeout(600000)).toPromise();
    }

    copiarPlanejamentoOrcamentario(id : number,busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/copiar/'+id, JSON.stringify(busca), { 'headers' : this.headers }).toPromise();
    }

    criarResumo(id: number) : Promise<any> {
        return this.http.get<any>(this.apiURL+'/criarresumo/'+id, { 'headers' : this.headers }).toPromise();
    }

    downloadExcel(id: number) : void {
        this.http.get(`${this.apiURL}/excel/${id}`,{responseType: 'blob' as 'json',headers: this.headers}).subscribe(
            (response:any) => {
                let dataType = response.type;
                let binaryData = [];
                binaryData.push(response);
                let downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
                downloadLink.setAttribute('download', 'Planejamento Orçamentário');
                document.body.appendChild(downloadLink);
                downloadLink.click();
            });            
    }
}