import { Permissao } from './permissao.model';

export class Autorizacao {

    constructor(
        public id: number,
        public nome: string,
        public nomeExibicao: string,
        public permissoes: Permissao[]
    ){}

}