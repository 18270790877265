import { Pessoa } from '../model/pessoa.model';
import { AbstractBuilder } from './abstract.builder';


export class PessoaBuilder extends AbstractBuilder<Pessoa> {

    reset() : void {
        this.entity = new Pessoa(null, null, null, null, null, null);
    }

}