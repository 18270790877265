import { Component, OnInit } from '@angular/core';
import { Regional } from 'src/app/model/regional.model';
import { RegionalService } from './service/regional.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'regionais',
    templateUrl: './regionais.component.html'
})
export class RegionaisComponent implements OnInit {

    public entities: Array<Regional>;
    public busca: any;
    public totalDePaginas: number;

    constructor(
        public regionalService: RegionalService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.entities = new Array<Regional>();        
        this.busca = {
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 10
        };
    }

    ngOnInit() {
        this.buscar();
    }   

    buscar() : void {
        this.regionalService.buscar(this.busca).then( (response: any) => {
            this.entities = response.entity;
            this.totalDePaginas = response.quantity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero-1;
        this.buscar();
    }
    
    deletar(id: number) : void {

        if(confirm('Deseja deletar este regional?'))
        {
            this.regionalService.delete(id)
            .then( (response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscar();
            })
            .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }
}