import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { Imagem } from 'src/app/model/imagem.model';

@Injectable()
export class ImagemService extends GenericService<Imagem> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('imagem');
    }

    salvarCropped(imageData: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/cropped/salvar', JSON.stringify(imageData), { 'headers' : this.headers }).toPromise();
    }

} 