import { Component, Input } from '@angular/core';
import { CategoriaPlanejamentoOrcamentario } from 'src/app/model/categoriaplanejamentoorcamentario.model';
import { ContaPlanoService } from '../../contaplano/service/contaplano.service';
import { DetalheContaService } from '../../detalheconta/service/detalheconta.service';
import { PrevisaoOrcamentariaService } from '../../previsaoorcamentaria/service/previsaoorcamentaria.service';
import { PrevisaoOrcamentaria } from 'src/app/model/previsaoorcamentaria.model';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { PlanilhaUtilsService } from '../service/planilhautils.service';
import { PrevisaoOrcamentariaBuilder } from 'src/app/builder/previsaoorcamentaria.builder';
import { Usuario } from 'src/app/model/usuario.model';
import { UsuarioService } from '../../usuario/service/usuario.service';
import { UsuarioBuilder } from 'src/app/builder/usuario.builder';

@Component({
    selector: '[planilha-previsao-avulsa]',
    templateUrl: './planilhaprevisaoorcamentariaavulsa.component.html',
    styleUrls: ['../style/table-sheet.css','../style/scrollbar.css']
})
export class PlanilhaPrevisaoOrcamentariaAvulsaComponent {

    @Input() 
    public categoria: CategoriaPlanejamentoOrcamentario;

    public previsao: PrevisaoOrcamentaria;
    public previsaoBuilder: PrevisaoOrcamentariaBuilder;
    public usuarioAtual: Usuario;
    public usuarioBuilder: UsuarioBuilder;

    constructor(
        public contaPlanoService: ContaPlanoService,
        public detalheContaService: DetalheContaService,
        public previsaoOrcamentariaService: PrevisaoOrcamentariaService,
        public swtAlert2Service: SwtAlert2Service,
        public planilhaUtilService: PlanilhaUtilsService,
        public usuarioService: UsuarioService
    ) {
        this.usuarioBuilder = new UsuarioBuilder();
        this.usuarioAtual = this.usuarioBuilder.getInstance();
        this.getUsuarioAtual();
        this.previsaoBuilder = new PrevisaoOrcamentariaBuilder();
        this.previsao = this.previsaoBuilder.getInstance();
    }

    async getUsuarioAtual() : Promise<any> {

        try 
        {
            let response = await this.usuarioService.getCurrentUser();   
            this.usuarioAtual = response.entity;        
        }
        catch(e)
        {
            console.log(e);
        }

    }

    async salvarPrevisaoOrcamentariaSubItem() : Promise<any> {

        this.previsao.planejamentoOrcamentarioId = this.categoria.planejamentoOrcamentarioId;
        this.previsao.categoriaPlanejamentoOrcamentarioId = this.categoria.id;
        this.previsao.previsaoOrcamentariaPertencenteId = this.categoria.previsaoOrcamentariaInicial.id;
        this.previsao.nivelArvore = this.categoria.previsaoOrcamentariaInicial.nivelArvore + 1;
        this.previsao.ordemExibicao = this.categoria.previsaoOrcamentariaInicial.previsoesOrcamentariasVinculadas.length;

        try
        {         
            let response = await this.previsaoOrcamentariaService.create(this.previsao);            
            this.previsao = this.previsaoBuilder.getInstance();
            this.carregarPrevisaoInicial();
            $("#previsao-orcamentaria-avulsa-"+this.categoria.id).modal('hide');
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async deletarPrevisaoOrcamentaria(id:number) : Promise<any> {

        try
        {
            let response = await this.previsaoOrcamentariaService.delete(id);            
            await this.carregarPrevisaoInicial();
            let response2 = await this.previsaoOrcamentariaService.update(this.categoria.previsaoOrcamentariaInicial.id,this.categoria.previsaoOrcamentariaInicial);
            this.carregarPrevisaoInicial();
            this.swtAlert2Service.successAlert(response.message)
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async atualizarPrevisaoOrcamentaria(previsao:PrevisaoOrcamentaria) : Promise<any> {

        try
        {
            let response = await this.previsaoOrcamentariaService.update(previsao.id,previsao);
            this.swtAlert2Service.successAlert(response.message);                       
            this.carregarPrevisaoInicial();                        
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarPrevisaoInicial() : Promise<any> {

        try
        {

        let response = await this.previsaoOrcamentariaService.buscarPrevisaoOrcamentariaInicialPorCategoria(this.categoria.id);
        this.categoria.previsaoOrcamentariaInicial = response.entity;

        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    executarRotinaEdicaoPrevisaoOrcamentaria(previsao:PrevisaoOrcamentaria) : void {
        if(!previsao.exibirCamposEdicao && previsao.contaPlanoId == null)
        {
            previsao.exibirCamposEdicao = true;
            previsao.exibirCamposEdicao = this.planilhaUtilService.filtragemEdicaoLinhas(this.usuarioAtual.autorizacoesNome[0],previsao.nome, previsao.contaPlanoCodigo);            
        }
    }

    public colorHexToRGB(hex:any,previsao:PrevisaoOrcamentaria) : any {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        let retorno = null;
        let fatorCorrecao = previsao.nivelArvore*0.20;
        
        if(result && previsao.contaPlanoId)
        {
            retorno = {
                r: parseInt(result[1], 16) + (255 - parseInt(result[1], 16))*fatorCorrecao,
                g: parseInt(result[2], 16) + (255 - parseInt(result[2], 16))*fatorCorrecao,
                b: parseInt(result[3], 16) + (255 - parseInt(result[3], 16))*fatorCorrecao
            };
        } 
        else
        {
            retorno = {
                r: 255,
                g: 255,
                b: 255
            };
        }

        return result ? 'rgb('+retorno.r+','+retorno.g+','+retorno.b+')' : null;
    }

    public compareDate(data1:any, data2:any) : any {
        return new Date(data1.data).getTime() - new Date(data2.data).getTime();
    }

}