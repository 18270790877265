import { Component, OnInit } from '@angular/core';
import { MissaoService } from './service/missao.service';
import { Missao } from 'src/app/model/missao.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Regional } from 'src/app/model/regional.model';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { RegionalService } from '../regional/service/regional.service';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';
import { MissaoBuilder } from 'src/app/builder/missao.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'missao-edit',
    templateUrl: './missao.component.html'
})
export class MissaoEditComponent implements OnInit {

    public missaoId: number;
    public missao: Missao;
    public regionais: Array<Regional>;
    public fasesMissao: Array<ElementoGrupoDominio>;
    public missaoBuilder: MissaoBuilder;

    constructor(
        public missaoService: MissaoService,
        public regionalService: RegionalService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public swtAlert2Service: SwtAlert2Service,
        public route: ActivatedRoute,
        public router: Router
    ) 
    {
        this.missaoBuilder = new MissaoBuilder();
        this.missao = this.missaoBuilder.getInstance();
        this.regionais = new Array<Regional>();
        this.fasesMissao = new Array<ElementoGrupoDominio>();        
    }

    ngOnInit() {  
        this.missaoId = Number(this.route.snapshot.paramMap.get('id'));
        this.initialize();
    }

    async initialize() : Promise<null> {

        await this.carregarRegionais();
        await this.carregarFasesMissao();
        await this.carregarMissao(this.missaoId);

        return null;
    }

    async carregarMissao(id: number): Promise<Missao> {

        try
        {
            let response = await this.missaoService.find(id);
            this.missao = response.entity;  
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

        return null;
    }

    async carregarRegionais() : Promise<null> {

        try 
        {
            let response = await this.regionalService.findAll();
            this.regionais = response.entity;
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }        

        return null;
    }

    async carregarFasesMissao() : Promise<null> {

        try
        {
            let buscaFase = {
                grupoNome : 'FASE_MISSAO'
            }
    
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase);
            this.fasesMissao = response.entity;
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }        

        return null;
    }

    salvar(missao: Missao) : void {

        this.missaoService.update(missao.id,missao).then( (response:any) => {
            this.missao = this.missaoBuilder.getInstance();
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/missoes']);
            }, 2000);
        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }


}