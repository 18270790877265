import { Difusao } from '../model/difusao.model';
import { AbstractBuilder } from './abstract.builder';


export class DifusaoBuilder extends AbstractBuilder<Difusao> {

    reset() : void {
        this.entity = new Difusao(null,null,null,null,null);
    }

}