import { NgModule } from '@angular/core';
import { PlanejamentoOrcamentarioComponent } from './planejamentoorcamentario.component';
import { Routes, RouterModule } from '@angular/router';
import { PlanejamentosOrcamentariosComponent } from './planejamentosorcamentarios.component';
import { PlanejamentoOrcamentarioEditComponent } from './planejamentoorcamentario.edit.component';
import { PlanejamentoOrcamentarioPlanilhaComponent } from './planejamentoorcamentarioplanilha.component';
import { PlanejamentosOrcamentariosParaUsuariosComponent } from './usuario/planejamentosorcamentarios.component';
import { PlanejamentoOrcamentarioPlanilhaParaUsuarioComponent } from './usuario/planejamentoorcamentarioplanilha.component';
import { PlanejamentosOrcamentariosParaGestorComponent } from './gestor/planejamentosorcamentarios.component';
import { PlanejamentoOrcamentarioEditParaGestorComponent } from './gestor/planejamentoorcamentario.edit.component';
import { PlanejamentoOrcamentarioPlanilhaParaGestorComponent } from './gestor/planejamentoorcamentarioplanilha.component';

const planejamentoOrcamentarioRoutes: Routes = [
    {
        path: 'secured/planejamentoorcamentario',
        component: PlanejamentoOrcamentarioComponent
    },
    {
        path: 'secured/planejamentosorcamentarios',
        component: PlanejamentosOrcamentariosComponent
    },
    {
        path: 'secured/planejamentoorcamentario/:id',
        component: PlanejamentoOrcamentarioEditComponent
    },
    {
        path: 'secured/planejamentoorcamentario/:id/planilha',
        component: PlanejamentoOrcamentarioPlanilhaComponent
    },
    {
        path: 'secured/g/planejamentosorcamentarios',
        component: PlanejamentosOrcamentariosParaGestorComponent
    },
    {
        path: 'secured/g/planejamentoorcamentario/:id',
        component: PlanejamentoOrcamentarioEditParaGestorComponent
    },
    {
        path: 'secured/g/planejamentoorcamentario/:id/planilha',
        component: PlanejamentoOrcamentarioPlanilhaParaGestorComponent
    },
    {
        path: 'secured/u/planejamentosorcamentarios',
        component: PlanejamentosOrcamentariosParaUsuariosComponent
    },
    {
        path: 'secured/u/planejamentoorcamentario/:id/planilha',
        component: PlanejamentoOrcamentarioPlanilhaParaUsuarioComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(planejamentoOrcamentarioRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class PlanejamentoOrcamentarioRoutingModule {
    
}