import { NgModule } from '@angular/core';
import { EmpresaComponent } from './empresa.component';
import { EmpresaEditComponent } from './empresa.edit.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { EmpresaRoutingModule } from './empresa-routing.module';
import { FormsModule } from '@angular/forms';
import { SecuredModule } from '../layout/secured.module';
import { EmpresaService } from './service/empresa.service';
import { EmpresasComponent } from './empresas.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

 @NgModule({
     declarations:[
         EmpresaComponent,
         EmpresasComponent,
         EmpresaEditComponent
     ],
     imports:[
         CommonModule,
         RouterModule,
         EmpresaRoutingModule,
         FormsModule,
         SecuredModule,
         SweetAlert2Module
     ],
     providers:[
         EmpresaService     
     ]
 })
 export class EmpresaModule{
     
 }