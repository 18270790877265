import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Pessoa } from 'src/app/model/pessoa.model';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";

@Injectable()
export class PessoaService extends GenericService<Pessoa> {
    
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('pessoa');
    }
    
    buscarPessoas(busca: any) {
        return this.http.post<any>(this.apiURL+'/buscar', JSON.stringify(busca), { 'headers' : this.headers }).toPromise();
    }

    buscaPorUsuarioId(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/porusuario/${id}`, { 'headers' : this.headers }).toPromise();
    }

    buscaPessoaPorUsuarioCriador(): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/porusuariocriador`, { 'headers' : this.headers }).toPromise();
    }

} 