import { Component, OnInit } from '@angular/core';
import { ContaPlanoService } from './service/contaplano.service';
import { ContaPlano } from 'src/app/model/contaplano.model';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { ContaPlanoBuilder } from 'src/app/builder/contaplano.builder';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'contaplano',
    templateUrl: './contaplano.component.html'
})
export class ContaPlanoComponent {

    public contaPlano: ContaPlano;
    public contaPlanoBuilder: ContaPlanoBuilder;
    public urlGruposPorNomeURL: string;

    constructor(
        public contaPlanoService: ContaPlanoService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {        
        this.contaPlanoBuilder = new ContaPlanoBuilder();
        this.contaPlano = this.contaPlanoBuilder.getInstance();
        this.urlGruposPorNomeURL = environment.contaPlano.gruposPorNomeURL;
    }

    salvar(contaplano: ContaPlano) : void {

        this.contaPlanoService.create(contaplano).then( (response:any) => {
            this.contaPlano = this.contaPlanoBuilder.getInstance();
            this.limparSelect2();
            this.swtAlert2Service.successAlert(response.message);
        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    limparSelect2() : void {
        $("select[name='cp_pertencente']").empty();
    }

    setarElementoSelecionado(id): void {
        this.contaPlano.contaPlanoPertencenteId = id;
    }

}