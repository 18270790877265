import { Component, OnInit } from '@angular/core';
import { NotificacaoService } from './service/notificacao.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'regional',
    templateUrl: './notificacao.component.html'
})
export class NotificacaoComponent implements OnInit {

    public mensagem: string;

    constructor(
        public notificacaoService: NotificacaoService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {}

    ngOnInit() {        
    }

    async nviarMensagem(mensagem: string) : Promise<any> {

        confirm("Deseja enviar essa mensagem para todos os usuários conectados?")
        {
            try
            {
                let response = await this.notificacaoService.enviarMensagem(mensagem);
                this.mensagem = "";
            }
            catch(e)
            {
                this.swtAlert2Service.errorAlert(e.error.errors)
            }
        }
        
        
    }

}