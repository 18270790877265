import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      { 
        enableTracing: false, // <-- debugging purposes only
        scrollPositionRestoration: 'enabled', // or 'top'
        anchorScrolling: 'enabled',
        scrollOffset: [0, 64] // [x, y] - adjust scroll offset
      } 
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { 

  
}
