import { Component, OnInit } from '@angular/core';
import { PlanejamentoOrcamentarioService } from '../service/planejamentoorcamentario.service';
import { PlanejamentoOrcamentario } from 'src/app/model/planejamentoorcamentario.model';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { PlanejamentoOrcamentarioBuilder } from 'src/app/builder/planejamentoorcamentario.builder';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { ElementoGrupoDominioService } from '../../grupodominio/service/elementogrupodominio.service';
import { environment } from 'src/environments/environment';
import { CategoriaPlanejamentoOrcamentario } from 'src/app/model/categoriaplanejamentoorcamentario.model';
import { CategoriaPlanejamentoOrcamentarioService } from '../service/categoriaplanejamentoorcamentario.service';
import { CategoriaPlanejamentoOrcamentarioBuilder } from 'src/app/builder/categoriaplanejamentoorcamentario.builder';

@Component({
    selector: 'planejamentoorcamentario-edit',
    templateUrl: './planejamentoorcamentario.component.html'
})
export class PlanejamentoOrcamentarioEditParaGestorComponent implements OnInit {

    public planejamentoOrcamentarioBuilder : PlanejamentoOrcamentarioBuilder;
    public planejamentoOrcamentario: PlanejamentoOrcamentario;
    public planejamentoOrcamentarioId: number;
    public categoriaPlanejamentoOrcamentario: CategoriaPlanejamentoOrcamentario;
    public categoriaPlanejamentoOrcamentarioBuilder: CategoriaPlanejamentoOrcamentarioBuilder;
    public busca: any;
    public totalDePaginas: number;
    public listaStatus : Array<ElementoGrupoDominio>;
    public listaInstancias : Array<ElementoGrupoDominio>;
    public listaCategorias : Array<CategoriaPlanejamentoOrcamentario>;
    public urlBuscaEmpresaPorNome: string;

    constructor(
        public planejamentoorcamentarioService: PlanejamentoOrcamentarioService,
        public categoriaPlanejamentoOrcamentarioService: CategoriaPlanejamentoOrcamentarioService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public swtAlert2Service: SwtAlert2Service,
        public route: ActivatedRoute,
        public router: Router
    ) {
        this.planejamentoOrcamentarioBuilder = new PlanejamentoOrcamentarioBuilder();
        this.categoriaPlanejamentoOrcamentarioBuilder = new CategoriaPlanejamentoOrcamentarioBuilder();
        this.planejamentoOrcamentario = this.planejamentoOrcamentarioBuilder.getInstance();
        this.categoriaPlanejamentoOrcamentario = this.categoriaPlanejamentoOrcamentarioBuilder.getInstance();
        this.urlBuscaEmpresaPorNome = environment.empresa.empresaPorNomeUrl;
        this.listaStatus = new Array<ElementoGrupoDominio>();
        this.listaInstancias = new Array<ElementoGrupoDominio>();
        this.listaCategorias = new Array<CategoriaPlanejamentoOrcamentario>();
    }

    ngOnInit() {
        this.planejamentoOrcamentarioId = Number(this.route.snapshot.paramMap.get('id'));
        this.carregarPlanejamentoOrcamentario(this.planejamentoOrcamentarioId);
        this.carregarStatus();
        this.carregarInstancias();
        this.carregarCategorias();
    }

    carregarPlanejamentoOrcamentario(id: number): void {
        this.planejamentoorcamentarioService.find(id).then((response: any) => {
            this.planejamentoOrcamentario = response.entity;
            $("select[name='empresas']").append(new Option(this.planejamentoOrcamentario.empresaNome, this.planejamentoOrcamentario.empresaId.toString(), false, true));
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    salvar(planejamentoorcamentario: PlanejamentoOrcamentario): void {

        this.planejamentoorcamentarioService.update(planejamentoorcamentario.id, planejamentoorcamentario).then((response: any) => {
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/g/planejamentosorcamentarios']);
            }, 2000);
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    salvarCategoria(categoriaplanejamentoorcamentario: CategoriaPlanejamentoOrcamentario): void {

        if(this.categoriaPlanejamentoOrcamentario.id == null)
        {
            this.categoriaPlanejamentoOrcamentario.planejamentoOrcamentarioId = this.planejamentoOrcamentarioId;

            this.categoriaPlanejamentoOrcamentarioService.create(categoriaplanejamentoorcamentario).then((response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.categoriaPlanejamentoOrcamentario = this.categoriaPlanejamentoOrcamentarioBuilder.getInstance();
                this.carregarCategorias();
                $('#modal-categoria').modal('hide');
            }).catch((err: any) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
        else
        {
            this.categoriaPlanejamentoOrcamentario.planejamentoOrcamentarioId = this.planejamentoOrcamentarioId;

            this.categoriaPlanejamentoOrcamentarioService.update(categoriaplanejamentoorcamentario.id,categoriaplanejamentoorcamentario).then((response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.carregarCategorias();
                $('#modal-categoria').modal('hide');
            }).catch((err: any) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }

    }

    setarCategoria(categoria: CategoriaPlanejamentoOrcamentario) : void {
        this.categoriaPlanejamentoOrcamentario = categoria;
    }

    async carregarStatus() : Promise<any> {

        let buscaFase = {
            grupoNome : 'STATUS_PO'
        }

        try
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase);
            this.listaStatus = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarInstancias() : Promise<any> {

        let buscaFase = {
            grupoNome : 'INSTANCIA_PO'
        }

        try
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase);
            this.listaInstancias = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarCategorias() : Promise<any> {

        try
        {
            let response = await this.categoriaPlanejamentoOrcamentarioService.buscaPorPlanejamentoOrcamentarioId(this.planejamentoOrcamentarioId);
            this.listaCategorias = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    deletarCategoria(id: number) : void {

        if(confirm('Deseja deletar este planejamento orçamentário?'))
        {
            this.categoriaPlanejamentoOrcamentarioService.delete(id)
            .then( (response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.carregarCategorias();
            })
            .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }   

}