import { Component, OnInit } from '@angular/core';
import { Regional } from 'src/app/model/regional.model';
import { RegionalService } from '../regional/service/regional.service';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { Difusao } from 'src/app/model/difusao.model';
import { DifusaoService } from './service/difusao.service';
import { DifusaoBuilder } from 'src/app/builder/difusao.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'difusao',
    templateUrl: './difusao.component.html'
})
export class DifusaoComponent implements OnInit {

    public difusao: Difusao;
    public regionais: Array<Regional>;    
    public fasesDifusao: Array<ElementoGrupoDominio>;
    public etapasDifusao: Array<ElementoGrupoDominio>;
    public difusaoBuilder: DifusaoBuilder;

    constructor(
        public difusaoService: DifusaoService,
        public regionalService: RegionalService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.difusaoBuilder = new DifusaoBuilder();
        this.difusao = this.difusaoBuilder.getInstance();
        this.regionais = new Array<Regional>();        
        this.fasesDifusao = new Array<ElementoGrupoDominio>();        
        this.etapasDifusao = new Array<ElementoGrupoDominio>();
    }

    ngOnInit() {  
        this.carregarRegionais();   
        this.carregarFasesDifusao();  
        this.carregarEtapasDifusao();
    }

    carregarRegionais() : void {
        this.regionalService.findAll().then( (lista: any) => {
            this.regionais = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarFasesDifusao() : void {

        let buscaFase = {
            grupoNome : 'FASE_DIFUSAO'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase).then( (lista: any) => {
            this.fasesDifusao = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarEtapasDifusao() : void {

        let buscaEtapa = {
            grupoNome : 'ETAPA_DIFUSAO'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaEtapa).then( (lista: any) => {
            this.etapasDifusao = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    salvar(difusao: Difusao) : void {

        this.difusaoService.create(difusao).then( (response:any) => {
            this.difusao = this.difusaoBuilder.getInstance();
            this.swtAlert2Service.successAlert(response.message);
        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

}