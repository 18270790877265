import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import * as $ from 'jquery';
import { Token } from 'src/app/model/token.model';

@Component({
    selector: 'select2-component',
    templateUrl: './select2.component.html',
    styles: ['.select2 { max-width: 100% !important; }']
})
export class Select2Component implements OnInit {

    @Input() 
    public name: string;
    @Input()
    public url: string;
    @Input()
    public multiplo: boolean = false;
    @Input()
    public required: boolean = false;
    @Output() 
    public elementoSelecionado = new EventEmitter();
    public select2Called: boolean = false;
    public ajaxOptions: any;
    public results: Array<any>;
    public token : Token; 

    ngOnInit() {
        this.token = JSON.parse(localStorage.getItem("posh-token"));
    }

    ngAfterViewChecked() {

        this.ajaxOptions = {
            url: this.url,
            dataType: 'json',
            data: function(params) {
                return {
                    search: params.term                    
                }
            },
            processResults: function(data, params){
                return {
                    results: data.entity
                }
            },
            headers: {
                'Content-Type' : 'application/json; charset=UTF-8',
                'Authorization' : 'Bearer '+ (this.token ? this.token.access_token : '')
            }				
        };

        if(!this.select2Called && $("select[name='"+this.name+"']").length >= 1) {          
            
            let self = this;
            
            $("select[name='"+this.name+"']").select2({
                theme: "bootstrap4",
                ajax: this.ajaxOptions,
                multiple: this.multiplo
            })
            .trigger('change')
            .on('change',function() {

                let valorSelecionado = null;

                $("select[name='"+self.name+"']").each(function() { 

                    if($(this).val() != null)
                    {
                        valorSelecionado = $(this).val();
                    }                    
                    
                });
                
                self.elementoSelecionado.emit(valorSelecionado);
            });
            
            this.select2Called = true;
        }
    }
}