import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmpresaComponent } from './empresa.component';
import { EmpresasComponent } from './empresas.component';
import { EmpresaEditComponent } from './empresa.edit.component';

const empresaRoutes: Routes = [
    {
        path: 'secured/empresa',
        component: EmpresaComponent
    },
    {
        path: 'secured/empresas',
        component: EmpresasComponent
    },
    {
        path: 'secured/empresa/:id',
        component: EmpresaEditComponent
    }
] 

@NgModule({
    imports: [
        RouterModule.forChild(empresaRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class EmpresaRoutingModule {
    
}