import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DetalheContaService } from './service/detalheconta.service';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../layout/secured.module';
import { RouterModule } from '@angular/router';
import { DetalheContaComponent } from './detalheconta.component';
import { DetalheContaRoutingModule } from './detalheconta-routing.module';
import { DetalheContaEditComponent } from './detalheconta.edit.component';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { DetalhesContaComponent } from './detalhesconta.component';

@NgModule({
    declarations: [ 
        DetalheContaComponent,
        DetalhesContaComponent,
        DetalheContaEditComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        DetalheContaRoutingModule,
        FormsModule,
        SecuredModule
    ],
    providers: [
        DetalheContaService,
        SwtAlert2Service
    ]
})
export class DetalheContaModule {

}