import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/model/usuario.model';
import { UsuarioService } from './service/usuario.service';
import { AutorizacaoService } from '../autorizacao/service/autorizacao.service';
import { Autorizacao } from 'src/app/model/autorizacao.model';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { UsuarioBuilder } from 'src/app/builder/usuario.builder';

@Component({
    selector: 'usuario',
    templateUrl: './usuario.component.html'
})
export class UsuarioComponent implements OnInit {

    public autorizacoes: Array<Autorizacao>;
    public usuarioBuilder : UsuarioBuilder;
    public usuario: Usuario;
    public autorizacaoSelecionada: number = null;
    public total: number;
    public usuarioAtual: Usuario;

    constructor(
        public usuarioService: UsuarioService, 
        public autorizacaoService: AutorizacaoService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.autorizacoes = new Array<Autorizacao>();
        this.usuarioBuilder = new UsuarioBuilder();
        this.usuario = this.usuarioBuilder.getInstance();
    }

    ngOnInit() {
        this.getUsuarioAtual();
        this.carregarAutorizacoes();
    }

    async getUsuarioAtual() : Promise<any> {

        try 
        {
            let response = await this.usuarioService.getCurrentUser();   
            this.usuarioAtual = response.entity;        
        }
        catch(e)
        {
            console.log(e);
        }

    }

    carregarAutorizacoes() : void {
        this.autorizacaoService.findAll().then( (lista: any) => {
            
            this.autorizacoes = lista.entity.filter( (regra) => { 
                
                if(
                    (regra.nome == 'ROLE_ADMIN' || regra.nome == 'ROLE_GESTOR') 
                    && 
                    this.usuarioAtual.autorizacoesNome[0] == 'ROLE_GESTOR'
                ) 
                {
                    return false;
                }
                
                return true;
            
            });

        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    salvar(usuario: Usuario) : void {

        usuario.autorizacoes = new Array<number>();
        usuario.autorizacoes.push(this.autorizacaoSelecionada);

        this.usuarioService.create(usuario).then( (response:any) => {
            this.usuario = this.usuarioBuilder.getInstance();
            this.swtAlert2Service.successAlert(response.message);
        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

}