export class CentroCusto {

    constructor(
        public id : number,
        public w4Id : number,
        public nome : string,
        public tipoHierarquico : string,
        public centroCustoPertencenteId : number,
        public centroCustoPertencenteNome: string,
        public centrosCustoVinculadosIds : Array<number>        
    ){
        this.centrosCustoVinculadosIds = new Array<number>();
    }

}