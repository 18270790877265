import { Component, OnInit } from '@angular/core';
import { DetalheConta } from 'src/app/model/detalheconta.model';
import { DetalheContaService } from './service/detalheconta.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'detalhesconta',
    templateUrl: './detalhesconta.component.html'
})
export class DetalhesContaComponent implements OnInit {

    public detalhesConta: Array<DetalheConta>;
    public busca: any;
    public totalDePaginas: number;
    public url : string;

    constructor(
        public detalhesContaService: DetalheContaService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.detalhesConta = new Array<DetalheConta>();  
        this.url = environment.detalheConta.gruposPorNomeURL;              
        this.busca = {            
            nome : undefined,
            codigo : undefined,
            tipoOperacao : null,
            tipoHierarquico : null,
            contaPlanoPertencenteId : undefined,
            pageNumber : 0, 
            quantityOfElements : 10
        };
    }

    ngOnInit() {
        this.buscarDetalhesConta();
    }   

    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero-1;
        this.buscarDetalhesConta();
    }

    buscarDetalhesConta() : void {

        this.detalhesContaService.buscarDetalhesConta(this.busca).then( (response: any) => {
            this.detalhesConta = response.content;
            this.totalDePaginas = response.totalPages;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
        
    }
    
    deletar(id: number) : void {

        if(confirm('Deseja deletar este detalhe da conta?'))
        {
            this.detalhesContaService.delete(id)
            .then( (response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscarDetalhesConta();
            })
            .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }

    limparSelect2() : void {
        $("select[name='dc_pertencente']").empty();
        this.setarElementoSelecionado(null);
    }

    setarElementoSelecionado(id): void {
        this.busca.detalheContaPertencenteId = id;
    }
}