import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { LoginModule } from './login/login.module';
import { SecuredModule } from './secured/layout/secured.module';
import { UsuarioModule } from './secured/usuario/usuario.module';
import { PermissaoModule } from './secured/permissao/permissao.module';
import { AutorizacaoModule } from './secured/autorizacao/autorizacao.module';
import { LoaderComponent } from './core/loader/loader.component';
import { LoaderService } from './core/loader/service/loader.service';
import { LoaderInterceptor } from './core/loader/interceptors/loader.interceptor';
import { GrupoDominioModule } from './secured/grupodominio/grupodominio.module';
import { RegionalModule } from './secured/regional/regional.module';
import { MissaoModule } from './secured/missao/missao.module';
import { PessoaModule } from './secured/pessoa/pessoa.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DifusaoModule } from './secured/difusao/difusao.module';
import { EmpresaModule } from './secured/empresa/empresa.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ContaPlanoModule } from './secured/contaplano/contaplano.module';
import { DetalheContaModule } from './secured/detalheconta/detalheconta.module';
import { CentroCustoModule } from './secured/centrocusto/centrocusto.module';
import { PlanejamentoOrcamentarioModule } from './secured/planejamentoorcamentario/planejamentoorcamentario.module';
import { CurrencyMaskConfig, CurrencyMaskModule, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import { RegraPOModule } from './secured/regrapo/regrapo.module';
import { DEFAULT_TIMEOUT, TimeoutInterceptor } from './core/interceptor/timeout.interceptor';
import { NotificacaoModule } from './secured/pushnotification/notificacao.module';
import { ResumoModule } from './secured/relatorio/resumo/resumo.module';
import { AppRouteChangeService } from './core/services/app-route-change.service';
import { AuthInterceptor } from './core/interceptor/exception.interceptor';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "right",
  allowNegative: false,
  decimal: ".",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: ","
};

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent
  ],
  imports: [
    SweetAlert2Module.forRoot(),
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    CurrencyMaskModule,
    LoginModule,
    SecuredModule,
    UsuarioModule,
    PermissaoModule,
    AutorizacaoModule,
    GrupoDominioModule,
    RegionalModule,
    MissaoModule,
    PessoaModule,
    DifusaoModule,
    BrowserAnimationsModule,
    ImageCropperModule,
    EmpresaModule,
    ContaPlanoModule,
    DetalheContaModule,
    CentroCustoModule,
    PlanejamentoOrcamentarioModule,
    RegraPOModule,
    NotificacaoModule,
    ResumoModule
  ],
  providers: [
    LoaderService,
    AppRouteChangeService,
    [
      { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
      //{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ],
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    { provide: DEFAULT_TIMEOUT, useValue: 1800000 }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
