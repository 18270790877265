export class Difusao {

    constructor(
        public id: number,
        public nome: string,
        public regionalId: number,        
        public faseDifusaoId: number,
        public etapaDifusaoId: number        
    ){
        
    }

}