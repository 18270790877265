import { Component, OnInit } from '@angular/core';
import { RegraPO } from 'src/app/model/regrapo.model';
import { RegraPOService } from './service/regrapo.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'regraspo',
    templateUrl: './regraspo.component.html'
})
export class RegrasPOComponent implements OnInit {

    public entities: Array<RegraPO>;
    public busca: any;
    public totalDePaginas: number;
    public urlBuscaPoPorNome: string;

    constructor(
        public regionalService: RegraPOService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.entities = new Array<RegraPO>();        
        this.busca = {
            nome: undefined,
            posIds: undefined,
            pageNumber : 0, 
            quantityOfElements : 10
        };
        this.urlBuscaPoPorNome = environment.po.poPorNomeUrl;
    }

    ngOnInit() {
        this.buscar();
    }   

    buscar() : void {
        this.regionalService.buscar(this.busca).then( (response: any) => {
            this.entities = response.content;
            this.totalDePaginas = response.totalPages;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero-1;
        this.buscar();
    }
    
    deletar(id: number) : void {

        if(confirm('Deseja deletar esta regra?'))
        {
            this.regionalService.delete(id)
            .then( (response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscar();
            })
            .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }

    async setarElementoSelecionado(posIds:Array<number>) : Promise<any> {
        this.busca.posIds = posIds;
    }

    limpar() {
        this.limparSelect2('po');
    }

    limparSelect2(nomeEl) : void {
        $("select[name='"+nomeEl+"']").empty();
    }
}