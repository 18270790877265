import { AbstractBuilder } from './abstract.builder';
import { PrevisaoOrcamentaria } from '../model/previsaoorcamentaria.model';
import { MesPrevisaoOrcamentaria } from '../model/mesprevisaoorcamentaria.model';

export class PrevisaoOrcamentariaBuilder extends AbstractBuilder<PrevisaoOrcamentaria> {

    reset() : void {
        this.entity = new PrevisaoOrcamentaria(null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null, new Array<MesPrevisaoOrcamentaria>(),null,null,new Array<PrevisaoOrcamentaria>(),null,null,false);
    }

}