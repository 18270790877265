import { Component, OnInit } from '@angular/core';
import { ContaPlanoService } from './service/contaplano.service';
import { ContaPlano } from 'src/app/model/contaplano.model';
import { Router, ActivatedRoute } from '@angular/router';
import { ContaPlanoBuilder } from 'src/app/builder/contaplano.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { environment } from 'src/environments/environment';
import { DetalheContaService } from '../detalheconta/service/detalheconta.service';
import { DetalheConta } from 'src/app/model/detalheconta.model';

@Component({
    selector: 'contaplano-edit',
    templateUrl: './contaplano.component.html'
})
export class ContaPlanoEditComponent implements OnInit {

    public contaPlano: ContaPlano;
    public contasPlanoVinculadas: Array<ContaPlano>;
    public detalhesContaVinculados: Array<DetalheConta>;
    public contaPlanoId: number;
    public busca: any;
    public contaPlanoBuilder: ContaPlanoBuilder;
    public urlGruposPorNomeURL : string;
    public urlBuscaPorContaIdENomeParaVinculacao : string;
    public urlBuscaPorNomeParaVinculacaoDeDetalheConta : string;
    public requestVinculacao: any;

    constructor(
        public contaPlanoService: ContaPlanoService,
        public detalheContaService: DetalheContaService,
        public swtAlert2Service: SwtAlert2Service,
        public route: ActivatedRoute,
        public router: Router
    ) {
        this.contaPlanoBuilder = new ContaPlanoBuilder();
        this.contaPlano = this.contaPlanoBuilder.getInstance();    
        this.contasPlanoVinculadas = new Array<ContaPlano>();  
        this.detalhesContaVinculados = new Array<DetalheConta>();  
        this.busca = {
            contaPlanoId: this.contaPlanoId,
            pageNumber: 0,
            quantityOfElements: 10
        };     
        this.urlGruposPorNomeURL = environment.contaPlano.gruposPorNomeURL;
        this.urlBuscaPorContaIdENomeParaVinculacao = environment.contaPlano.urlBuscaPorContaIdENomeParaVinculacao+this.contaPlanoId;
        this.urlBuscaPorNomeParaVinculacaoDeDetalheConta = environment.detalheConta.buscaPorNome;           
    }

    ngOnInit() {
        this.route.params.subscribe(
            params => {
                this.contaPlanoId = +params['id'];
                this.carregarContaPlano(this.contaPlanoId);
                this.carregarContasPlanoVinculadas();
                this.carregarDetalhesContaVinculadas();
                this.urlGruposPorNomeURL = environment.contaPlano.gruposPorNomeURL;
                this.urlBuscaPorContaIdENomeParaVinculacao = environment.contaPlano.urlBuscaPorContaIdENomeParaVinculacao+this.contaPlanoId;
                this.urlBuscaPorNomeParaVinculacaoDeDetalheConta = environment.detalheConta.buscaPorNome;
            }
        );           
    }

    carregarContaPlano(id: number): void {
        this.contaPlanoService.find(id).then((response: any) => {
            this.contaPlano = response.entity;  
            if(this.contaPlano.contaPlanoPertencenteId != null)
            {
                $("select[name='cp_pertencente']").append(new Option(this.contaPlano.contaPlanoPertencenteNome, this.contaPlano.contaPlanoPertencenteId.toString(), false, true));
            }         
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    async carregarContasPlanoVinculadas(): Promise<any> {
        
        try
        {
            let response = await this.contaPlanoService.buscaPorContaPlanoPertencenteId(this.contaPlanoId);
            this.contasPlanoVinculadas = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarDetalhesContaVinculadas(): Promise<any> {
        
        try
        {
            let response = await this.detalheContaService.buscaPorContaPlanoId(this.contaPlanoId);
            this.detalhesContaVinculados = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    salvar(contaPlano: ContaPlano): void {

        this.contaPlanoService.update(contaPlano.id, contaPlano).then((response: any) => {
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/contasplano']);
            }, 2000);

        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    deletar(id: number) : void {

        if(confirm('Deseja deletar este conta do plano?'))
        {
            this.contaPlanoService.delete(id)
            .then( (response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.carregarContasPlanoVinculadas();
            })
            .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }

    setarContaPlanoVinculavel(id:any): void {
        this.requestVinculacao = {
            "id" : this.contaPlanoId,
            "vincularId" : Number(id)
        };
    }

    setarDetalheContaVinculavel(id:any): void {
        this.requestVinculacao = {
            "id" : this.contaPlanoId,
            "vincularId" : Number(id)
        };
    }

    async vincularContaPlano(): Promise<any> {

        try 
        {
            let response = await this.contaPlanoService.vicularContaPlano(this.requestVinculacao);
            this.requestVinculacao = {};
            this.limparSelect2Vinculavel();
            this.swtAlert2Service.successAlert(response.message);
            this.carregarContasPlanoVinculadas();
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async vincularDetalheConta(): Promise<any> {

        try 
        {
            let response = await this.contaPlanoService.vicularDetalheConta(this.requestVinculacao);
            this.requestVinculacao = {};
            this.limparSelect2DetalheContaVinculavel();
            this.swtAlert2Service.successAlert(response.message);
            this.carregarDetalhesContaVinculadas();
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async desvincularContaPlano(id:any): Promise<any> {

        this.requestVinculacao = {
            "id" : this.contaPlanoId,
            "vincularId" : Number(id)
        };

        try 
        {
            let response = await this.contaPlanoService.desvicularContaPlano(this.requestVinculacao);
            this.requestVinculacao = {};
            this.swtAlert2Service.successAlert(response.message);
            this.carregarContasPlanoVinculadas();
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async desvincularDetalheConta(id:any): Promise<any> {

        this.requestVinculacao = {
            "id" : this.contaPlanoId,
            "vincularId" : Number(id)
        };

        try 
        {
            let response = await this.contaPlanoService.desvicularDetalheConta(this.requestVinculacao);
            this.requestVinculacao = {};
            this.swtAlert2Service.successAlert(response.message);
            this.carregarDetalhesContaVinculadas();
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    limparSelect2() : void {
        $("select[name='cp_pertencente']").empty();
        this.setarElementoSelecionado(null);
    }

    limparSelect2Vinculavel() : void {
        $("select[name='cp_vincular']").empty();
    }

    limparSelect2DetalheContaVinculavel() : void {
        $("select[name='dc_vincular']").empty();
    }

    setarElementoSelecionado(id): void {
        this.contaPlano.contaPlanoPertencenteId = id;
    }

}