import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { timeout } from 'rxjs/operators';


@Injectable()
export class HistoricoPlanejamentoOrcamentarioService extends GenericService<any> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('historicopo');
    }


    criarHistorico(id : number) : Promise<any> {
        return this.http.get<any>(this.apiURL+'/criar/'+id, { 'headers' : this.headers }).toPromise();
    }


}