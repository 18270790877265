import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UsuarioComponent } from './usuario.component';
import { UsuarioRoutingModule } from './usuario-routing.module';
import { UsuarioService } from './service/usuario.service';
import { AutorizacaoService } from '../autorizacao/service/autorizacao.service';
import { CommonModule } from '@angular/common';
import { UsuariosComponent } from './usuarios.component';
import { SecuredModule } from '../layout/secured.module';
import { RouterModule } from '@angular/router';
import { UsuarioEditComponent } from './usuario.edit.component';

@NgModule({
    declarations: [ 
        UsuarioComponent,
        UsuarioEditComponent,
        UsuariosComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        UsuarioRoutingModule,
        FormsModule,
        SecuredModule
    ],
    providers: [
        UsuarioService,
        AutorizacaoService
    ]
})
export class UsuarioModule {

}