export class ContaPlano {

    constructor(
        public id : number,
        public w4Id : number,
        public nome : string,
        public codigo : string,
        public tipoHierarquico : string,
        public tipoOperacao : string,
        public contaPlanoPertencenteId : number,
        public contaPlanoPertencenteNome: string,
        public contasPlanoVinculadasIds : Array<number>,
        public detalhesContaVinculadosIds : Array<number>
    ){
        this.contasPlanoVinculadasIds = new Array<number>();
        this.detalhesContaVinculadosIds = new Array<number>();
    }

}