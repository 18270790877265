import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RegionalService } from './service/regional.service';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../layout/secured.module';
import { RouterModule } from '@angular/router';
import { RegionalComponent } from './regional.component';
import { RegionalRoutingModule } from './regional-routing.module';
import { RegionaisComponent } from './regionais.component';
import { RegionalEditComponent } from './regional.edit.component';

@NgModule({
    declarations: [ 
        RegionalComponent,
        RegionaisComponent,
        RegionalEditComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        RegionalRoutingModule,
        FormsModule,
        SecuredModule
    ],
    providers: [
        RegionalService
    ]
})
export class RegionalModule {

}