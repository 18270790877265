import { Component, OnInit } from "@angular/core";
import { Empresa } from 'src/app/model/empresa.model';
import { EmpresaService } from './service/empresa.service';
import { Regional } from 'src/app/model/regional.model';
import { Missao } from 'src/app/model/missao.model';
import { Difusao } from 'src/app/model/difusao.model';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { RegionalService } from '../regional/service/regional.service';
import { MissaoService } from '../missao/service/missao.service';
import { DifusaoService } from '../difusao/service/difusao.service';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { environment } from 'src/environments/environment';
import { env } from 'process';
import { PlanejamentoOrcamentarioService } from '../planejamentoorcamentario/service/planejamentoorcamentario.service';
import { Usuario } from 'src/app/model/usuario.model';
import { UsuarioService } from '../usuario/service/usuario.service';
import { Pessoa } from 'src/app/model/pessoa.model';
import { PessoaService } from '../pessoa/service/pessoa.service';

 @Component({
     selector: 'empresas',
     templateUrl: './empresas.component.html'
 })
 export class EmpresasComponent implements OnInit{
     
    public empresas: Array<Empresa>;
    public regionais: Array<Regional>;
    public missoes: Array<Missao>;
    public difusoes: Array<Difusao>;
    public grupoPertencente: Array<ElementoGrupoDominio>;
    public segmento: Array<ElementoGrupoDominio>;
    public busca: any;
    public requestVinculo : any;
    public totalDePaginas: number; 
    public url: string;
    public urlPlanejamentoPorNome: string;
    public poSelecionadoParaCopiaId : number;
    public usuarioAtual: Usuario;
    public pessoasGestor: Array<Pessoa>;
    public pessoaVinculoSelecionadaId: number;

    constructor(
        public empresaService: EmpresaService,
        public regionalService: RegionalService,
        public missaoService: MissaoService,
        public difusaoService: DifusaoService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public swtAlert2Service: SwtAlert2Service,
        public planejamentoOrcamentarioService: PlanejamentoOrcamentarioService,
        public usuarioService: UsuarioService,
        public pessoaService: PessoaService
    ){
        this.empresas = new Array<Empresa>();
        this.busca = {
            regionalId: null,
            missaoId: null,
            difusaoId: null,
            nome: null,
            grupoPertencenteId: null,
            segmentoId: null,
            w4Id: null,
            pessoasVinculadas: [],
            pageNumber: 0,
            quantityOfElements : 10
        };

        this.requestVinculo = {
            busca: this.busca,
            pessoas : [],
            adicionar: undefined
        }

        this.pessoasGestor = new Array<Pessoa>();

    }

    ngOnInit(): void {
        this.carregarPessoasPorUsuarioCriador();
        this.getUsuarioAtual();
        this.carregarRegionais();
        this.carregarGrupoPertencente();
        this.carregarSegmento();
        this.buscarEmpresas();
        this.url = environment.pessoa.pessoaPorNomeUrl;
        this.urlPlanejamentoPorNome = environment.po.poPorNomeUrl;
    }

    async getUsuarioAtual() : Promise<any> {

        try 
        {
            let response = await this.usuarioService.getCurrentUser();   
            this.usuarioAtual = response.entity;        
        }
        catch(e)
        {
            console.log(e);
        }

    }

    buscarEmpresas() : void {
        this.empresaService.buscarEmpresas(this.busca).then( (response: any) => {
            this.empresas = response.content;            
            this.totalDePaginas = response.totalPages;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    async vincularPessoas() : Promise<any> {

        if(this.pessoaVinculoSelecionadaId != undefined)
        {
            this.requestVinculo.pessoas.push(this.pessoaVinculoSelecionadaId);
        }

        this.requestVinculo.adicionar = true;

        try
        {
            let response = await this.empresaService.vinculoPessoas(this.requestVinculo);
            this.swtAlert2Service.successAlert(response.message);
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }        
    }

    async desvincularPessoas() : Promise<any> {

        if(this.pessoaVinculoSelecionadaId != undefined)
        {
            this.requestVinculo.pessoas.push(this.pessoaVinculoSelecionadaId);
        }

        this.requestVinculo.adicionar = false;
        try
        {
            let response = await this.empresaService.vinculoPessoas(this.requestVinculo);
            this.swtAlert2Service.successAlert(response.message);
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }
    }

    async copiarPlanejamentoOrcamentario() : Promise<any> {
        try
        {
            let response = await this.planejamentoOrcamentarioService.copiarPlanejamentoOrcamentario(this.poSelecionadoParaCopiaId,this.busca);
            this.swtAlert2Service.successAlert(response.message);
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }
    }

    async carregarPessoasPorUsuarioCriador() : Promise<any> {
        try
        {
            let response = await this.pessoaService.buscaPessoaPorUsuarioCriador();
            this.pessoasGestor = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }
    }

    deletarEmpresa(id: number) : void {

        if(confirm('Deseja deletar esta empresa?'))
        {
            this.empresaService.delete(id)
            .then( (response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscarEmpresas();
            })
            .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }

    carregarRegionais() : void {
        this.regionalService.findAll().then( (lista: any) => {
            this.regionais = lista.entity;
        }).catch( (err: any) => {
            console.log(err);
        });
    }
    
    carregarMissoes(): void {
        this.missaoService.porRegional(this.busca.regionalId).then((response: any) => {
            this.missoes = response.entity;          
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    carregarDifusoes(): void {
        this.difusaoService.porRegional(this.busca.regionalId).then((response: any) => {
            this.difusoes = response.entity;          
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    carregarGrupoPertencente() : void {

        let busca = {
            grupoNome : 'EMPRESA_GRUPO_PERTENCENTE'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca).then( (lista: any) => {
            this.grupoPertencente = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarSegmento() : void {

        let busca = {
            grupoNome : 'EMPRESA_SEGMENTO'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca).then( (lista: any) => {
            this.segmento = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    
    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero-1;
        this.buscarEmpresas();
    }

    setarElementoSelecionado(valor) {
        this.busca.pessoasVinculadas = valor;       
    }

    setarVinculoPessoas(pessoas) {
        this.requestVinculo.pessoas = pessoas;
    }

    nomePessoasVinculadas(pessoas: Array<string>) : string {
        let retorno;

        for(let nome of pessoas)
        {
            if(!retorno)
            {
                retorno = nome;
            }
            else
            {
                retorno += ',\n'+nome;
            }
        }

        if(!retorno)
        {
            retorno = 'Não há pessoas vinculadas.'
        }

        return retorno;
    }
 }