import { OnInit, Component } from '@angular/core';
import { Empresa } from 'src/app/model/empresa.model';
import { Alerta } from 'src/app/model/alerta.model';
import { EmpresaService } from './service/empresa.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ImagemService } from '../imagem/service/imagem.service';
import 'select2';
import { EmpresaBuilder } from 'src/app/builder/empresa.builder';
import * as bootastrap from 'bootstrap';
import { RegionalService } from '../regional/service/regional.service';
import { MissaoService } from '../missao/service/missao.service';
import { DifusaoService } from '../difusao/service/difusao.service';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';
import { Regional } from 'src/app/model/regional.model';
import { Missao } from 'src/app/model/missao.model';
import { Difusao } from 'src/app/model/difusao.model';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { PessoaService } from '../pessoa/service/pessoa.service';
import { IDragStartEventArgs } from 'igniteui-angular';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'empresaedit',
    templateUrl: './empresa.component.html'
})

export class EmpresaEditComponent implements OnInit {

    public empresa: Empresa;
    public empresaId: number;
    public regionais: Array<Regional>;
    public missoes: Array<Missao>;
    public difusoes: Array<Difusao>;
    public grupoPertencente: Array<ElementoGrupoDominio>;
    public segmento: Array<ElementoGrupoDominio>;
    public regionalSelecionadoId : number;
    public url: string;
    public empresaBuilder: EmpresaBuilder;       

    constructor(
        public empresaService: EmpresaService,
        public regionalService: RegionalService,
        public missaoService: MissaoService,
        public difusaoService: DifusaoService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public pessoaService: PessoaService,
        public swtAlert2Service: SwtAlert2Service,
        public route: ActivatedRoute,
        public router: Router
    ) {        
        this.missoes = new Array<Missao>();
        this.difusoes = new Array<Difusao>();
        this.grupoPertencente = new Array<ElementoGrupoDominio>(); 
        this.segmento = new Array<ElementoGrupoDominio>();                 
        this.empresaBuilder = new EmpresaBuilder();
        this.empresa = this.empresaBuilder.getInstance();        
    }

    ngOnInit() {         
        this.carregarRegionais();
        this.carregarGrupoPertencente();
        this.carregarSegmento();
        this.regionalSelecionadoId = null; 
        this.url = environment.pessoa.pessoaPorNomeUrl;
        this.empresaId = Number(this.route.snapshot.paramMap.get('id'));        
        this.carregarEmpresa(this.empresaId);  
    }

    carregarRegionais() : void {
        this.regionalService.findAll().then( (lista: any) => {
            this.regionais = lista.entity;            
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    
    carregarMissoes(): void {
        this.missaoService.porRegional(this.regionalSelecionadoId).then((response: any) => {
            this.missoes = response.entity;               
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);            
        });
    }

    carregarDifusoes(): void {
        this.difusaoService.porRegional(this.regionalSelecionadoId).then((response: any) => {
            this.difusoes = response.entity;          
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);            
        });
    }

    carregarGrupoPertencente() : void {

        let busca = {
            grupoNome : 'EMPRESA_GRUPO_PERTENCENTE'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca).then( (lista: any) => {
            this.grupoPertencente = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarSegmento() : void {

        let busca = {
            grupoNome : 'EMPRESA_SEGMENTO'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca).then( (lista: any) => {
            this.segmento = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    async carregarPessoas(ids: number[]) : Promise<null> {

        for(let i=0; i<ids.length; i++) {

            try
            {
                let response = await this.pessoaService.find(ids[i]);
                let pessoa = response.entity;
                $("select[name='pessoas']").append(new Option(pessoa.nome+' '+pessoa.sobrenome, pessoa.id.toString(), false, true));
            }
            catch(e)
            {
                this.swtAlert2Service.errorAlert(e.error.errors);
                console.log(e);
            }

        }

        return null;

    }

    carregarEmpresa(Id: number) {
        this.empresaService.find(Id).then((response: any) => {
            this.empresa = response.entity;     
            this.regionalSelecionadoId = this.empresa.regionalId;   
            this.carregarMissoes();
            this.carregarDifusoes();  
            this.carregarPessoas(this.empresa.pessoasVinculadasIds); 
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);            
        });
    }

    salvar(empresa: Empresa): void {

        this.empresaService.update(empresa.id, empresa).then((response: any) => {
            this.empresa = response.entity;
            this.swtAlert2Service.successAlert(response.message); 
            setTimeout(() => {
                this.router.navigate(['../secured/empresas']);
            },2000);            
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    setarElementoSelecionado(valor) {
        this.empresa.pessoasVinculadasIds = valor;       
    }

}