import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Regional } from 'src/app/model/regional.model';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { RegionalService } from '../regional/service/regional.service';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';
import { Difusao } from 'src/app/model/difusao.model';
import { DifusaoService } from './service/difusao.service';
import { DifusaoBuilder } from 'src/app/builder/difusao.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'difusao-edit',
    templateUrl: './difusao.component.html'
})
export class DifusaoEditComponent implements OnInit {

    public difusaoId: number;
    public difusao: Difusao;
    public regionais: Array<Regional>;
    public fasesDifusao: Array<ElementoGrupoDominio>;
    public etapasDifusao: Array<ElementoGrupoDominio>;
    public difusaoBuilder: DifusaoBuilder;

    constructor(
        public difusaoService: DifusaoService,
        public regionalService: RegionalService,
        public elementoGrupoDominioService: ElementoGrupoDominioService, 
        public swtAlert2Service: SwtAlert2Service,       
        public route: ActivatedRoute,
        public router: Router
    ) 
    {
        this.difusaoBuilder = new DifusaoBuilder();
        this.difusao = this.difusaoBuilder.getInstance();
        this.regionais = new Array<Regional>();
        this.fasesDifusao = new Array<ElementoGrupoDominio>();
        this.etapasDifusao = new Array<ElementoGrupoDominio>();
    }

    ngOnInit() {  
        this.difusaoId = Number(this.route.snapshot.paramMap.get('id'));
        this.carregarRegionais();   
        this.carregarFasesDifusao();  
        this.carregarEtapasDifusao();
        this.carregarDifusao(this.difusaoId);
    }

    carregarDifusao(id: number): void {
        this.difusaoService.find(id).then((response: any) => {
            this.difusao = response.entity;
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarRegionais() : void {
        this.regionalService.findAll().then( (lista: any) => {
            this.regionais = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarEtapasDifusao() : void {

        let buscaFase = {
            grupoNome : 'ETAPA_DIFUSAO'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase).then( (lista: any) => {
            this.etapasDifusao = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarFasesDifusao() : void {

        let buscaFase = {
            grupoNome : 'FASE_DIFUSAO'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase).then( (lista: any) => {
            this.fasesDifusao = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    salvar(difusao: Difusao) : void {

        this.difusaoService.update(difusao.id,difusao).then( (response:any) => {
            this.difusao = this.difusaoBuilder.getInstance();            
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/difusoes']);
            }, 2000);
        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }


}