import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';


@Injectable()
export class NotificacaoService {

    protected apiURL: string = environment.configServidor.apiUrlNotSecured;

    constructor(protected http: HttpClient, protected router: Router) {}

    enviarMensagem(mensagem: any) : Promise<any> {
        return this.http.get<any>(this.apiURL+'pushnotification/message?message='+mensagem).toPromise();
    }

}