import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/login/service/login.service';
import { RouterManagerService } from './routermanager.service';
import localeBr from '@angular/common/locales/br';
import { registerLocaleData } from '@angular/common';

registerLocaleData(localeBr, 'br');

@Component({
    selector: 'secured',
    templateUrl: './secured.component.html'
})
export class SecuredComponent implements OnInit {

    constructor(
        public loginService: LoginService,
        public routerManagerService: RouterManagerService
    ){}
    
    ngOnInit() {
        this.loginService.checkToken();
        this.routerManagerService.verificarUsuario();
    }
    
}