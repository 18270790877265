import { PlanejamentoOrcamentario } from '../model/planejamentoorcamentario.model';
import { AbstractBuilder } from './abstract.builder';


export class PlanejamentoOrcamentarioBuilder extends AbstractBuilder<PlanejamentoOrcamentario> {

    reset() : void {
        this.entity = new PlanejamentoOrcamentario(null,null,null,null,null,null,null,null,null,null,new Array<number>(),null,null,true,new Array<number>(), new Array<any>());
    }

}