import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ContaPlanoService } from './service/contaplano.service';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../layout/secured.module';
import { RouterModule } from '@angular/router';
import { ContaPlanoComponent } from './contaplano.component';
import { ContaPlanoRoutingModule } from './contaplano-routing.module';
import { ContaPlanoEditComponent } from './contaplano.edit.component';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { ContasPlanoComponent } from './contasplano.component';

@NgModule({
    declarations: [ 
        ContaPlanoComponent,
        ContasPlanoComponent,
        ContaPlanoEditComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        ContaPlanoRoutingModule,
        FormsModule,
        SecuredModule
    ],
    providers: [
        ContaPlanoService,
        SwtAlert2Service
    ]
})
export class ContaPlanoModule {

}