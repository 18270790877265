import { PrevisaoOrcamentaria } from './previsaoorcamentaria.model';

export class CategoriaPlanejamentoOrcamentario { 
    constructor(
        public id: number,
        public nome: string,
        public planejamentoOrcamentarioId: number,
        public previsoesOrcamentariasIds: Array<number>,
        public exibirCategoria : boolean,
        public calcularNoPlanejamentoOrcamentarioCompleto : boolean,
        public exibirColunaCentroCusto : boolean,
        public exibirColunaObservacao : boolean,
        public exibirColunaComentario : boolean,
        public exibirColunaAnaliseVertical : boolean,
        public exibirColunaMediaTotal : boolean,
        public exibirColunaValorTotal : boolean,
        public previsaoOrcamentariaAvulsa : boolean,
        public previsaoOrcamentariaInicial : PrevisaoOrcamentaria,
        public corPrevisao: string,
        public requestExportacao: any   
    ){}
}