import { PlanilhaPlanejamentoOrcamentario } from '../model/PlanilhaPlanejamentoOrcamentario.model';
import { AbstractBuilder } from './abstract.builder';
import { PrevisaoOrcamentaria } from '../model/previsaoorcamentaria.model';
import { PrevisaoOrcamentariaBuilder } from './previsaoorcamentaria.builder';


export class PlanilhaPlanejamentoOrcamentarioBuilder extends AbstractBuilder<PlanilhaPlanejamentoOrcamentario> {

    reset() : void {
        this.entity = new PlanilhaPlanejamentoOrcamentario(null,null,null,new Array<PrevisaoOrcamentaria>(),new Array<PrevisaoOrcamentaria>())
    }

}